import Axios from "axios";
import React, { useContext, useState } from "react";
import Pagination from "react-js-pagination";
import { withRouter } from "react-router";
import { toast } from "react-toastify";
import AuthContext from "../../context/Auth.context";
import Message from "../../utilities/Message";
import Header from "../components/header/Header";
import Modal from "../components/modal/Modal";
import styles from "./Capatest.module.scss";
// import * as Sentry from "@sentry/react";

// var process = require("../../myProcess.json");

const CapabilityReserve = ({ history }) => {
  const auth = useContext(AuthContext);
  const token = localStorage.getItem("token");
  const [historyList, setHistoryList] = useState();
  const [displayed, setDisplayed] = useState();
  const [page, setPage] = useState(1);

  const [isModalOpen, setIsModalOpen] = useState(false);

  const getConsulting = () => {
    const url = `${process.env.REACT_APP_IP}/users/exam/consult`;

    Axios.get(url, { headers: { AccessToken: `Bearer ${token}` } })
      .then((response) => {
        setHistoryList(response.data);
        setDisplayed(response.data.slice(0, 10));
      })
      .catch((error) => {
        if (error.response !== undefined) {
          if (error.response.status === 401) {
            toast.error(Message.SERVICE_INVALID_TOKEN);
          } else if (error.response.status === 403) {
            toast.error(Message.ERROR_FORBIDDEN);
          } else if (error.response.status === 500) {
            toast.error(Message.ERROR_500);
          }
          // auth.onLogout();
          // history.push("/login");
          // Sentry.captureException(error);
        }
      });
  };

  useState(() => {
    getConsulting();
  });

  const handlePageChange = (page) => {
    setPage(page);
  };

  const onSuccess = (msg) => {
    if (msg === "SUCCESS") {
      getConsulting();
    }
  };

  return (
    <>
      <Header
        data={{
          title: "역량평가 결과 상담 현황",
        }}
      />
      <div className={styles.resultWrapper}>
        <div className={styles.resultTable}>
          <div className={styles.resultHead}>
            <div>신청 일자</div>
            <div>확정 일자</div>
            <div>신청 이유</div>
            <div>상태</div>
          </div>
          {displayed && displayed.length !== 0 ? (
            displayed.map((item) => (
              <div key={item.id} className={styles.resultRow}>
                <div>{`${item.consultingDate.split("T")[0]} ${
                  item.consultingDate.split("T")[1].split(":")[0]
                }:${item.consultingDate.split("T")[1].split(":")[1]}`}</div>
                <div>
                  {item.approvalDate
                    ? `${item.approvalDate.split("T")[0]} ${
                        item.approvalDate.split("T")[1].split(":")[0]
                      }:${item.approvalDate.split("T")[1].split(":")[1]}`
                    : "-"}
                </div>
                <div>{item.purpose}</div>
                <div>{item.resultComment ? item.resultComment : "-"}</div>
              </div>
            ))
          ) : (
            <div className={styles.resultRow}>상담 기록이 없습니다.</div>
          )}
        </div>
        {historyList && historyList.length > 10 && (
          <div style={{ width: "100%" }}>
            <Pagination
              activePage={page}
              itemsCountPerPage={10}
              totalItemsCount={historyList.length}
              pageRangeDisplayed={5}
              onChange={handlePageChange}
            />
          </div>
        )}
        <div className={styles.btns}>
          <button onClick={() => setIsModalOpen(true)}>상담 신청하기</button>
        </div>
        {isModalOpen && (
          <div className={styles.modalWrapper}>
            <div
              className={styles.overlay}
              onClick={() => setIsModalOpen(false)}
            />
            <Modal
              onSuccess={onSuccess}
              onClick={() => setIsModalOpen(false)}
              title={"상담 신청"}
              // onTabClick={handleTabClick}
            />
          </div>
        )}
      </div>
    </>
  );
};

export default withRouter(CapabilityReserve);
