import React, { useState, useContext, Fragment } from "react";
import { withRouter } from "react-router-dom";
import { withStyles, makeStyles } from "@material-ui/core/styles";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import { Button } from "../../elements";
import Table from "@material-ui/core/Table";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableBody from "@material-ui/core/TableBody";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import InputBase from "@material-ui/core/InputBase";
import axios from "axios";
import { toast } from "react-toastify";
import MyQandAList from "./MyQandAList.js";
import TablePagination from "@material-ui/core/TablePagination";
import AuthContext from "../../../context/Auth.context";
import Message from "../../../utilities/Message";
// import * as Sentry from "@sentry/react";

// var process = require("../../../myProcess.json");

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: "#C4C4C4",
    color: theme.palette.common.white,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const useStyles = makeStyles((theme) => ({
  root: {
    padding: "2px 4px",
    display: "flex",
    alignItems: "center",
    width: 400,
  },
  input: {
    marginLeft: theme.spacing(1),
    flex: 1,
    // '&:hover':{
    //   backgroundColor:fade(theme.palette.common.black, 0.1),
    // }
  },
  iconButton: {
    padding: 5,
  },
  table: {
    minWidth: 700,
  },
  title: {
    flex: "1 1 100%",
    marginTop: "5vh",
    fontWeight: "bolder",
  },
}));

const BootstrapInput = withStyles((theme) => ({
  root: {
    "label + &": {
      marginTop: theme.spacing(3),
    },
  },
  input: {
    marginLeft: "3px",
    borderRadius: 4,
    position: "relative",
    backgroundColor: theme.palette.background.paper,
    border: "1px solid #ced4da",
    fontSize: "15.5px",
    letterSpacing: "-0.175px",
    padding: "10px 26px 10px 12px",
    transition: theme.transitions.create(["border-color", "box-shadow"]),
    // Use the system font instead of the default Roboto font.
    fontFamily: [
      "Noto Sans KR",
      "-apple-system",
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(","),
    "&:focus": {
      borderRadius: 4,
      borderColor: "#80bdff",
      boxShadow: "0 0 0 0.2rem rgba(0,123,255,.25)",
    },
    "&::placeholder": {
      fontSize: 15.5,
    },
  },
}))(InputBase);

const List = ({ history }) => {
  const token = localStorage.getItem("token");
  const classes = useStyles();
  const auth = useContext(AuthContext);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const [list, setList] = React.useState([]);
  const [searchedData, setSearched] = React.useState([]);
  const [search, setSearch] = React.useState({
    selected: "postRegDate",
    title: "등록일",
    content: "",
  });

  const selected = React.useState([])[0];

  const selectChange = (e) => {
    console.log(e);
    if (e.target.value === "postRegDate") {
      setSearch({ ...search, selected: e.target.value, title: "등록일" });
      setSearched(
        list.filter((l) => {
          return l.postRegDate.indexOf(search.content) !== -1;
        })
      );
    } else if (e.target.value === "postTitle") {
      setSearch({ ...search, selected: e.target.value, title: "제목" });
      setSearched(
        list.filter((l) => {
          return l.postTitle.indexOf(search.content) !== -1;
        })
      );
    }
  };

  const handleChangeSearch = (e) => {
    if (search.selected === "postRegDate")
      setSearched(
        list.filter((l) => {
          return l.postRegDate.indexOf(e.target.value) !== -1;
        })
      );
    else if (search.selected === "postTitle")
      setSearched(
        list.filter((l) => {
          return l.postTitle.indexOf(e.target.value) !== -1;
        })
      );
    setSearch({ ...search, content: e.target.value });
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const isSelected = (id) => selected.indexOf(id) !== -1;

  useState(() => {
    if (!auth.auth) return;

    const url = `${process.env.REACT_APP_IP}/common/my/questionList/${auth.uid}`;

    axios
      .get(url, {
        headers: { AccessToken: `Bearer ${token}` },
      })
      .then((response) => {
        setList(response.data);
        setSearched(response.data);
      })
      .catch((error) => {
        if (error.response !== undefined) {
          if (error.response.status === 401) {
            toast.error(Message.SERVICE_INVALID_TOKEN);
          } else if (error.response.status === 403) {
            toast.error(Message.ERROR_FORBIDDEN);
          } else if (error.response.status === 500) {
            toast.error(Message.ERROR_500);
          }
          // auth.onLogout();
          // history.push("/login");
          // Sentry.captureException(error);
        }
      });
  });

  return (
    <Fragment>
      {/* <Typography className={classes.title} variant="h6" id="tableTitle" component="div">
          문제 등록 리스트
        </Typography> */}
      <div style={{ marginBottom: "1vh", float: "left" }}>
        <Button
          roundSmall
          //click={() => handleSubmit()}
          to="/customer/contact"
          type="outline-color-cl"
          hoverType="solid-color-tb"
        >
          {auth.type !== 1 ? (
            <span className="secotext">
              문의하기 목록
              <i
                className="las la-file-code"
                style={{ marginLeft: "10px" }}
              ></i>
            </span>
          ) : (
            <span className="secotext">
              문의하기
              <i
                className="las la-file-code"
                style={{ marginLeft: "10px" }}
              ></i>
            </span>
          )}
        </Button>
      </div>
      <div style={{ marginBottom: "1vh", float: "right" }}>
        <FormControl className={classes.margin}>
          <Select
            native
            id="select"
            value={search.selected}
            onChange={selectChange}
            input={<BootstrapInput />}
          >
            <option className="secotext" value="postRegDate">
              등록일
            </option>
            <option className="secotext" value="postTitle">
              제목
            </option>
          </Select>
        </FormControl>
        <FormControl className={classes.margin}>
          <BootstrapInput
            id="demo-customized-textbox"
            placeholder={`${search.title}으로 검색하기`}
            name="content"
            value={search.content}
            onChange={handleChangeSearch}
            style={{ width: "300px", fontSize: "15px" }}
          />
        </FormControl>
      </div>

      <TableContainer component={Paper}>
        <Table
          className={classes.table}
          size="small"
          aria-label="customized table"
        >
          <TableHead rowcount={searchedData.length}>
            <TableRow>
              <StyledTableCell>
                <span className="secotext">No.</span>
              </StyledTableCell>
              <StyledTableCell style={{ width: "500px" }} align="center">
                <span className="secotext">제목</span>
              </StyledTableCell>
              <StyledTableCell align="center">
                <span className="secotext">작성자</span>
              </StyledTableCell>
              <StyledTableCell align="center">
                <span className="secotext">등록일</span>
              </StyledTableCell>
              <StyledTableCell align="center">
                <span className="secotext">조회수</span>
              </StyledTableCell>
              <StyledTableCell align="center">
                <span className="secotext">답변여부</span>
              </StyledTableCell>
            </TableRow>
          </TableHead>
          {searchedData.length === 0 ? (
            <TableBody style={{ width: "100%", textAlign: "center" }}>
              <TableCell colSpan={5}>
                <div style={{ textAlign: "center", fontSize: "14px" }}>
                  게시글이 없습니다.
                </div>
              </TableCell>
            </TableBody>
          ) : (
            <TableBody>
              {searchedData
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row, index) => {
                  return (
                    <MyQandAList
                      qandalist={row}
                      no={index}
                      key={index}
                      date={row.postRegDate.substring(0, 10)}
                    />
                  );
                })}
            </TableBody>
          )}
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[5, 10, 25]}
        component="div"
        count={searchedData.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onChangePage={handleChangePage}
        onChangeRowsPerPage={handleChangeRowsPerPage}
      />
    </Fragment>
  );
};

export default withRouter(List);
