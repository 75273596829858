import React, { useContext, useEffect } from "react";
import { Route, Switch, useHistory, useRouteMatch } from "react-router";
import { toast } from "react-toastify";
import { Footer, Header } from "../../components/layouts";
import AuthContext from "../../context/Auth.context";
import Message from "../../utilities/Message";
import CapabilityReserve from "./CapabilityReserve";
import TestInfo from "./test/TestInfo";
import TestPage from "./test/TestPage";
import TestResult from "./test/TestResult";
import TestResultDetail from "./test/TestResultDetail";

const Capability = ({ data }) => {
  const history = useHistory();
  const match = useRouteMatch();
  const auth = useContext(AuthContext);
  useEffect(() => {
    if (!auth.auth) {
      toast.error(Message.SERVICE_AUTHENTICATED_ONLY);
      history.push("/login");
    }
  }, [auth]);
  return (
    <>
      <Header data={data} />
      <Switch>
        <Route exact path={`${match.path}/test`} render={() => <TestInfo />} />
        <Route
          exact
          path={`${match.path}/test-result`}
          render={() => <TestResult />}
        />
        <Route
          exact
          path={`${match.path}/test/testpage`}
          render={() => <TestPage />}
        />
        <Route
          exact
          path={`${match.path}/reserve`}
          render={() => <CapabilityReserve />}
        />
        <Route
          exact
          path={`${match.path}/test-result-detail`}
          render={() => <TestResultDetail />}
        />
      </Switch>
      <Footer />
    </>
  );
};

export default Capability;
