import styled from "styled-components";

export const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  font-size: 14px;
  color: rgba(0, 0, 0, 0.7);
`;

export const Title = styled.h3`
  width: 100%;
  margin-top: 20px;
  font-size: 14px;
  color: rgba(0, 0, 0, 0.7);
  font-weight: 700;
`;

export const Content = styled.div`
  width: 100%;
  min-height: 250px;
  padding: 15px;
  font-size: 14px;
  color: rgba(0, 0, 0, 0.7);
`;

export const ModalForm = styled.form`
  margin-top: 20px;
  padding: 10px;
`;

export const InputWrapper = styled.div`
  width: 100%;
  input,
  textarea {
    font-size: 1.6rem;
    padding: 10px;
    width: 100%;
    border: 1px solid rgba(0, 0, 0, 0.2);
    border-radius: 5px;
    resize: none;
    &::placeholder {
      font-size: 1.6rem;
    }
  }

  textarea::placeholder {
    color: rgba(0, 0, 0, 0.2);
  }
`;

export const ErrorMsg = styled.span`
  color: red;
`;

export const TableHead = styled.h3`
  position: relative;
  font-size: 16px;
  color: rgba(0, 0, 0, 0.4);
  font-weight: 600;
  padding: 20px 10px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
  width: 100%;
`;
export const RewatchDate = styled.div`
  margin: 0;
  float: right;
`;

export const Input = styled.input`
  margin: 15px;
  font-size: 14px;
  padding: 10px;
  border: 1px solid rgba(0, 0, 0, 0.2);
  min-width: 250px;
  &::placeholder {
    color: rgba(0, 0, 0, 0.3);
    font-size: 12px;
  }
`;

export const Tr = styled.tr`
  &:hover:not(.head) {
    cursor: pointer;
    background-color: rgba(0, 0, 0, 0.05);
  }
  td {
    max-width: 200px;
    font-size: 1.5rem;
    border: none !important;
    padding: 15px 10px;
    height: 40px;
    vertical-align: middle;
    color: rgba(0, 0, 0, 0.6);
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }
  th {
    border-top: none !important;
    color: rgba(0, 0, 0, 0.4) !important;
  }

  .md {
    text-align: center;
  }
`;

export const Badge = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${(props) => props.badgeColor};
  border-radius: 10px;
  color: white;
  width: 60px;
  margin: 0 auto;
  font-weight: 700;
  height: 100%;
`;
