import axios from "axios";
import { useContext } from "react";
import { useQuery } from "react-query";
import AuthContext from "../../context/Auth.context";

// const process = require("../../myProcess.json");

const useStudentDetail = (userId) => {
  const token = localStorage.getItem("token");
  const auth = useContext(AuthContext);
  const { data, isLoading } = useQuery(
    ["studentDetail", userId],
    async () => {
      return await axios
        .get(
          `${process.env.REACT_APP_IP}/teachers/exam/counseling/${auth.classId}/${userId}`,
          {
            headers: { AccessToken: `Bearer ${token}` },
          }
        )
        .then((res) => {
          return res.data;
        });
    },
    { enabled: !!userId }
  );
  return { data, isLoading };
};

export default useStudentDetail;
