import React, {
  Fragment,
  useEffect,
  useRef,
  useState,
  useContext,
  useCallback,
} from "react";
import axios from "axios";
import TestDate from "react-live-clock";
import { toast } from "react-toastify";
import styles from "../Capatest.module.scss";
import TakeTestModal from "./TakeTestModal";
import "./TestInfo.css";
import AuthContext from "../../../context/Auth.context";
import Message from "../../../utilities/Message";
import Header from "../../components/header/Header";
import { withRouter } from "react-router";
import styled from "styled-components";

function TestInfo({ sidebar = "left", history }) {
  const ref = useRef(null);
  const auth = useContext(AuthContext);
  const userId = localStorage.getItem("uid");
  const token = localStorage.getItem("token");

  const [openModal, setOpenModal] = useState(false);
  const [examStatus, setExamStatus] = useState(null);
  const [isExamPeriod, setIsExamPeriod] = useState(true);
  const [checked, setChecked] = useState(false);

  useEffect(() => {
    if (!auth.auth) return;

    const url = `${process.env.REACT_APP_IP}/users/exam/taken/${userId}`;
    axios
      .get(url, {
        headers: {
          AccessToken: `Bearer ${token}`,
        },
      })
      .then((response) => {
        const { data } = response;
        if (data === 3) {
          setExamStatus("평가 대상이 아닙니다.");
        } else if (data === 2) {
          setExamStatus("평가 기간이 아닙니다.");
        } else if (data === 1) {
          setExamStatus("역량평가를 이미 응시하셨습니다.");
        }
        isStartReq();
      })
      .catch((error) => {
        if (error.response !== undefined) {
          if (error.response.status === 401) {
            toast.error(Message.SERVICE_INVALID_TOKEN);
          } else if (error.response.status === 403) {
            toast.error(Message.ERROR_FORBIDDEN);
          } else if (error.response.status === 500) {
            toast.error(Message.ERROR_500);
          }
          // auth.onLogout();
          // Sentry.captureException(error);
        }
      });

    window.addEventListener("resize", resizeListener);
    return window.removeEventListener("resize", resizeListener);
  }, []);

  const isStartReq = useCallback(() => {
    const url = `${process.env.REACT_APP_IP}/users/exam/started/${userId}`;
    axios
      .get(url, {
        headers: {
          AccessToken: `Bearer ${token}`,
        },
      })
      .then((response) => {
        setTestData(response.data);
        setOpenModal(false);
        setChecked(response.data.isStarted);
        console.log(response.data.isStarted);
      })
      .catch((error) => {
        if (error.response !== undefined) {
          if (error.response.status === 401) {
            toast.error(Message.SERVICE_INVALID_TOKEN);
          } else if (error.response.status === 403) {
            toast.error(Message.ERROR_FORBIDDEN);
          } else if (error.response.status === 500) {
            toast.error(Message.ERROR_500);
          }
          // auth.onLogout();
          // Sentry.captureException(error);
        }
      });
  }, [userId, token, auth]);

  const resizeListener = () => {
  };

  const [testData, setTestData] = useState([]);

  const handleModalOpen = () => {
    setOpenModal(true);
  };

  const handleModalClose = () => {
    setOpenModal(false);
  };

  const handleCheck = () => {
    setChecked((prev) => !prev);
  };

  return (
    <Fragment>
      <Header
        data={{
          title: testData.classTitle
            ? testData.classTitle
            : "시험정보가 없습니다.",
        }}
      />
      <div className={styles.mypage} ref={ref}>
        <section
          className={[
            styles.wrapper,
            sidebar === "left"
              ? styles.with_sidebar + " " + styles.left
              : sidebar === "right"
                ? styles.with_sidebar + " " + styles.right
                : null,
          ].join(" ")}
        >
          <article className="capaTestInfo">
            <div className="capaTestInfo1">
              <div className="capaTestInfoBox1">
                <h3>역량평가 응시 정보</h3>
                <p>
                  - 응시일자:{" "}
                  <span>
                    {examStatus ? (
                      " -"
                    ) : (
                      <TestDate
                        format={"YYYY년 MM월 DD일"}
                        ticking={false}
                        timezone={"Asia/Seoul"}
                      />
                    )}
                  </span>
                </p>
                <p>
                  - 제한시간:{" "}
                  {testData.examTimeLimit
                    ? testData.examTimeLimit / 60 + "분"
                    : " -"}
                </p>
                <p>
                  - 전체문항수:{" "}
                  {testData.examProblemCount
                    ? testData.examProblemCount + "문항"
                    : " -"}
                </p>
              </div>
              <div className="capaTestInfoBox2">
                <h2>주의 사항</h2>
                <Warn>
                  - 시험 중 브라우저 종료 및 이동(새로운 브라우저 열기 포함)을
                  금지합니다.
                </Warn>
                <Warn>- 재로그인을 금지합니다.</Warn>
                <Warn>
                  - 브라우저의 앞으로 가기, 뒤로 가기 버튼의 사용을 금지합니다.
                  (이전 문제로 이동하고 싶을 경우, 반드시 문제 내 "이전" 버튼을
                  클릭하시기 바랍니다)
                </Warn>
                <Warn>
                  - 해당 역량평가의 모든 문제는 본 사의 자산이며, 무단 캡처 및
                  크롤링을 금지합니다.
                </Warn>
                <Warn>
                  - 위 사항을 위반한 경우 발생되는 문제는 본인의 책임으로
                  인정합니다.
                </Warn>
                <h2>응시 화면 안내</h2>
                <p>- 화면에는 1문제씩 출력됩니다.</p>
                <p>- 이전 버튼을 누르면 이전 문제로 이동할 수 있습니다.</p>
                <p>- 다음 버튼을 누르면 다음 문제로 이동할 수 있습니다.</p>
                <p>
                  - 문제 오른편에 있는 문제 현황판에서 문제 번호를 누르면 해당
                  번호의 문제를 볼 수 있습니다.
                </p>
                <p>
                  - 답을 제출하기 전에 다시 확인하고 싶은 문제를 문제 현황판의
                  체크박스에 체크해둘 수 있습니다.
                </p>
                <p>
                  - 응시 중 화면을 이탈할 경우, 화면 이탈 전에 표기했던 답이
                  초기화될 수 있습니다.
                </p>
                <p>- 화면을 이탈하더라도 제한시간은 초기화되지 않습니다.</p>
                <p>
                  - 문제를 모두 푼 다음에 제출 버튼을 눌러주세요. 제출한 후에는
                  답안을 수정할 수 없습니다.
                </p>
                <p>
                  - 역량평가 시험에 한 번 응시를 완료한 후에는 재응시가
                  불가능합니다.
                </p>
              </div>
              {testData.isStarted === 0 && examStatus === null ? (
                <CheckBoxBackground>
                  <CheckBoxWrapper onClick={handleCheck}>
                    <Input
                      type="checkbox"
                      id="consent-check"
                      checked={checked}
                    />
                    <Check for="consent-check" onClick={handleCheck}>
                      <span>해당 내용을 확인하였습니다</span>
                    </Check>
                  </CheckBoxWrapper>
                </CheckBoxBackground>
              ) : null}

              {!!checked ? (
                !examStatus ? (
                  testData.isStarted === 0 ? (
                    <div className="btnContainer">
                      <button
                        onClick={() => handleModalOpen()}
                        className="testStartBtn"
                      >
                        응시하기
                      </button>
                      {openModal === true ? (
                        <TakeTestModal
                          history
                          testData={testData}
                          openModal={openModal}
                          closeModal={() => handleModalClose()}
                        />
                      ) : null}
                    </div>
                  ) : (
                    <div className="btnContainer">
                      <button
                        onClick={() => handleModalOpen()}
                        className="testStartBtn"
                      >
                        이어하기
                      </button>
                      {openModal === true ? (
                        <TakeTestModal
                          history
                          testData={testData}
                          openModal={openModal}
                          closeModal={() => handleModalClose()}
                        />
                      ) : null}
                    </div>
                  )
                ) : (
                  <div className="btnContainer">
                    <button disabled className="testDisabled">
                      {examStatus}
                    </button>
                  </div>
                )
              ) : (
                <div className="btnContainer">
                  <button disabled className="testDisabled">
                    {examStatus ? examStatus : "안내문 확인이 필요합니다"}
                  </button>
                </div>
              )}
            </div>
          </article>
        </section>
      </div>
    </Fragment>
  );
}

const Input = styled.input`
  cursor: pointer;
`;
const Warn = styled.p`
  color: red;
  font-weight: bold;
`;

const Check = styled.label`
  cursor: pointer;

  & span {
    font-size: 16px;
    margin-left: 8px;
    font-weight: 500;
  }
`;

const CheckBoxWrapper = styled.div`
  /* :hover {
    background-color: white;
    border: 3px solid #2c95d2;
    span {
      color: black;
    }
  } */
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: white;
  border: 3px solid #2c95d2;

  span {
    color: black;
  }

  width: 250px;
  height: 40px;
  border-radius: 20px;
  cursor: pointer;
`;
const CheckBoxBackground = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 36px;
`;
export default withRouter(TestInfo);
