import axios from "axios";
import { useMutation } from "react-query";

// const process = require("../../myProcess.json");

const useUpdateCounseling = () => {
  const token = localStorage.getItem("token");
  const { mutate, isLoading } = useMutation(
    async ({ counselingId, title, content }) => {
      return await axios
        .put(
          `${process.env.REACT_APP_IP}/teachers/exam/counseling/comments`,
          {
            counselingId,
            title,
            content,
          },
          {
            headers: { AccessToken: `Bearer ${token}` },
          }
        )
        .then((res) => {
          return res.data;
        });
    }
  );
  return { mutate, isLoading };
};

export default useUpdateCounseling;
