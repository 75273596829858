/* eslint-disable */
import React, { useEffect, Fragment, useContext } from "react";
import { Switch, Route, Redirect, useLocation } from "react-router-dom";
import AuthProvider from "./components/AuthProvider";
import Customer from "./components/pages/Customer/index";
import "./assets/css/exam.css";
import "./assets/css/userModal.css";
import "./assets/css/scrollNav.css";
import "./assets/css/customSlider.css";
import "./assets/css/educational.css";
import "./assets/css/registerForm.css";
import "./assets/css/table.css";
import "./assets/css/filtering.css";
import "./assets/css/font-awesome.css";
import "react-toastify/dist/ReactToastify.css";
import "./assets/css/login.css";
import "./assets/css/mystyle.css";

import {
  Home,
  MypageMyInformation,
  MypageMyQandA,
  MyQandADetail,
  AccountInfoFind,
} from "./components/pages";

import Terms from "./components/pages/Footer/Terms";
import Privacy from "./components/pages/Footer/Privacy";
import Sitemap from "./components/pages/Footer/Sitemap";

import { Header, Sidebar, Footer } from "./components/layouts";
import { ModalForm, ModalVideo, RequestForm } from "./components/ui";
import { Spinner } from "./components/elements";
import { useCustomState } from "./state/state";
import { ToastContainer } from "react-toastify";
import AxiosConfig from "./utilities/Axios.js";
// import Room from "./modules/myclass/room/Room";
// import SGroup from "./modules/studyGroup/SGroup";
import Capability from "./modules/capability/Capability";
// import PreCourse from "./modules/preCourse/PreCourse";
import Teacher from "./modules/teacher/Teacher";
import "./Videomain.css";
import Login from "./modules/login/Login";
import AuthContext from "./context/Auth.context";
import TopButton from "./modules/components/topbutton/TopButton";
import Error404 from "./components/pages/Error/Error404";

export default () => {
  // const auth = useContext(AuthContext);
  const [state, actions] = useCustomState();
  // const location = useLocation();

  useEffect(() => {
    actions.fetch();
  }, [actions]);

  let app = state.error ? <p>Can't load page</p> : <Spinner fluid />;

  if (state.data) {
    app = (
      <AuthProvider>
        <ToastContainer
          style={{ fontSize: "1.3rem", width: "85%" }}
          autoClose="2000"
          position="top-center"
        />
        <Sidebar data={state.data.menu} />
        <RequestForm />
        <AxiosConfig />

        {/* {location.pathname !== "/login" && <Header data={state.data.menu} />} */}
        <Switch>
          <Route
            exact
            path={`/`}
            render={() => <Home data={state.data.menu} />}
          />
          <Route
            path="/mypagemyinformation"
            exact
            render={() => <MypageMyInformation data={state.data.menu} />}
          />
          {/*<Route*/}
          {/*  path="/mypagemyqanda"*/}
          {/*  exact*/}
          {/*  render={() => <MypageMyQandA data={state.data.menu} />}*/}
          {/*/>*/}
          {/*<Route*/}
          {/*  path="/mypagemyqandadetail/:id"*/}
          {/*  exact*/}
          {/*  render={() => <MyQandADetail data={state.data.menu} />}*/}
          {/*/>*/}
          <Route path="/login" exact component={Login} />
          <Route
            path="/accountinfofind"
            exact
            render={() => <AccountInfoFind data={state.data.menu} />}
          />
          {/*<Route*/}
          {/*  path="/customer"*/}
          {/*  render={() => <Customer data={state.data.menu} />}*/}
          {/*/>*/}

          {/* 마이클래스 */}
          {/*<Route*/}
          {/*  path="/myclass"*/}
          {/*  render={() => <Room data={state.data.menu} />}*/}
          {/*/>*/}

          {/* 스터디그룹 */}
          {/*<Route*/}
          {/*  path="/sgroup"*/}
          {/*  render={() => (*/}
          {/*    <SGroup*/}
          {/*      list={state.data.sgroupList}*/}
          {/*      posts={state.data.sgroupPostList}*/}
          {/*      data={state.data.menu}*/}
          {/*    />*/}
          {/*  )}*/}
          {/*/>*/}

          {/* 역량평가 */}
          <Route
            path={`/capability`}
            render={() => <Capability data={state.data.menu} />}
          />

          {/* 지식컨텐츠 */}
          {/*<Route*/}
          {/*  path={`/pre-course`}*/}
          {/*  render={() => <PreCourse data={state.data.menu} />}*/}
          {/*/>*/}

          {/* 교강사 */}
          <Route
            path={`/teacher`}
            render={() => <Teacher data={state.data.menu} />}
          />
          <Route
            path={`/*`}
            render={() => <Error404 />}
          />
          <Route
            path={`/accountinfofind/*`}
            render={() => <Error404 />}
          />
          <Route
            path={`/mypagemyinformation/*`}
            render={() => <Error404 />}
          />

          {/*<Route*/}
          {/*  path="/footer/terms"*/}
          {/*  render={() => <Terms data={state.data.menu} />}*/}
          {/*/>*/}
          {/*<Route*/}
          {/*  path="/footer/privacy"*/}
          {/*  render={() => <Privacy data={state.data.menu} />}*/}
          {/*/>*/}
          {/*<Route*/}
          {/*  path="/footer/sitemap"*/}
          {/*  render={() => <Sitemap data={state.data.menu} />}*/}
          {/*/>*/}
        </Switch>

        <TopButton />
        {/* {location.pathname !== "/login" && <Footer />} */}
      </AuthProvider>
    );
  }

  return <Fragment>{app}</Fragment>;
};
