export const filterFunction = (text, data) => {
  if (!data) {
    throw Error("데이터가 존재하지 않습니다.");
  }
  return data.filter((v) => {
    if (Object.values(v).join().indexOf(text) < 0) {
      return false;
    }
    return true;
  });
};

export const birthdayFormatter = (birth) => {
  if (typeof birth === "string" && !isNaN(birth)) {
    const year = birth.slice(0, 4);
    const month = birth.slice(4, 6);
    const date = birth.slice(6);
    return `${year}.${month}.${date}`;
  }
  throw Error("잘못된 형식입니다.");
};

export const numberFormatter = (num, digits) => {
  const zeros = new Array(digits - 1).fill("0").join().replace(",");
  const minNum = Number("1" + zeros);

  if (Number(num) < minNum) {
    return zeros + num;
  }
  return String(num);
};

export const getTimeString = (time) => {
  let minute = "" + Math.floor(time / 60);
  let second = time % 60;
  if (second < 10) {
    second = "0" + second;
  }
  return minute + "분 " + second + "초";
};
