import React, { useEffect, useState } from "react";
import styled from "styled-components";
import Modal from "../components/modalComponents/Modal";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";
import Grid from "@material-ui/core/Grid";
import axios from "axios";
import { toast } from "react-toastify";
import { withRouter } from "react-router";

// var process = require("../../myProcess.json");

const DefaultPwdChange = ({ history }) => {
  const useStyles = makeStyles((theme) => ({
    button: {
      color: "white",
      fontSize: "1.2rem",
      margin: theme.spacing(3, 0, 2),
      backgroundColor: "#343a40",
      cursor: "pointer",
      border: "thin solid #343a40 !important",
    },
  }));
  const classes = useStyles();

  const [isInitialPw, setIsInitialPw] = useState(localStorage.getItem("initialPw") === "true");

  const [valuesPass, setValuesPass] = useState({
    userId: localStorage.getItem("uid"),
    newpassword: "",
    confirmPassword: "",
  });

  const [error, setError] = useState({
    pwdError: "",
    confirmPwd: ""
  });

  const isPwd = (pass) => {
    const pwdRegex =
      /^.*(?=.{8,15})(?=.*[0-9])(?=.*[a-zA-Z])(?=.*[@$!%*#?&]).*$/;
    return pwdRegex.test(pass);
  };

  const confirmPassword = (pass, confirmPass) => {
    return pass === confirmPass;
  };

  const onTextValidationPass = () => {
    let pwdError = "";
    let confirmPwd = "";

    if (!isPwd(valuesPass.newpassword))
      pwdError = "비밀번호 조건을 만족 할 수 없습니다.";
    if (!confirmPassword(valuesPass.newpassword, valuesPass.confirmPassword))
      confirmPwd = "비밀번호가 일치하지 않습니다.";
    if (valuesPass.userId === valuesPass.newpassword)
      pwdError = "아이디를 비밀번호로 사용 할 수 없습니다.";

    setError({
      pwdError,
      confirmPwd
    });

    if (pwdError || confirmPwd ) return false;
    return true;
  };

  const handleChangeFormPass = (e) => {
    setValuesPass({ ...valuesPass, [e.target.name]: e.target.value });
  };

  const handleSubmitPassword = (e) => {
    e.preventDefault();
    const valid = onTextValidationPass();

    if (!valid) console.error("invalid");
    else {
      const token = localStorage.getItem("token");
      const userid = localStorage.getItem("uid");
      const urlEdit = `${process.env.REACT_APP_IP}/users/sign/pw`;
      axios
        .put(
          urlEdit,
          {
            userId: userid,
            password: valuesPass.newpassword,
          },
          {
            headers: {
              AccessToken: `Bearer ${token}`,
            },
          },
        )
        .then((response) => {
          if (response.data === 1) {
            toast.success("비밀번호가 변경되었습니다.");
            localStorage.setItem("initialPw", false);
            axios.defaults.headers.common["initialPw"] = localStorage
              .getItem("initialPw")
              .toString();
            setTimeout(() => {
              history.push("/capability/test");
            }, 1000);
          } else {
            toast.error("비밀번호 규정에 맞지 않습니다.");
          }
        })
        .catch((error) => {
          toast.error("서버 값을 받지 못했습니다.");
          setValuesPass({ newpassword: "", confirmPassword: "" });
        });
    }
  };

  return (
    <>
      {(localStorage.getItem("initialPw") === "true") && <Modal
        open={isInitialPw}
        size="small"
        maxHeight={"100"}
      >
        <ModalInner>
          <ToolBar>초기 비밀번호를 변경해주세요.</ToolBar>
          <Typography component={"span"}>
            <Grid>
              <Grid item xs={12}>
                <form onSubmit={handleSubmitPassword}>
                  <fieldset>
                    <TextField
                      variant="outlined"
                      margin="normal"
                      required
                      autoComplete=""
                      fullWidth
                      helperText="영문과 숫자, 특수문자(@$!%*#?&)를 조합하여 8-15자 사이로 입력해 주세요."
                      label="신규 비밀번호를 입력하세요."
                      name="newpassword"
                      type="password"
                      value={valuesPass.newpassword}
                      onChange={handleChangeFormPass}
                    />
                    <div
                      style={{
                        color: "red",
                        fontSize: "12px",
                        margin: "-5px 0 10px 15px",
                        fontFamily: "Noto Sans KR",
                      }}
                    >
                      {error.pwdError}
                    </div>
                    <TextField
                      variant="outlined"
                      margin="normal"
                      required
                      autoComplete=""
                      fullWidth
                      label="비밀번호를 다시한번 입력하세요."
                      name="confirmPassword"
                      type="password"
                      value={valuesPass.confirmPassword}
                      onChange={handleChangeFormPass}
                    />
                    <div
                      style={{
                        color: "red",
                        fontSize: "12px",
                        margin: "-5px 0 10px 15px",
                        fontFamily: "Noto Sans KR",
                      }}
                    >
                      {error.confirmPwd}
                    </div>
                    <button
                      type="submit"
                      size="medium"
                      variant="contained"
                      color="primary"
                      className={classes.button}
                      style={{
                        float: "right",
                        margin: "auto",
                        marginTop: "20px",
                        padding: "10px 30px",
                        borderRadius: "50px",
                        fontSize: "18px",
                      }}
                    >
                      비밀번호 변경
                    </button>
                  </fieldset>
                </form>
              </Grid>
            </Grid>
          </Typography>
        </ModalInner>
      </Modal>
      } </>);
};

const ModalInner = styled.div`
  width: 100%;
  overflow: hidden;
`;

const ToolBar = styled.h3`
  box-shadow: 5px 5px 5px rgb(0 0 0 / 10%);
  border-radius: 5px;
  padding: 20px;
  font-size: 18px;
  line-height: 30px;
  background-color: #999;
  color: #fff;
`;

export default withRouter(DefaultPwdChange);
