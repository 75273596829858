import axios from "axios";
import React, { useContext, useState } from "react";
import { useHistory, useLocation } from "react-router";
import { toast } from "react-toastify";
import {
  Legend,
  PolarAngleAxis,
  PolarGrid,
  PolarRadiusAxis,
  Radar,
  RadarChart,
  ResponsiveContainer,
} from "recharts";
import AuthContext from "../../../../context/Auth.context";
import useCompResult from "../../../../hooks/teacher/useCompResult";
import Message from "../../../../utilities/Message";
import { NameTag, TitleWrapper } from "../../style";
// import * as Sentry from "@sentry/react";

const Chart = ({ userId }) => {
  const { data: compResult, isLoading } = useCompResult(userId);

  // var process = require("../../../../myProcess.json");
  const [retakeYn, setRetakeYn] = useState();
  const auth = useContext(AuthContext);
  const token = localStorage.getItem("token");
  const [testResult, setTestResult] = useState([]);
  const [userName, setUserName] = useState();
  const history = useHistory();
  const location = useLocation();

  useState(() => {
    if (!auth.auth) return;

    const url = `${process.env.REACT_APP_IP}/teachers/exam/examResult/${userId}`;
    axios
      .get(url, {
        headers: {
          AccessToken: `Bearer ${token}`,
        },
      })
      .then((response) => {
        response.data.forEach((data) => {
          if (data.retakeYn === 1) {
            setRetakeYn(1);
            return;
          }
        });
      })
      .catch((error) => {
        if (error.response !== undefined) {
          if (error.response.status === 401) {
            toast.error(Message.SERVICE_INVALID_TOKEN);
          } else if (error.response.status === 403) {
            toast.error(Message.ERROR_FORBIDDEN);
          } else if (error.response.status === 500) {
            toast.error(Message.ERROR_500);
          }
          // auth.onLogout();
          // history.push("/login");
          // Sentry.captureException(error);
        }
      });
  });

  return (
    <div
      className="card"
      style={{ width: "100%", position: "relative", marginTop: "80px" }}
    >
      <div className="card-body">
        <NameTag>역량평가 결과</NameTag>
        <TitleWrapper>
          <h2>사전평가</h2>
          {retakeYn === 1 ? <h2>사후평가(재평가)</h2> : <h2>사후평가</h2>}
        </TitleWrapper>
        <div
          className="capaTestScore"
          style={{ display: "flex", justifyContent: "center" }}
        >
          <ResponsiveContainer width={"50%"} height={450}>
            <RadarChart
              cx="50%"
              cy="46%"
              outerRadius={150}
              height={450}
              data={compResult}
            >
              <PolarGrid />
              <PolarAngleAxis
                dataKey="unitTitle"
                fontSize="12px"
                angle={-10}
                width={60}
              />
              <PolarRadiusAxis angle={90} domain={[0, 100]} />
              <Radar
                name="점수(%)"
                dataKey="firstPer"
                stroke="#1E14E0"
                strokeWidth={2}
                fill="#7C77EB"
                fillOpacity={0.3}
              />
              <Radar
                name="목표(%)"
                dataKey="firstTargetScore"
                stroke="#1DC218"
                fill="#8884d8"
                fillOpacity={0.0}
              />
              <Radar
                name="평균(%)"
                dataKey="firstAvgPer"
                stroke="#D306C6"
                fill="#D306C6"
                fillOpacity={0.0}
              />
              <Legend />
            </RadarChart>
          </ResponsiveContainer>
          {retakeYn !== 1 && (
            <ResponsiveContainer width={"50%"} height={450}>
              <RadarChart
                cx="50%"
                cy="46%"
                outerRadius={150}
                height={450}
                data={compResult}
              >
                <PolarGrid />
                <PolarAngleAxis
                  dataKey="unitTitle"
                  fontSize="12px"
                  angle={-10}
                  width={60}
                />
                <PolarRadiusAxis angle={90} domain={[0, 100]} />
                <Radar
                  name="점수(%)"
                  dataKey="secondPer"
                  stroke="#EE6505"
                  strokeWidth={2}
                  fill="#FBA96F"
                  fillOpacity={0.3}
                />
                <Radar
                  name="목표(%)"
                  dataKey="secondTargetScore"
                  stroke="#1DC218"
                  fill="#8884d8"
                  fillOpacity={0.0}
                />
                <Radar
                  name="평균(%)"
                  dataKey="secondAvgPer"
                  stroke="#D306C6"
                  fill="#D306C6"
                  fillOpacity={0.0}
                />
                <Legend />
              </RadarChart>
            </ResponsiveContainer>
          )}
          {retakeYn === 1 && (
            <ResponsiveContainer width="50%" height={450}>
              <RadarChart
                cx="50%"
                cy="46%"
                outerRadius={150}
                height={450}
                data={compResult}
              >
                <PolarGrid />
                <PolarAngleAxis
                  dataKey="unitTitle"
                  fontSize="12px"
                  angle={-10}
                  width={60}
                />
                <PolarRadiusAxis angle={90} domain={[0, 100]} />
                <Radar
                  name="점수(%)"
                  dataKey="retakePer"
                  stroke="#EE6505"
                  strokeWidth={2}
                  fill="#FBA96F"
                  fillOpacity={0.3}
                />
                <Radar
                  name="목표(%)"
                  dataKey="retakeTargetScore"
                  stroke="#1DC218"
                  fill="#8884d8"
                  fillOpacity={0.0}
                />
                <Radar
                  name="평균(%)"
                  dataKey="retakeAvgPer"
                  stroke="#D306C6"
                  fill="#D306C6"
                  fillOpacity={0.0}
                />
                <Legend />
              </RadarChart>
            </ResponsiveContainer>
          )}
        </div>
      </div>
    </div>
  );
};

export default Chart;
