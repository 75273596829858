import React from "react";
import InfoEdit from "./InfoEdit";
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(0),
    marginBottom: theme.spacing(20),
    alignItems: "center",
    padding: "2px",
  },

  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.primary.main,
  },
  button: {
    margin: theme.spacing(3, 0, 2),
  },
  container: {
    flex: 1,
    display: "flex",
    justifyContent: "flex-end",
  },
}));

export default function AccountInfo(props) {
  const classes = useStyles();

  return (
    <Container style={{ backgroundColor: "white" }}>
      <div className={classes.paper}>
        <InfoEdit />
      </div>
    </Container>
  );
}
