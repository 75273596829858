import Axios from "axios";
import React, { useContext, useState } from "react";
import { useEffect } from "react";
import { Link } from "react-router-dom";
import { withRouter } from "react-router";
import { toast } from "react-toastify";
import AuthContext from "../../../context/Auth.context";
import Message from "../../../utilities/Message";
import Header from "../../components/header/Header";
import Navbar from "../Navbar";
import {
  Table,
  TableHeader,
  Wrapper,
  Btn,
  DisabledTableRow,
  BtnWrapper,
} from "../style";
import EditProblem from "./addExam/EditProblem";
import ProblemDetail from "./addExam/ProblemDetail";
import PrevProblemList from "./addExam/PrevProblemList";
// import * as Sentry from "@sentry/react";

// var process = require("../../../myProcess.json");

const GroupTestManager = ({ history }) => {
  const auth = useContext(AuthContext);
  const token = localStorage.getItem("token");
  const [list, setList] = useState([]);
  const [timeLimit, setTimeLimit] = useState(0);

  const fetchExamActiveStatus = () => {
    const url = `${process.env.REACT_APP_IP}/teachers/exam/status/${auth.groupId}`;
    Axios.get(url, {
      headers: {
        AccessToken: `Bearer ${token}`,
      },
    })
      .then((response) => {
        setList(response.data);
      })
      .catch((error) => {
        if (error.response !== undefined) {
          if (error.response.status === 401) {
            toast.error(Message.SERVICE_INVALID_TOKEN);
          } else if (error.response.status === 403) {
            toast.error(Message.ERROR_FORBIDDEN);
          } else if (error.response.status === 500) {
            toast.error(Message.ERROR_500);
          }
        }
      });
  };

  useEffect(() => {
    fetchExamActiveStatus();
  }, [auth]);

  useEffect(() => {
    if (timeLimit) {
      setTimeout(() => {
        fetchExamActiveStatus();
      }, (timeLimit * 1000) + 60000 + 3000);
    }
  }, [timeLimit]);

  const examActiveHandler = (examId, examStatus, msg, examTimeLimit) => {
    if (!auth.auth) return;

    const insertDatereq = () => {
      const url = `${process.env.REACT_APP_IP}/teachers/exam/active`;
      Axios.put(
        url,
        {
          userId: auth.uid,
          examId,
          groupId: auth.groupId,
          examStatus,
        },
        {
          headers: {
            AccessToken: `Bearer ${token}`,
          },
        },
      )
        .then((response) => {
          if (response.data === "success") {
            if (msg === "시작") {
              setTimeLimit(examTimeLimit);
              toast.success("선택한 시험이 시작되었습니다.");
            } else {
              toast.success("선택한 시험이 종료되었습니다.");
            }
            fetchExamActiveStatus();
          } else if (response.data === "already") {
            toast.error("이미 진행 중인 시험이 있습니다.");
          } else {
            toast.error(
              "서버와의 연결이 원활하지 않습니다. 잠시 후에 다시 시도해주세요.",
            );
          }
        })
        .catch((error) => {
          if (error.response !== undefined) {
            if (error.response.status === 401) {
              toast.error(Message.SERVICE_INVALID_TOKEN);
            } else if (error.response.status === 403) {
              toast.error(Message.ERROR_FORBIDDEN);
            } else if (error.response.status === 500) {
              toast.error(Message.ERROR_500);
            }
          }
        });
    };

    if (window.confirm(`해당 시험을 ${msg} 하시겠습니까?`)) {
      insertDatereq();
    }
  };

  return (
    <>
      <Header data={{ title: "시험 관리" }} />
      <Navbar title={"시험 관리"} />
      <Wrapper>
        <Table>
          <TableHeader>
            <div className="sm-div">No.</div>
            <div>시험명</div>
            <div className="sm-div">시간</div>
            <div className="sm-div">분류</div>
            <div className="sm-div">상태</div>
          </TableHeader>
          {list && list.length !== 0 ? (
            list.map((item, i) => (
              <DisabledTableRow key={i}>
                <div className="sm-div">{i + 1}</div>
                <div>{item.categoryName}</div>
                <div className="sm-div">{parseInt(item.timeLimit / 60)}분</div>
                <div className="sm-div">
                  {item.examCount === 1
                    ? "사전"
                    : item.examCount === 2
                      ? "사후"
                      : "재시험"}
                </div>
                <div className="sm-div">
                  {item.examActive !== 0 ? (
                    item.examActive === 1 ? (
                      <Btn
                        onClick={() => {
                          examActiveHandler(
                            item.examId,
                            item.examActive,
                            "시작",
                            item.timeLimit,
                          );
                        }
                        }
                        color="rgba(0,0,0,0.2)"
                      >
                        시험시작
                      </Btn>
                    ) : item.examActive === 2 ? (
                      <Btn
                        onClick={() =>
                          examActiveHandler(
                            item.examId,
                            item.examActive,
                            "종료",
                          )
                        }
                        color="#2E90CF"
                      >
                        시험종료
                      </Btn>
                    ) : (
                      <span className="status-span">종료</span>
                    )
                  ) : (
                    <Link to={`/teacher/test/add/${item.examId}`}>
                      <Btn color="rgb(238, 36, 36)">시험출제</Btn>
                    </Link>
                  )}
                  {/* {item.examActive
                      ? item.examActive === 1
                        ? "진행중"
                        : "완료"
                      : list.filter(
                          (obj) => obj.classCategoryId === item.classCategoryId
                        ).length === 1
                      ? "시험시작"
                      : list.filter(
                          (obj) => obj.classCategoryId === item.classCategoryId
                        ).length > 1
                      ? list.filter(
                          (obj) =>
                            obj.classCategoryId === item.classCategoryId &&
                            obj.examCount === item.examCount - 1
                        )[0]
                        ? list.filter(
                            (obj) =>
                              obj.classCategoryId === item.classCategoryId &&
                              obj.examCount === item.examCount - 1
                          )[0].examActive === 2
                          ? "시험시작"
                          : "시작대기중"
                        : "시험시작"
                      : ""} */}
                </div>
              </DisabledTableRow>
            ))
          ) : (
            <DisabledTableRow>
              <div>출제된 시험이 없습니다.</div>
            </DisabledTableRow>
          )}
        </Table>
        <BtnWrapper>
          {list && list.length !== 0 && list[0].examActive === 3 && (
            <Link to={`/teacher/test/result`}>
              <Btn color="#2E90CF">시험 결과 확인</Btn>
            </Link>
          )}
        </BtnWrapper>
      </Wrapper>
    </>
  );
};

export default withRouter(GroupTestManager);
