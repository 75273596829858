import React from "react";
// import styles from "./Header.module.scss";
import Layout from "../../layouts/Layout/Layout";

export default ({ children, subTitle }) => {
  return (
    // <Layout
    //   style={{
    //     //background: "url(" + img + ") center/cover",
    //     backgroundColor: "rgb(240, 245, 249)"
    //   }}
    //   col="1"
    //   mode="light"
    // >
    //   <div className={styles.header}>
    //     <h1 style={{ padding: "0px"}}>{children}</h1>
    //     <p>{subTitle}</p>
    //   </div>
    // </Layout>

    <Layout
      style={{
        backgroundColor: "#f0f5f9",
        marginTop: "110px",
        paddingBottom: "35px",
      }}
      col="1"
    >
      <div className="text-center" style={{ marginTop: "45px" }}>
        <h2
          className="secomain secoblue"
          style={{
            marginBlockStart: "0",
            markginBlockEnd: "0",
            marginBottom: "0",
          }}
        >
          {children}
        </h2>
        {/* <h4 className="pt-2" style={{ color: "#a8a9a9" }}>{subTitle}</h4> */}
      </div>
    </Layout>
  );
};
