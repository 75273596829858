import React, { useEffect } from "react";
import AuthContext from "../context/Auth.context";
import axios from "axios";
import { useCustomState } from "../state/state";
import { useHistory } from "react-router";
import * as Sentry from "@sentry/react";

// var process = require("../myProcess.json");
if (
  localStorage.getItem("freeYn") !== null &&
  localStorage.getItem("allowLanguages") !== null &&
  localStorage.getItem("uid") !== null &&
  localStorage.getItem("status") !== null &&
  localStorage.getItem("userType") !== null &&
  localStorage.getItem("userEmail") !== null &&
  localStorage.getItem("classId") !== null &&
  localStorage.getItem("initialPw") !== null
) {
  axios.defaults.headers.common["uid"] = localStorage.getItem("uid").toString();
  axios.defaults.headers.common["status"] = localStorage
    .getItem("status")
    .toString();
  axios.defaults.headers.common["userType"] = localStorage
    .getItem("userType")
    .toString();
  axios.defaults.headers.common["freeYn"] = localStorage
    .getItem("freeYn")
    .toString();
  axios.defaults.headers.common["allowLanguages"] = localStorage
    .getItem("allowLanguages")
    .toString();
  axios.defaults.headers.common["email"] = localStorage
    .getItem("userEmail")
    .toString();
  axios.defaults.headers.common["classId"] = localStorage
    .getItem("classId")
    .toString();
  axios.defaults.headers.common["initialPw"] = localStorage
    .getItem("initialPw")
    .toString();
  axios.defaults.headers.common["groupId"] = localStorage
    .getItem("groupId")
    .toString();
}

let refreshSubscribers = [];

export default function AxiosConfig() {
  const history = useHistory();
  let isTokenRefreshing = false;
  const auth = React.useContext(AuthContext);
  const actions = useCustomState()[1];

  const onTokenRefreshed = async (accessToken) => {
    refreshSubscribers.map((callback) => {
      callback(accessToken);
    });
    refreshSubscribers = [];
  };
  const addRefreshSubscriber = (callback) => {
    refreshSubscribers.push(callback);
  };

  useEffect(() => {
    if (auth.auth) {
      if (JSON.stringify(refreshSubscribers) !== "[]") {
        onTokenRefreshed(localStorage.getItem("token"));
      }
    }
  }, []);

  // Request Interceptor
  axios.interceptors.request.use(
    (config) => {
      // Get token from localStorage
      const accessToken = localStorage.getItem("token");
      // If token is present add it to request's Authorization Header
      // console.log(accessToken);

      if (accessToken) {
        // eslint-disable-next-line no-param-reassign
        config.headers.Authorization = "Bearer " + accessToken;
      }
      return config;
    },
    (error) => Promise.reject(error)
  );

  axios.interceptors.response.use(
    (response) => {
      return response;
    },
    async (error) => {
      let accessToken = "";
      const {
        config,
        response: { status },
      } = error;
      const originalRequest = config;

      if (status === 401) {
        if (!isTokenRefreshing) {
          // isTokenRefreshing이 false인 경우에만 token refresh 요청
          isTokenRefreshing = true;
          const refreshToken = localStorage.getItem("refreshToken");
          axios
            .post(
              `${process.env.REACT_APP_IP}/users/refreshAccessToken`,
              {}, // token refresh api
              {
                headers: {
                  RefreshToken: `Bearer ${refreshToken}`,
                },
              }
            )
            .then((res) => {
              // 새로운 토큰 저장
              isTokenRefreshing = false;
              accessToken = res.headers.accesstoken;
              localStorage.setItem("token", accessToken);
              onTokenRefreshed(accessToken);
              console.log("New token issued");
            })
            .catch((error) => {
              // console.log(1);
              Sentry.captureException("error line 126" + status);
              isTokenRefreshing = false;
              // modal이 여러번 toggle되지 않기 위해 오직 pop만 하는 함수 사용
              localStorage.clear();
              auth.onLogout();
              window.location.replace("/login");
              // if (!toast.isActive(1)) {
              //   toast.error(Message.SERVICE_EXPIRED_TOKEN, { toastId: 1 });
              // }
            });
        } else {
          isTokenRefreshing = false;
          if (
            error.config.url ===
            `${process.env.REACT_APP_IP}/users/refreshAccessToken`
          ) {
            Sentry.captureException("error line 142" + status);
            localStorage.clear();
            auth.onLogout();
            window.location.replace("/login");
          }
        }
        //   token이 재발급 되는 동안의 요청은 refreshSubscribers에 저장
        const retryOriginalRequest = new Promise((resolve, reject) => {
          addRefreshSubscriber(function (accessToken) {
            originalRequest.headers.AccessToken = "Bearer " + accessToken;
            axios(originalRequest)
              .then((res) => resolve(res))
              .catch((error) => reject(error));
          });
        });
        return retryOriginalRequest;
      }
      // 400, 404, 500
      else if (status === 500) {
        Sentry.captureException("error line 161" + status);
      } else if (status === 404) {
        Sentry.captureException("error line 163" + status);
      } else if (status === 400) {
        Sentry.captureException("error line 165" + status);
      }

      return Promise.reject(error);
    }
  );

  return <></>;
}
