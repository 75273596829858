import React, { useEffect, useContext, Fragment } from "react";
import { useHistory, useRouteMatch } from "react-router-dom";
import axios from "axios";
import { toast } from "react-toastify";
import "./TakeTestModal.css";
import AuthContext from "../../../context/Auth.context";
import Message from "../../../utilities/Message";
import { useState } from "react";
// import * as Sentry from "@sentry/react";

// var process = require("../../../myProcess.json");

export default function TakeTestModal(props) {
  const history = useHistory();
  const match = useRouteMatch();
  const userId = localStorage.getItem("uid");
  const token = localStorage.getItem("token");
  const auth = useContext(AuthContext);
  const [isClicked, setIsClicked] = useState(false);

  useEffect(() => {
    window.addEventListener("resize", resizeListener);
    return window.removeEventListener("resize", resizeListener);
  }, []);

  const resizeListener = () => {
  };

  const handleClickStart = () => {
    if (!isClicked) {
      setIsClicked(true);
      const url = `${process.env.REACT_APP_IP}/users/exam/start/${userId}`;
      axios
        .post(
          url,
          {},
          {
            headers: {
              AccessToken: `Bearer ${token}`,
            },
          },
        )
        .then((response) => {
          if (response.data === "success") {
            history.push(`${match.url}/testpage`);
          } else if (response.data === "time over") {
            toast.error(
              "시험이 이미 종료되었습니다. 시험장에 입장하실 수 없습니다.",
            );
            props.closeModal();
          } else {
            toast.error(
              "페이지 접근 오류 발생했습니다. 서버가 정상적으로 작동되었는지 확인하세요. ",
            );
            props.closeModal();
          }
        })
        .catch((error) => {
          if (error.response !== undefined) {
            if (error.response.status === 401) {
              toast.error(Message.SERVICE_INVALID_TOKEN);
            } else if (error.response.status === 403) {
              toast.error(Message.ERROR_FORBIDDEN);
            } else if (error.response.status === 500) {
              toast.error(Message.ERROR_500);
            }
            // auth.onLogout();
            // history.push("/login");
            // Sentry.captureException(error);
          }
        });
    }
    setTimeout(() => {
      setIsClicked(false);
    }, 1000);
  };

  const handleClickCancel = () => {
    return props.closeModal();
  };

  const handleClickContinue = () => {
    history.push(`${match.url}/testpage`);
  };

  return (
    <Fragment>
      <div className="testConfirmModalContainer">
        {props.testData.isStarted === 0 ? (
          <div className="testConfirmModal">
            <div className="testConfirmText">
              <p>
                <i class="fas fa-info-circle"></i>&nbsp;&nbsp;역량평가 테스트를
                시작하시겠습니까?
              </p>
            </div>
            <div className="btnGroup">
              <button
                onClick={() => handleClickCancel()}
                className="cancelBtn"
                title="취소"
              >
                취소
              </button>
              <button
                onClick={() => handleClickStart()}
                className="startBtn"
                title="시작"
              >
                시작
              </button>
            </div>
          </div>
        ) : (
          <div className="testConfirmModal">
            <div className="testConfirmText">
              <p>
                <i class="fas fa-info-circle"></i>&nbsp;&nbsp;역량평가 테스트를
                이어서 하시겠습니까?
              </p>
            </div>
            <div className="btnGroup">
              <button
                onClick={() => handleClickCancel()}
                className="cancelBtn"
                title="취소"
              >
                취소
              </button>
              <button
                onClick={() => handleClickContinue()}
                className="startBtn"
                title="계속"
              >
                계속
              </button>
            </div>
          </div>
        )}
      </div>
    </Fragment>
  );
}
