import React, { useEffect, useRef, useState } from "react";
import { withRouter } from "react-router";
import {
  Input,
  RewatchDate,
  TableHead,
  Tr,
} from "../../teacher/students/style";
import { filterFunction, numberFormatter } from "../../libs/util";

import { colTitlesRewatch, sortedKeysRewatch } from "../students/list/data";
import Header from "../../components/header/Header";
import Navbar from "../Navbar";
import { Wrapper } from "../style";
import { useQuery, useQueryClient } from "react-query";
import { FormControl, InputBase, Select, withStyles } from "@material-ui/core";
import Pagination from "./Pagination";
import { Badge } from "react-bootstrap";
import Axios from "axios";

const BootstrapInput = withStyles((theme) => ({
  root: {
    "label + &": {
      marginTop: theme.spacing(3),
    },
  },

  input: {
    marginLeft: "3px",
    borderRadius: 4,
    position: "relative",
    backgroundColor: theme.palette.background.paper,
    border: "1px solid #ced4da",
    fontSize: "15.5px",
    letterSpacing: "-0.175px",
    padding: "10px 26px 10px 12px",
    transition: theme.transitions.create(["border-color", "box-shadow"]),

    // Use the system font instead of the default Roboto font.
    fontFamily: [
      "Noto Sans KR",
      "-apple-system",
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(","),
    "&:focus": {
      borderRadius: 4,
      borderColor: "#80bdff",
      boxShadow: "0 0 0 0.2rem rgba(0,123,255,.25)",
    },
    "&::placeholder": {
      fontSize: 15.5,
    },
  },
}))(InputBase);

const Rewatch = ({ history }) => {
  const searchRef = useRef();
  const token = localStorage.getItem("token");
  const [filteredData, setFilteredData] = useState([]);
  const [pageNum, setPageNum] = useState(1);
  //현재 페이지
  const [pageSize, setpageSize] = useState(
    Math.floor((window.innerHeight - 320) / 50)
  ); // 화면 크기에 따라서 row수 결정
  const [search, setSearch] = useState({
    selected: "all",
    title: "전체",
    content: "",
  });
  const [searchContent, setSearchContent] = useState(null);
  const tableHeight = useRef(null);
  const [maxPostPage, setMaxPostPage] = useState();

  const selectChange = (e) => {
    if (e.target.value === "name") {
      setSearch({ ...search, selected: "name", title: "이름" });
      searchRef.current.focus();
    } else if (e.target.value === "lecture") {
      setSearch({ ...search, selected: "lecture", title: "강의" });
      searchRef.current.focus();
    } else if (e.target.value === "all") {
      setSearch({ ...search, selected: "all", title: "전체" });
      searchRef.current.focus();
    }
  };

  async function fetchLogs(pageNum) {
    const response = await Axios.get(
      `${process.env.REACT_APP_IP}/teachers/myclass/logging/vod-click?pageNum=${pageNum}&pageSize=${pageSize}&searchType=${search.selected}&searchContent=${search.content}`,
      {
        headers: { AccessToken: `Bearer ${token}` },
      }
    );
    return response;
  }

  // useEffect(() => {
  //   if (pageSize < maxPostPage) {
  //     const nextPage = pageNum + 1;
  //     queryClient.prefetchQuery(["posts", nextPage], () => fetchLogs(nextPage));
  //   }
  // }, [pageSize]);
  // // 미리 한페이지 이후의 것을 pre-fetcth함

  useEffect(() => {
    if (data) {
      setFilteredData(data.data.data.vodLogs.list);
    }
  }, [data]);

  const { data, isLoading, isError, error } = useQuery(
    ["rewatch", pageNum, pageSize, search, searchContent],
    () => fetchLogs(pageNum)
  );

  useEffect(() => {
    if (!isLoading) {
      setMaxPostPage(data.data.data.vodLogs.pages);
    }
  }, [isLoading, maxPostPage, data]);

  useEffect(() => {
    if (!isLoading) {
      setFilteredData(data.data.data.vodLogs.list);
    }
  }, [isLoading, pageNum, pageSize, data]);

  // const queryClient = useQueryClient();

  const handleSearch = (e) => {
    if (e.key === "Enter") {
      setSearch({ ...search, content: e.target.value });
      setPageNum(1);
    }
  };

  const [content, setContent] = useState(null);

  const handleChangeSearch = (e) => {
    if (e.key === "Enter") {
      setContent(e.target.value);
    }
  };

  return (
    <>
      <Header data={{ title: "다시보기 관리" }} />
      <Navbar title={"다시보기 관리"} />
      <div className="card">
        <div className="card-body" ref={tableHeight}>
          <Wrapper>
            <TableHead>
              다시보기 관리
              <RewatchDate>
                <Badge
                  className={"badge-pill bg-success"}
                  style={{ color: "white", padding: "10px", fontSize: "12px" }}
                >
                  다시보기 가능일수: {data?.data.data.period}일
                </Badge>
              </RewatchDate>
            </TableHead>
            <div>
              <FormControl>
                <Select
                  native
                  id="select"
                  value={search.selected}
                  onChange={selectChange}
                  input={<BootstrapInput />}
                >
                  <option className="secotext" value="all">
                    전체
                  </option>
                  <option className="secotext" value="name">
                    이름
                  </option>
                  <option className="secotext" value="lecture">
                    강의
                  </option>
                </Select>
              </FormControl>
              <FormControl>
                <BootstrapInput
                  autoFocus={true}
                  inputRef={searchRef}
                  id="demo-customized-textbox"
                  placeholder={`${search.title} 검색하기`}
                  name="content"
                  value={content}
                  onKeyPress={handleSearch}
                  onChange={handleChangeSearch}
                  style={{ width: "300px" }}
                />
              </FormControl>
            </div>

            {filteredData && (
              <table
                className="table"
                style={{ borderTop: "none", marginTop: "20px" }}
              >
                <thead>
                  <Tr className="head">
                    {colTitlesRewatch.map((v, i) => (
                      <th key={i + "colTitleRewatch"} scope="col">
                        {v}
                      </th>
                    ))}
                  </Tr>
                </thead>
                <tbody>
                  {filteredData.length !== 0 ? (
                    filteredData.map((v, i) => (
                      <Tr
                        key={i + "filteredData"}
                        style={{ cursor: "default" }}
                      >
                        {sortedKeysRewatch.map((item, i) => {
                          let result = v[item];
                          return (
                            <td key={i + "sortedKeysRewatch"}>
                              {result === true ? (
                                <span
                                  style={{ color: "green", fontWeight: "bold" }}
                                >
                                  O
                                </span>
                              ) : result === false ? (
                                <span style={{ color: "grey" }}>X</span>
                              ) : (
                                result
                              )}
                            </td>
                          );
                        })}
                      </Tr>
                    ))
                  ) : (
                    <Tr>
                      <td
                        style={{ textAlign: "center", cursor: "default" }}
                        colSpan={11}
                      >
                        데이터가 없습니다.
                      </td>
                    </Tr>
                  )}
                </tbody>
              </table>
            )}
          </Wrapper>
          <div
            className="d-flex"
            style={{
              maxWidth: "1140px",
              margin: "0 auto",
              justifyContent: "end",
            }}
          ></div>
          {filteredData?.length !== 0 && (
            <Pagination
              pageNum={pageNum}
              setPageNum={setPageNum}
              data={data?.data.data.vodLogs}
              maxPostPage={maxPostPage}
            />
          )}
        </div>
      </div>
    </>
  );
};

export default withRouter(Rewatch);
