import React from "react";
import { Link } from "react-router-dom";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import { withStyles } from "@material-ui/core/styles";

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow);

export default function MyQandAList(props) {
  // let history = useHistory();
  // let location = useLocation();
  // let { from1 } = location.state || { from: { pathname: `/submitter/detail/${props.registration.scodeId}` } };
  // let { from2 } = location.state || { from: { pathname: `/submitter/modify/${props.registration.scodeId}` } };

  return (
    <StyledTableRow key={props.qandalist.no}>
      <StyledTableCell component="th" scope="row">
        <span className="secotext">{props.no + 1}</span>
      </StyledTableCell>
      <StyledTableCell align="center" style={{ cursor: "pointer" }}>
        <span className="secotext">
          <Link to={"/mypagemyqandadetail/" + props.qandalist.postId}>
            {props.qandalist.postTitle.length > 32
              ? props.qandalist.postTitle.slice(0, 32) + " ..."
              : props.qandalist.postTitle}
          </Link>
        </span>
      </StyledTableCell>
      <StyledTableCell align="center">
        <span className="secotext">{props.qandalist.postAuthor}</span>
      </StyledTableCell>
      <StyledTableCell align="center">
        <span className="secotext">{props.qandalist.postRegDate}</span>
      </StyledTableCell>
      <StyledTableCell align="center">
        <span className="secotext">{props.qandalist.postHitCount}</span>
      </StyledTableCell>
      <StyledTableCell align="center">
        <span className="secotext">{props.qandalist.replyYn}</span>
      </StyledTableCell>
    </StyledTableRow>
  );
}
