import { createContext } from "react";

const AuthContext = createContext({
  auth: false,
  uid: "",
  name: "",
  type: "",
  email: "",
  status: "",
  token: "",
  isOauth: "",
  authToken: "",
  freeYn: "",
  classId: "",
  subClassId: "",
  groupId: "",
  onLogin: () => {},
  onLogout: () => {},
});

export default AuthContext;
