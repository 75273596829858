import React from "react";
import styled from "styled-components";

const BackDrop = ({ visible, onClose }) => {
  return <Back visible={visible} onClick={onClose}></Back>;
};

const Back = styled.div`
  display: ${(value) => (value ? "block" : "none")};
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  position: fixed;
  background-color: rgba(0, 0, 0, 0.6);
  z-index: 1000;
`;

export default BackDrop;
