import React, { Fragment, useContext } from "react";

import AuthContext from "../../../context/Auth.context";
import { useHistory, withRouter } from "react-router";
import { Header } from "../../layouts";

const Home = ({ data }) => {
  const history = useHistory();
  const auth = useContext(AuthContext);
  if (auth.auth) {
    if (auth.type === "1") {
      history.push("/capability/test");
    } else {
      history.push("/teacher");
    }

  } else {
    history.push("/login");
  }
  return (
    <Fragment>
      <Header data={data} />
    </Fragment>
  );
};

export default withRouter(Home);
