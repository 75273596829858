import React, {
  Fragment,
  useEffect,
  useRef,
  useState,
  useContext,
  createRef,
} from "react";
import styles from "../Mypage.module.scss";
import { withStyles } from "@material-ui/core/styles";
import { toast } from "react-toastify";
import {
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Radar,
  RadarChart,
  PolarGrid,
  Legend,
  PolarAngleAxis,
  PolarRadiusAxis,
  ResponsiveContainer,
  BarChart,
} from "recharts";
import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableBody from "@material-ui/core/TableBody";
import TableRow from "@material-ui/core/TableRow";
import { useHistory, useLocation, withRouter } from "react-router";
import axios from "axios";
import TestDate from "react-live-clock";
import "./GroupTestResult.css";
import Header from "../../components/header/Header";
import AuthContext from "../../../context/Auth.context";
import Message from "../../../utilities/Message";
import {
  DataUsageSharp,
  SettingsSystemDaydreamOutlined,
} from "@material-ui/icons";
// import * as Sentry from "@sentry/react";

// var process = require("../../../myProcess.json");

const GroupTestResult = ({ sidebar = "left" }) => {
  const ref = useRef(null);
  const auth = useContext(AuthContext);
  const token = localStorage.getItem("token");
  const history = useHistory();
  const location = useLocation();

  const [testResult, setTestResult] = useState([]);
  const [userName, setUserName] = useState();
  const [compResult, setCompResult] = useState([]);

  const [categoryName, setcategoryName] = useState([]);
  const [userId, setUserId] = useState();
  const [groupCourse, setGroupCourse] = useState();
  const [tableHeight, setTableHeight] = useState();
  const [retakeYn, setRetakeYn] = useState(0);
  const [retakeList, setRetakeList] = useState([]);
  const [arr, setArr] = useState([]);
  const [sortedCompData1, setSortedCompData1] = useState();
  const [mergedCompData1, setMergedCompData1] = useState();
  // const [sortedCompData, setSortedCompData] = useState();
  const sortedCompData = [];
  const mergedCompData = [];
  const retakeExamData = [];
  const dheight = createRef();

  const resizeListener = () => {};

  const ColorTableRow = withStyles((theme) => ({
    root: {
      backgroundColor: "white",
    },
    selected: { color: "black", backgroundColor: "#ffebd4 !important" },
  }))(TableRow);

  const StyledTableCell = withStyles((theme) => ({
    head: {
      backgroundColor: "#868686",
      color: theme.palette.common.white,
      fontSize: "1.45rem",
      fontWeight: "600",
      textAlign: "center",
    },
    body: {
      fontSize: 14,
    },
  }))(TableCell);

  const secondToClock = (time) => {
    let hour = parseInt(time / 3600);
    let min = parseInt((time % 3600) / 60);
    let sec = time % 60;

    if (hour === 0) {
      return `${min}분 ${sec}초`;
    }
    if (hour === 0 && min === 0) {
      return `${sec}초`;
    } else {
      return `${hour}시간 ${min}분 ${sec}초`;
    }
  };

  // const addList = () => {
  //   setRetakeList((prev) => [...prev, retakeYn]);
  // };

  useState(() => {
    if (!auth.auth) return;

    const url = `${process.env.REACT_APP_IP}/teachers/exam/examResult/${location.state.userId}`;
    axios
      .get(url, {
        headers: {
          AccessToken: `Bearer ${token}`,
        },
      })
      .then((response) => {
        setTestResult(response.data);
        setUserName(response.data[0].userName);
        response.data.forEach((data, index) => {
          if (data.retakeYn === 1) {
            setRetakeYn(1);
            retakeList.push(1);
          } else {
            retakeList.push(0);
            // setRetakeYn(0);
          }
        });
      })
      .catch((error) => {
        if (error.response !== undefined) {
          if (error.response.status === 401) {
            toast.error(Message.SERVICE_INVALID_TOKEN);
          } else if (error.response.status === 403) {
            toast.error(Message.ERROR_FORBIDDEN);
          } else if (error.response.status === 500) {
            toast.error(Message.ERROR_500);
          }
          // auth.onLogout();
          // history.push("/login");
          // Sentry.captureException(error);
        }
      });
  });

  useState(() => {
    if (!auth.auth) return;

    const url = `${process.env.REACT_APP_IP}/teachers/exam/examResult/detail/${location.state.userId}`;
    axios
      .get(url, {
        headers: {
          AccessToken: `Bearer ${token}`,
        },
      })
      .then((response) => {
        setCompResult(response.data.groupExamScoreDetails);
        setcategoryName(response.data.categoryName);
        setUserId(location.state.userId);
        setGroupCourse(location.state.groupCourse);
      })
      .catch((error) => {
        if (error.response !== undefined) {
          if (error.response.status === 401) {
            toast.error(Message.SERVICE_INVALID_TOKEN);
          } else if (error.response.status === 403) {
            toast.error(Message.ERROR_FORBIDDEN);
          } else if (error.response.status === 500) {
            toast.error(Message.ERROR_500);
          }
          // auth.onLogout();
          // history.push("/");
          // Sentry.captureException(error);
        }
      });
  });
  useEffect(() => {
    setTableHeight(dheight.current.offsetHeight);
    window.addEventListener("resize", resizeListener);
    return window.removeEventListener("resize", resizeListener);
  }, [dheight, compResult]);

  const GoBack = () => {
    history.goBack();
  };
  let myObj = {
    points: arr,
  };
  useEffect(() => {
    compResult.map((data) => {
      if (data.retakeScore === 0) {
        sortedCompData.push((myObj = { ...data, secondPer: data.secondPer }));
      } else {
        sortedCompData.push((myObj = { ...data, secondPer: 0 }));
      }
    });
    setSortedCompData1(sortedCompData);
  }, [compResult]);

  useEffect(() => {
    compResult.map((data) => {
      if (data.retakeScore === 0) {
        mergedCompData.push(
          (myObj = {
            ...data,
            retakeScore: data.secondScore,
            retakePer: data.secondPer,
            retakeTargetScore: data.secondTargetScore,
            retakeAvgPer: data.secondAvgPer,
            retakeCategoryAvg: data.secondCategoryAvg
          })
        );
      } else {
        mergedCompData.push(
          (myObj = {
            ...data,
            retakeScore: data.retakeScore,
            retakePer: data.retakePer,
            retakeTargetScore: data.retakeTargetScore,
            retakeAvgPer: data.retakeAvgPer,
            retakeCategoryAvg : data.retakeCategoryAvg
          })
        );
      }
    });
    setMergedCompData1(mergedCompData);
  }, [compResult]);

  const printFunc = () => {
    const html = document.querySelector("html");
    // 출력할 내용 가져오기
    const printContents = document.querySelector("#content").innerHTML;

    const printDiv = document.createElement("DIV");
    printDiv.className = "print-div";

    html.appendChild(printDiv);

    // 출력할 내용 인쇄 화면 속 태그에 넣어주기
    printDiv.innerHTML = printContents;
    document.body.style.display = "none";

    window.print();
    document.body.style.display = "block";
    printDiv.style.display = "none";
  };

  return (
    <Fragment>
      <Header data={{ title: `역량평가 결과 - ${userName && userName}` }} />
      <div className={styles.mypage} ref={ref}>
        <section
          className={[
            styles.wrapper,
            sidebar === "left"
              ? styles.with_sidebar + " " + styles.left
              : sidebar === "right"
              ? styles.with_sidebar + " " + styles.right
              : null,
          ].join(" ")}
        >
          <article className="groupTestResult" id="content">
            <div className="capaTestScore">
              <div className="row detailBox">
                <div className="col-md-1 detailLeft secotext">이름</div>
                <div className="col-md-6 detailRight secotext">{userName}</div>
                <div className="col-md-1 detailLeft secotext">아이디</div>
                <div className="col-md-4 detailRight secotext">{userId}</div>
              </div>
              <div
                className="row detailcontentBox"
                style={{
                  marginBottom: "30px",
                  borderRadius: "0 0 5px 5px",
                  borderBottom: "1px solid #e7e7e7",
                }}
              >
                <div className="col-md-1 detailLeft secotext">교육명</div>
                <div className="col-md-6 detailRight secotext">
                  {groupCourse}
                </div>
                <div className="col-md-1 detailLeft secotext">출력일</div>
                <div className="col-md-4 detailRight secotext">
                  <TestDate
                    format={"YYYY-MM-DD"}
                    ticking={false}
                    timezone={"Asia/Seoul"}
                  />
                </div>
              </div>
            </div>
            <div className="capaTestScore">
              <div style={{ display: "flex" }}>
                <h2>개인 역량평가 결과</h2>
              </div>
              <div className="capaTestResult1" ref={dheight}>
                <TableContainer component={Paper}>
                  <Table>
                    {retakeYn === 1 ? (
                      <>
                        <TableHead>
                          <TableRow>
                            <StyledTableCell
                              style={{ width: "22%" }}
                              rowSpan="2"
                            >
                              과목명
                            </StyledTableCell>
                            <StyledTableCell
                              style={{ width: "27%" }}
                              rowSpan="2"
                            >
                              분류
                            </StyledTableCell>
                            <StyledTableCell
                              style={{ width: "17%" }}
                              colSpan="2"
                            >
                              사전
                            </StyledTableCell>
                            <StyledTableCell
                              style={{ width: "17%" }}
                              colSpan="2"
                            >
                              사후
                            </StyledTableCell>
                            <StyledTableCell
                              style={{ width: "17%" }}
                              colSpan="2"
                            >
                              재평가
                            </StyledTableCell>
                          </TableRow>
                          <TableRow>
                            <StyledTableCell style={{ width: "8.5%" }}>
                              점수
                            </StyledTableCell>
                            <StyledTableCell style={{ width: "8.5%" }}>
                              평균
                            </StyledTableCell>
                            <StyledTableCell style={{ width: "8.5%" }}>
                              점수
                            </StyledTableCell>
                            <StyledTableCell style={{ width: "8.5%" }}>
                              평균
                            </StyledTableCell>
                            <StyledTableCell style={{ width: "8.5%" }}>
                              점수
                            </StyledTableCell>
                            <StyledTableCell style={{ width: "8.5%" }}>
                              평균
                            </StyledTableCell>
                          </TableRow>{" "}
                        </TableHead>
                      </>
                    ) : (
                      <>
                        <TableHead>
                          <TableRow>
                            <StyledTableCell
                              style={{ width: "22%" }}
                              rowSpan="2"
                            >
                              과목명
                            </StyledTableCell>
                            <StyledTableCell
                              style={{ width: "30%" }}
                              rowSpan="2"
                            >
                              분류
                            </StyledTableCell>
                            <StyledTableCell
                              style={{ width: "24%" }}
                              colSpan="2"
                            >
                              사전
                            </StyledTableCell>
                            <StyledTableCell
                              style={{ width: "24%" }}
                              colSpan="2"
                            >
                              사후
                            </StyledTableCell>
                          </TableRow>
                          <TableRow>
                            <StyledTableCell style={{ width: "12%" }}>
                              점수
                            </StyledTableCell>
                            <StyledTableCell style={{ width: "12%" }}>
                              평균
                            </StyledTableCell>
                            <StyledTableCell style={{ width: "12%" }}>
                              점수
                            </StyledTableCell>
                            <StyledTableCell style={{ width: "12%" }}>
                              평균
                            </StyledTableCell>
                          </TableRow>
                        </TableHead>
                      </>
                    )}
                    <TableBody>
                      {categoryName.map((categoryName, option) => (
                        <>
                          <ColorTableRow>
                            <TableCell
                              style={{
                                fontSize: "1.4rem",
                                textAlign: "center",
                              }}
                              rowSpan={
                                compResult.filter(
                                  (data) => data.categoryName === categoryName
                                ).length + 1
                              }
                            >
                              {categoryName}
                            </TableCell>
                          </ColorTableRow>
                          {retakeYn === 1
                            ? compResult
                                .filter(
                                  (data) => data.categoryName === categoryName
                                )
                                .map((data, option) => (
                                  <>
                                    <ColorTableRow>
                                      <TableCell
                                        style={{
                                          fontSize: "1.2rem",
                                          textAlign: "center",
                                          cursor: "pointer",
                                        }}
                                      >
                                        {data.unitTitle}
                                      </TableCell>
                                      <TableCell
                                        style={{
                                          fontSize: "1.4rem",
                                          textAlign: "center",
                                        }}
                                      >
                                        {data.firstScore <= 0
                                          ? "-"
                                          : data.firstScore}
                                      </TableCell>
                                      <TableCell
                                        style={{
                                          fontSize: "1.4rem",
                                          textAlign: "center",
                                        }}
                                      >
                                        {data.firstCategoryAvg <= 0
                                          ? "-"
                                          : data.firstCategoryAvg}
                                      </TableCell>
                                      <TableCell
                                        style={{
                                          fontSize: "1.4rem",
                                          textAlign: "center",
                                        }}
                                      >
                                        {data.secondScore <= 0
                                          ? "-"
                                          : data.secondScore}
                                      </TableCell>
                                      <TableCell
                                        style={{
                                          fontSize: "1.4rem",
                                          textAlign: "center",
                                        }}
                                      >
                                        {data.secondCategoryAvg <= 0
                                          ? "-"
                                          : data.secondCategoryAvg}
                                      </TableCell>
                                      <TableCell
                                        style={{
                                          fontSize: "1.4rem",
                                          textAlign: "center",
                                        }}
                                      >
                                        {data.retakeScore <= 0
                                          ? "-"
                                          : data.retakeScore}
                                      </TableCell>
                                      <TableCell
                                        style={{
                                          fontSize: "1.4rem",
                                          textAlign: "center",
                                        }}
                                      >
                                        {data.retakeCategoryAvg <= 0
                                          ? "-"
                                          : data.retakeCategoryAvg}
                                      </TableCell>
                                    </ColorTableRow>
                                  </>
                                ))
                            : compResult
                                .filter(
                                  (data) => data.categoryName === categoryName
                                )
                                .map((data, option) => (
                                  <>
                                    <ColorTableRow>
                                      <TableCell
                                        style={{
                                          fontSize: "1.2rem",
                                          textAlign: "center",
                                        }}
                                      >
                                        {data.unitTitle}
                                      </TableCell>
                                      <TableCell
                                        style={{
                                          fontSize: "1.4rem",
                                          textAlign: "center",
                                        }}
                                      >
                                        {data.firstScore <= 0
                                          ? "-"
                                          : data.firstScore}
                                      </TableCell>
                                      <TableCell
                                        style={{
                                          fontSize: "1.4rem",
                                          textAlign: "center",
                                        }}
                                      >
                                        {data.firstCategoryAvg <= 0
                                          ? "-"
                                          : data.firstCategoryAvg}
                                      </TableCell>
                                      <TableCell
                                        style={{
                                          fontSize: "1.4rem",
                                          textAlign: "center",
                                        }}
                                      >
                                        {data.secondScore <= 0
                                          ? "-"
                                          : data.secondScore}
                                      </TableCell>
                                      <TableCell
                                        style={{
                                          fontSize: "1.4rem",
                                          textAlign: "center",
                                        }}
                                      >
                                        {data.secondCategoryAvg <= 0
                                          ? "-"
                                          : data.secondCategoryAvg}
                                      </TableCell>
                                    </ColorTableRow>
                                  </>
                                ))}
                        </>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </div>
              {/* 바 그래프 */}

              <div className="capaTestResult2">
                <ResponsiveContainer height={tableHeight}>
                  <BarChart
                    style={{ margin: "0 0 0 30px" }}
                    layout="vertical"
                    height="100%"
                    data={sortedCompData1}
                  >
                    <CartesianGrid strokeDasharray="3 3" />

                    <YAxis
                      dataKey="unitTitle"
                      type="category"
                      fontSize="10px"
                    />

                    <XAxis width={80} type="number" domain={[0, 100]} />
                    <Tooltip
                      contentStyle={{ fontSize: "14px" }}
                      formatter={function (value, name) {
                        return value;
                      }}
                    />
                    <Legend
                      width={660}
                      verticalAlign="top"
                      align="right"
                      margin={{ bottom: 20 }}
                    />
                    <Bar
                      dataKey="firstScore"
                      barSize={12}
                      fill="#0000a7"
                      name="사전 점수(%)"
                    />
                    <Bar                      
                      dataKey="secondScore"
                      barSize={12}
                      fill="#febe48"
                      name="사후 점수(%)"
                    />
                    <Bar
                      hide={retakeYn === 0 ? true : false}
                      dataKey="retakeScore"
                      barSize={12}
                      fill="#a40000"
                      name="사후(재평가) 점수(%)"
                    />
                  </BarChart>
                </ResponsiveContainer>
              </div>
              {/* 기존 바 그래프 */}
              {/* <div className="capaTestResult2">
                <ResponsiveContainer height={tableHeight}>
                  <ComposedChart
                    layout="vertical"
                    height="100%"
                    data={compResult}
                    margin={{
                      top: 0,
                      right: 20,
                      bottom: 8,
                      left: 20,
                    }}
                  >
                    <CartesianGrid stroke="#f5f5f5" />
                    <XAxis type="number" domain={[0, "dataMax"]} />
                    <YAxis
                      width={80}
                      dataKey="unitTitle"
                      type="category"
                      scale="band"
                      fontSize="10px"
                    />
                    <Tooltip
                      contentStyle={{ fontSize: "14px" }}
                      formatter={function (value, name) {
                        return `${value === 0.3 ? 0 : value}`;
                      }}
                    />
                    <Legend
                      width={330}
                      verticalAlign="top"
                      align="right"
                      margin={{ bottom: 20 }}
                    />
                    <Bar
                      dataKey="grownRate"
                      barSize={20}
                      fill="#167F9C"
                      name={
                        retakeYn === 1
                          ? "사전 : 재평가 점수 상승률(%)"
                          : "사전 : 사후 점수 상승률(%)"
                      }
                    />
                  </ComposedChart>
                </ResponsiveContainer>
              </div> */}
            </div>

            <div className="capaTestScore">
              <h2>기술요소별 역량평가 결과</h2>
              <div style={{ display: "flex", justifyContent: "space-around" }}>
                <h3>사전평가</h3>
                {retakeYn === 1 ? <h3>사후평가(재평가)</h3> : <h3>사후평가</h3>}
              </div>
              <div style={{ display: "flex", justifyContent: "space-around" }}>
                <ResponsiveContainer width={"49%"} height={450}>
                  <RadarChart
                    cx="50%"
                    cy="46%"
                    outerRadius={150}
                    height={450}
                    data={compResult}
                  >
                    <PolarGrid />
                    <PolarAngleAxis
                      dataKey="unitTitle"
                      fontSize="12px"
                      angle={-10}
                      width={60}
                    />
                    <Tooltip
                      formatter={(value, name, props) => `${value}%`}
                    />
                    <PolarRadiusAxis angle={90} domain={[0, 100]} />
                    <Radar
                      name="점수(%)"
                      dataKey="firstScore"
                      stroke="#1E14E0"
                      strokeWidth={2}
                      fill="#7C77EB"
                      fillOpacity={0.3}
                    />
                    <Radar
                      name="목표(%)"
                      dataKey="firstTargetScore"
                      stroke="#1DC218"
                      fill="#8884d8"
                      fillOpacity={0.0}
                    />
                    <Radar
                      name="평균(%)"
                      dataKey="firstCategoryAvg"
                      stroke="#D306C6"
                      fill="#D306C6"
                      fillOpacity={0.0}
                    />
                    <Legend />
                  </RadarChart>
                </ResponsiveContainer>{" "}
                {retakeYn === 1 || (
                  <>
                    <ResponsiveContainer width={"49%"} height={450}>
                      <RadarChart
                        cx="50%"
                        cy="46%"
                        outerRadius={150}
                        height={450}
                        data={compResult}
                      >
                        <PolarGrid />
                        <PolarAngleAxis
                          dataKey="unitTitle"
                          fontSize="12px"
                          angle={-10}
                          width={60}
                        />
                        <Tooltip
                          formatter={(value, name, props) => `${value}%`}
                        />
                        <PolarRadiusAxis angle={90} domain={[0, 100]} />
                        <Radar
                          name="점수(%)"
                          dataKey="secondScore"
                          stroke="#EE6505"
                          strokeWidth={2}
                          fill="#FBA96F"
                          fillOpacity={0.3}
                        />
                        <Radar
                          name="목표(%)"
                          dataKey="secondTargetScore"
                          stroke="#1DC218"
                          fill="#8884d8"
                          fillOpacity={0.0}
                        />
                        <Radar
                          name="평균(%)"
                          dataKey="secondCategoryAvg"
                          stroke="#D306C6"
                          fill="#D306C6"
                          fillOpacity={0.0}
                        />
                        <Legend />
                      </RadarChart>
                    </ResponsiveContainer>
                  </>
                )}
                {retakeYn === 1 && (
                  <>
                    <ResponsiveContainer width="49%" height={450}>
                      <RadarChart
                        cx="50%"
                        cy="46%"
                        outerRadius={150}
                        height={450}
                        data={mergedCompData1}
                      >
                        <PolarGrid />
                        <PolarAngleAxis
                          dataKey="unitTitle"
                          fontSize="12px"
                          angle={-10}
                          width={60}
                        />
                        <Tooltip
                          formatter={(value, name, props) => `${value}%`}
                        />
                        <PolarRadiusAxis angle={90} domain={[0, 100]} />
                        <Radar
                          name="점수(%)"
                          dataKey="retakeScore"
                          stroke="#EE6505"
                          strokeWidth={2}
                          fill="#FBA96F"
                          fillOpacity={0.3}
                        />

                        <Radar
                          name="목표(%)"
                          dataKey="retakeTargetScore"
                          stroke="#1DC218"
                          fill="#8884d8"
                          fillOpacity={0.0}
                        />
                        <Radar
                          name="평균(%)"
                          dataKey="retakeCategoryAvg"
                          stroke="#D306C6"
                          fill="#D306C6"
                          fillOpacity={0.0}
                        />
                        <Legend />
                      </RadarChart>
                    </ResponsiveContainer>
                  </>
                )}
              </div>
            </div>
            <div className="capaTestScore">
              <h2>사전 역량평가 결과</h2>
              <TableContainer component={Paper}>
                <Table>
                  <TableHead>
                    <TableRow>
                      <StyledTableCell style={{ width: "18%" }} rowSpan="2">
                        과목명
                      </StyledTableCell>
                      <StyledTableCell style={{ width: "13%" }} rowSpan="2">
                        응시일자
                      </StyledTableCell>
                      <StyledTableCell style={{ width: "11%" }} rowSpan="2">
                        목표 점수
                      </StyledTableCell>
                      <StyledTableCell style={{ width: "11%" }} rowSpan="2">
                        전체 평균
                      </StyledTableCell>
                      <StyledTableCell style={{ width: "11%" }} rowSpan="2">
                        소요 시간
                      </StyledTableCell>
                      <StyledTableCell style={{ width: "11%" }} rowSpan="2">
                        개인 점수
                      </StyledTableCell>
                      <StyledTableCell style={{ width: "21%" }} colSpan="3">
                        난이도별 정답률
                      </StyledTableCell>
                      <StyledTableCell style={{ width: "6%" }} rowSpan="2">
                        순위
                      </StyledTableCell>
                      <StyledTableCell style={{ width: "9%" }} rowSpan="2">
                        응시 인원
                      </StyledTableCell>
                    </TableRow>
                    <TableRow>
                      <StyledTableCell style={{ width: "7%" }}>
                        상
                      </StyledTableCell>
                      <StyledTableCell style={{ width: "7%" }}>
                        중
                      </StyledTableCell>
                      <StyledTableCell style={{ width: "7%" }}>
                        하
                      </StyledTableCell>
                    </TableRow>
                  </TableHead>
                  {testResult
                    .filter((fi) => fi.examCount === 1)
                    .map((result, option) => (
                      <TableBody>
                        <ColorTableRow>
                          <TableCell
                            style={{ fontSize: "1.4rem", textAlign: "center" }}
                          >
                            {result.categoryName}
                          </TableCell>
                          <TableCell
                            style={{ fontSize: "1.4rem", textAlign: "center" }}
                          >
                            {result.examStartDate}
                          </TableCell>
                          <TableCell
                            style={{ fontSize: "1.4rem", textAlign: "center" }}
                          >
                            {result.examGroupTargetScore <= 0
                              ? "-"
                              : result.examGroupTargetScore}
                          </TableCell>
                          <TableCell
                            style={{ fontSize: "1.4rem", textAlign: "center" }}
                          >
                            {result.examGroupScoreAvg <= 0
                              ? "-"
                              : result.examGroupScoreAvg}
                          </TableCell>
                          <TableCell
                            style={{ fontSize: "1.4rem", textAlign: "center" }}
                          >
                            {" "}
                            {secondToClock(result.examTimeRequired)}
                          </TableCell>
                          {result.examGroupTargetScore <=
                          result.examScoreResult ? (
                            <TableCell
                              style={{
                                fontSize: "1.4rem",
                                textAlign: "center",
                                color: "#FA7F42",
                              }}
                            >
                              <strong>{result.examScoreResult}</strong>
                            </TableCell>
                          ) : (
                            <TableCell
                              style={{
                                fontSize: "1.4rem",
                                textAlign: "center",
                                color: "#2C95D2",
                              }}
                            >
                              <strong>{result.examScoreResult}</strong>
                            </TableCell>
                          )}
                          <TableCell
                            style={{ fontSize: "1.4rem", textAlign: "center" }}
                          >
                            {result.examHighLevelScoreCount <= 0 &&
                            result.examHighLevelCount <= 0
                              ? "-"
                              : (
                                  (result.examHighLevelScoreCount /
                                    result.examHighLevelCount) *
                                  100
                                ).toFixed(0) + "%"}
                          </TableCell>
                          <TableCell
                            style={{ fontSize: "1.4rem", textAlign: "center" }}
                          >
                            {result.examMiddleLevelScoreCount <= 0 &&
                            result.examMiddleLevelCount <= 0
                              ? "-"
                              : (
                                  (result.examMiddleLevelScoreCount /
                                    result.examMiddleLevelCount) *
                                  100
                                ).toFixed(0) + "%"}
                          </TableCell>
                          <TableCell
                            style={{ fontSize: "1.4rem", textAlign: "center" }}
                          >
                            {result.examLowLevelScoreCount <= 0 &&
                            result.examLowLevelCount <= 0
                              ? "-"
                              : (
                                  (result.examLowLevelScoreCount /
                                    result.examLowLevelCount) *
                                  100
                                ).toFixed(0) + "%"}
                          </TableCell>
                          <TableCell
                            style={{ fontSize: "1.4rem", textAlign: "center" }}
                          >
                            {result.examRank}
                          </TableCell>
                          <TableCell
                            style={{ fontSize: "1.4rem", textAlign: "center" }}
                          >
                            {result.examinees}
                          </TableCell>
                        </ColorTableRow>
                      </TableBody>
                    ))}
                </Table>
              </TableContainer>
            </div>

            <div className="capaTestScore">
              <h2>사후 역량평가 결과</h2>
              <TableContainer component={Paper}>
                <Table>
                  <TableHead>
                    <TableRow>
                      <StyledTableCell style={{ width: "18%" }} rowSpan="2">
                        과목명
                      </StyledTableCell>
                      <StyledTableCell style={{ width: "13%" }} rowSpan="2">
                        응시일자
                      </StyledTableCell>
                      <StyledTableCell style={{ width: "11%" }} rowSpan="2">
                        목표 점수
                      </StyledTableCell>
                      <StyledTableCell style={{ width: "11%" }} rowSpan="2">
                        전체 평균
                      </StyledTableCell>
                      <StyledTableCell style={{ width: "11%" }} rowSpan="2">
                        소요 시간
                      </StyledTableCell>
                      <StyledTableCell style={{ width: "11%" }} rowSpan="2">
                        개인 점수
                      </StyledTableCell>
                      <StyledTableCell style={{ width: "21%" }} colSpan="3">
                        난이도별 정답률
                      </StyledTableCell>
                      <StyledTableCell style={{ width: "6%" }} rowSpan="2">
                        순위
                      </StyledTableCell>
                      <StyledTableCell style={{ width: "9%" }} rowSpan="2">
                        응시 인원
                      </StyledTableCell>
                    </TableRow>
                    <TableRow>
                      <StyledTableCell style={{ width: "7%" }}>
                        상
                      </StyledTableCell>
                      <StyledTableCell style={{ width: "7%" }}>
                        중
                      </StyledTableCell>
                      <StyledTableCell style={{ width: "7%" }}>
                        하
                      </StyledTableCell>
                    </TableRow>
                  </TableHead>
                  {testResult
                    .filter((fi) => fi.examCount === 2)
                    .map((result, option) => (
                      <TableBody>
                        <ColorTableRow>
                          <TableCell
                            style={{ fontSize: "1.4rem", textAlign: "center" }}
                          >
                            {result.categoryName}
                          </TableCell>
                          <TableCell
                            style={{ fontSize: "1.4rem", textAlign: "center" }}
                          >
                            {result.examStartDate}
                          </TableCell>
                          <TableCell
                            style={{ fontSize: "1.4rem", textAlign: "center" }}
                          >
                            {result.examGroupTargetScore <= 0
                              ? "-"
                              : result.examGroupTargetScore}
                          </TableCell>
                          <TableCell
                            style={{ fontSize: "1.4rem", textAlign: "center" }}
                          >
                            {result.examGroupScoreAvg <= 0
                              ? "-"
                              : result.examGroupScoreAvg}
                          </TableCell>
                          <TableCell
                            style={{ fontSize: "1.4rem", textAlign: "center" }}
                          >
                            {" "}
                            {secondToClock(result.examTimeRequired)}
                          </TableCell>
                          {result.examGroupTargetScore <=
                          result.examScoreResult ? (
                            <TableCell
                              style={{
                                fontSize: "1.4rem",
                                textAlign: "center",
                                color: "#FA7F42",
                              }}
                            >
                              <strong>{result.examScoreResult}</strong>
                            </TableCell>
                          ) : (
                            <TableCell
                              style={{
                                fontSize: "1.4rem",
                                textAlign: "center",
                                color: "#2C95D2",
                              }}
                            >
                              <strong>{result.examScoreResult}</strong>
                            </TableCell>
                          )}
                          <TableCell
                            style={{ fontSize: "1.4rem", textAlign: "center" }}
                          >
                            {result.examHighLevelScoreCount <= 0 &&
                            result.examHighLevelCount <= 0
                              ? "-"
                              : (
                                  (result.examHighLevelScoreCount /
                                    result.examHighLevelCount) *
                                  100
                                ).toFixed(0) + "%"}
                          </TableCell>
                          <TableCell
                            style={{ fontSize: "1.4rem", textAlign: "center" }}
                          >
                            {result.examMiddleLevelScoreCount <= 0 &&
                            result.examMiddleLevelCount <= 0
                              ? "-"
                              : (
                                  (result.examMiddleLevelScoreCount /
                                    result.examMiddleLevelCount) *
                                  100
                                ).toFixed(0) + "%"}
                          </TableCell>
                          <TableCell
                            style={{ fontSize: "1.4rem", textAlign: "center" }}
                          >
                            {result.examLowLevelScoreCount <= 0 &&
                            result.examLowLevelCount <= 0
                              ? "-"
                              : (
                                  (result.examLowLevelScoreCount /
                                    result.examLowLevelCount) *
                                  100
                                ).toFixed(0) + "%"}
                          </TableCell>
                          <TableCell
                            style={{ fontSize: "1.4rem", textAlign: "center" }}
                          >
                            {result.examRank}
                          </TableCell>
                          <TableCell
                            style={{ fontSize: "1.4rem", textAlign: "center" }}
                          >
                            {result.examinees}
                          </TableCell>
                        </ColorTableRow>
                      </TableBody>
                    ))}
                </Table>
              </TableContainer>
            </div>
            {retakeYn === 1 && (
              <div className="capaTestScore">
                <h2>재평가 결과</h2>
                <TableContainer component={Paper}>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <StyledTableCell style={{ width: "18%" }} rowSpan="2">
                          과목명
                        </StyledTableCell>
                        <StyledTableCell style={{ width: "13%" }} rowSpan="2">
                          응시일자
                        </StyledTableCell>
                        <StyledTableCell style={{ width: "11%" }} rowSpan="2">
                          목표 점수
                        </StyledTableCell>
                        <StyledTableCell style={{ width: "11%" }} rowSpan="2">
                          전체 평균
                        </StyledTableCell>
                        <StyledTableCell style={{ width: "11%" }} rowSpan="2">
                          소요 시간
                        </StyledTableCell>
                        <StyledTableCell style={{ width: "11%" }} rowSpan="2">
                          개인 점수
                        </StyledTableCell>
                        <StyledTableCell style={{ width: "21%" }} colSpan="3">
                          난이도별 정답률
                        </StyledTableCell>
                        <StyledTableCell style={{ width: "6%" }} rowSpan="2">
                          순위
                        </StyledTableCell>
                        <StyledTableCell style={{ width: "9%" }} rowSpan="2">
                          응시 인원
                        </StyledTableCell>
                      </TableRow>
                      <TableRow>
                        <StyledTableCell style={{ width: "7%" }}>
                          상
                        </StyledTableCell>
                        <StyledTableCell style={{ width: "7%" }}>
                          중
                        </StyledTableCell>
                        <StyledTableCell style={{ width: "7%" }}>
                          하
                        </StyledTableCell>
                      </TableRow>
                    </TableHead>
                    {testResult
                      .filter((fi) => fi.examCount === 3)
                      .map((result, option) => (
                        <TableBody>
                          <ColorTableRow>
                            <TableCell
                              style={{
                                fontSize: "1.4rem",
                                textAlign: "center",
                              }}
                            >
                              {result.categoryName}
                            </TableCell>
                            <TableCell
                              style={{
                                fontSize: "1.4rem",
                                textAlign: "center",
                              }}
                            >
                              {result.examStartDate}
                            </TableCell>
                            <TableCell
                              style={{
                                fontSize: "1.4rem",
                                textAlign: "center",
                              }}
                            >
                              {result.examGroupTargetScore <= 0
                                ? "-"
                                : result.examGroupTargetScore}
                            </TableCell>
                            <TableCell
                              style={{
                                fontSize: "1.4rem",
                                textAlign: "center",
                              }}
                            >
                              {result.examGroupScoreAvg <= 0
                                ? "-"
                                : result.examGroupScoreAvg}
                            </TableCell>
                            <TableCell
                              style={{
                                fontSize: "1.4rem",
                                textAlign: "center",
                              }}
                            >
                              {" "}
                              {secondToClock(result.examTimeRequired)}
                            </TableCell>
                            {result.examGroupTargetScore <=
                            result.examScoreResult ? (
                              <TableCell
                                style={{
                                  fontSize: "1.4rem",
                                  textAlign: "center",
                                  color: "#FA7F42",
                                }}
                              >
                                <strong>{result.examScoreResult}</strong>
                              </TableCell>
                            ) : (
                              <TableCell
                                style={{
                                  fontSize: "1.4rem",
                                  textAlign: "center",
                                  color: "#2C95D2",
                                }}
                              >
                                <strong>{result.examScoreResult}</strong>
                              </TableCell>
                            )}
                            <TableCell
                              style={{
                                fontSize: "1.4rem",
                                textAlign: "center",
                              }}
                            >
                              {result.examHighLevelScoreCount <= 0 &&
                              result.examHighLevelCount <= 0
                                ? "-"
                                : (
                                    (result.examHighLevelScoreCount /
                                      result.examHighLevelCount) *
                                    100
                                  ).toFixed(0) + "%"}
                            </TableCell>
                            <TableCell
                              style={{
                                fontSize: "1.4rem",
                                textAlign: "center",
                              }}
                            >
                              {result.examMiddleLevelScoreCount <= 0 &&
                              result.examMiddleLevelCount <= 0
                                ? "-"
                                : (
                                    (result.examMiddleLevelScoreCount /
                                      result.examMiddleLevelCount) *
                                    100
                                  ).toFixed(0) + "%"}
                            </TableCell>
                            <TableCell
                              style={{
                                fontSize: "1.4rem",
                                textAlign: "center",
                              }}
                            >
                              {result.examLowLevelScoreCount <= 0 &&
                              result.examLowLevelCount <= 0
                                ? "-"
                                : (
                                    (result.examLowLevelScoreCount /
                                      result.examLowLevelCount) *
                                    100
                                  ).toFixed(0) + "%"}
                            </TableCell>
                            <TableCell
                              style={{
                                fontSize: "1.4rem",
                                textAlign: "center",
                              }}
                            >
                              {result.examRank}
                            </TableCell>
                            <TableCell
                              style={{
                                fontSize: "1.4rem",
                                textAlign: "center",
                              }}
                            >
                              {result.examinees}
                            </TableCell>
                          </ColorTableRow>
                        </TableBody>
                      ))}
                  </Table>
                </TableContainer>
              </div>
            )}
          </article>
        </section>
      </div>
      <div class="d-flex justify-content-center btnGroup">
        <button onClick={() => GoBack()} className="goBackButton">
          <i class="fas fa-angle-left"></i> 뒤로가기
        </button>
        <button onClick={() => printFunc()} className="printButton">
          <i class="fas fa-print"></i> 인쇄하기
        </button>
      </div>
    </Fragment>
  );
};

export default withRouter(GroupTestResult);
