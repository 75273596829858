import Axios from "axios";
import React, { useContext } from "react";
import { useCallback } from "react";
import { useState } from "react";
import { useEffect } from "react";
import { withRouter } from "react-router";
import { toast } from "react-toastify";
import AuthContext from "../../../context/Auth.context";
import Message from "../../../utilities/Message";
import Header from "../../components/header/Header";
import Navbar from "../Navbar";
import {
  Btn,
  DisabledTableRow,
  IconWrapper,
  Table,
  TableHeader,
  TableRow,
  Wrapper,
} from "../style";
// import * as Sentry from "@sentry/react";

// var process = require("../../../myProcess.json");

const TeacherTask = ({ history }) => {
  const auth = useContext(AuthContext);
  const token = localStorage.getItem("token");
  const [list, setList] = useState();

  const getConsultReq = () => {
    const url = `${process.env.REACT_APP_IP}/teachers/exam/consult`;

    Axios.get(url, { headers: { AccessToken: `Bearer ${token}` } })
      .then((response) => {
        setList(response.data);
      })
      .catch((error) => {
        if (error.response !== undefined) {
          if (error.response.status === 401) {
            toast.error(Message.SERVICE_INVALID_TOKEN);
          } else if (error.response.status === 403) {
            toast.error(Message.ERROR_FORBIDDEN);
          } else if (error.response.status === 500) {
            toast.error(Message.ERROR_500);
          }
          // auth.onLogout();
          // history.push("/login");

          // Sentry.captureException(error);
        }
      });
  };

  useEffect(() => {
    getConsultReq();
  }, []);

  const handleFinish = useCallback((id) => {
    if (window.confirm("면담을 완료하시겠습니까?")) {
      const url = `${process.env.REACT_APP_IP}/teachers/exam/consult/${id}`;

      Axios.put(url, null, { headers: { AccessToken: `Bearer ${token}` } })
        .then((response) => {
          setList((prev) => {
            const newArr = prev.map((item) => {
              if (item.id === response.data.id) {
                return response.data;
              }
              return item;
            });
            return [...newArr];
          });
          toast.success("면담이 종료되었습니다.");
        })
        .catch((error) => {
          if (error.response !== undefined) {
            if (error.response.status === 401) {
              toast.error(Message.SERVICE_INVALID_TOKEN);
            } else if (error.response.status === 403) {
              toast.error(Message.ERROR_FORBIDDEN);
            } else if (error.response.status === 500) {
              toast.error(Message.ERROR_500);
            }
            // auth.onLogout();
            // history.push("/login");
            // Sentry.captureException(error);
          }
        });
    }
  }, []);
  const handleAccept = useCallback((id) => {
    if (window.confirm("해당 면담신청을 수락하시겠습니까?")) {
      const url = `${process.env.REACT_APP_IP}/teachers/exam/consult/${id}`;

      Axios.post(url, {}, { headers: { AccessToken: `Bearer ${token}` } })
        .then((response) => {
          setList((prev) => {
            const newArr = prev.map((item) => {
              if (item.id === response.data.id) {
                return response.data;
              } else {
                return item;
              }
            });
            return [...newArr];
          });
          toast.success("면담신청이 승인되었습니다.");
        })
        .catch((error) => {
          if (error.response !== undefined) {
            if (error.response.status === 401) {
              toast.error(Message.SERVICE_INVALID_TOKEN);
            } else if (error.response.status === 403) {
              toast.error(Message.ERROR_FORBIDDEN);
            } else if (error.response.status === 500) {
              toast.error(Message.ERROR_500);
            }
            // auth.onLogout();
            // history.push("/login");
            // Sentry.captureException(error);
          }
        });
    }
  }, []);

  return (
    <>
      <Header data={{ title: "면담 관리" }} />
      <Navbar title={"면담 관리"} />
      <Wrapper>
        <Table>
          <TableHeader>
            <div className="sm-div">No.</div>
            <div>학습자</div>
            <div>상담요청이유</div>
            <div>면담신청일</div>
            <div className="md-div">상태</div>
          </TableHeader>
          {list && list.length !== 0 ? (
            list.map((item, i) => (
              <TableRow key={item.id} style={{ cursor: "default" }}>
                <div className="sm-div">{i + 1}</div>
                <div>{item.userName}</div>
                <div>{item.purpose}</div>
                <div>{`${
                  item.consultingDate.split("T")[0]
                } ${item.consultingDate
                  .split(".")[0]
                  .split("T")[1]
                  .slice(0, 5)}`}</div>
                <div className="md-div">
                  {item.resultComment === "면담완료" ? (
                    <IconWrapper>면담완료</IconWrapper>
                  ) : item.resultComment === "면담대기중" ? (
                    <Btn onClick={() => handleFinish(item.id)} color="#E1212A">
                      면담완료
                    </Btn>
                  ) : (
                    <Btn onClick={() => handleAccept(item.id)} color="#2E90CF">
                      면담수락
                    </Btn>
                  )}
                </div>
              </TableRow>
            ))
          ) : (
            <DisabledTableRow>
              <div>등록된 면담신청이 없습니다.</div>
            </DisabledTableRow>
          )}
        </Table>
      </Wrapper>
    </>
  );
};

export default withRouter(TeacherTask);
