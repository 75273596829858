import React, { useContext, useState } from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import { toast } from "react-toastify";

import AuthContext from "../../../context/Auth.context";

import axios from "axios";

// var process = require("../../../myProcess.json");
const token = localStorage.getItem("token");

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`wrapped-tabpanel-${index}`}
      aria-labelledby={`wrapped-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `wrapped-tab-${index}`,
    "aria-controls": `wrapped-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
  },
  tab: {
    backgroundColor: "white",
    borderRaidus: "20px",
    fontSize: "16px",
    fontWeight: "900",
    color: "white",
  },
  tabBtn: {
    width: "250px !important",
    marginRight: "0px !important",
    marginLeft: "16px !important",
  },
  tF: {
    marginLeft: "0px !important",
  },
}));

//  Mypage > 내 정보 관리 > 회원정보 수정하기
export default function InfoEdit() {
  const auth = useContext(AuthContext);

  const [valuesDeffault, setValuesDeffault] = React.useState({
    userId: "",
    name: "",
    email: "",
    phone: "",
  });

  const [valuesEdit, setValuesEdit] = React.useState({
    userId: "",
    name: "",
    email: "",
    phone: "",
  });

  useState(() => {
    const url = `${process.env.REACT_APP_IP}/users/info/${auth.uid}`;

    axios
      .get(url, {
        headers: { AccessToken: `Bearer ${token}` },
      })
      .then((response) => {
        // setValuesEdit({
        //   userId: response.data.userId,
        //   name: response.data.name,
        //   email: response.data.email,
        //   phone: response.data.phone,
        // }
        // );
        setValuesEdit(...response.data, { phone: "xxxx" });
        setValuesDeffault(...response.data);
        // setValuesDeffault({
        //   userIdD: response.data.userId,
        //   nameD: response.data.name,
        //   emailD: response.data.email,
        //   phoneD: response.data.phone,
        // }
        // );
      })
      .catch((error) => {
        if (error.response !== undefined) {
          if (error.response.status === 401) {
            toast.error("토큰이 유효하지 않습니다! 로그아웃됩니다.");
            window.location.href = "/login";
          } else if (error.response.status === 403) {
            toast.error("토큰이 만료되었습니다. 다시 로그인해주세요 :)");
            window.location.href = "/login";
          }
        }
      });
  }, []);

  const classes = useStyles();
  const [value, setValue] = React.useState("one");

  const [submitEmail] = useState(false);
  const [confirmStatusEmail, setConfirmStatusEmail] = useState("no");

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const [valuesPass, setValuesPass] = React.useState({
    userId: localStorage.getItem("uid"),
    password: "",
    newpassword: "",
    confirmPassword: "",
  });

  const [valuesPassExit, setvaluesPassExit] = React.useState({
    userId: localStorage.getItem("uid"),
    password: "",
  });

  const [error, setError] = React.useState({
    userIdError: "",
    emailError: "",
    nameError: "",
    phoneError: "",
    pwdError: "",
    confirmPwd: "",
  });

  const isEmail = (email) => {
    const emailRegex =
      /^(([^<>()\].,;:\s@"]+(\.[^<>()\].,;:\s@"]+)*)|(".+"))@(([^<>()¥[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/i;

    return emailRegex.test(email);
  };

  const isPwd = (pass) => {
    const pwdRegex =
      /^.*(?=.{6,20})(?=.*[0-9])(?=.*[a-zA-Z])(?=.*[@$!%*#?&]).*$/;
    return pwdRegex.test(pass);
  };

  const isPhone = (phone) => {
    const phoneRegex = /^[0-9\b -]{0,13}$/;
    return phoneRegex.test(phone);
  };

  const confirmPassword = (pass, confirmPass) => {
    return pass === confirmPass;
  };

  const onTextValidation = () => {
    let emailError = "";
    let nameError = "";
    let phoneError = "";

    if (valuesEdit.email === valuesDeffault.email) {
      setConfirmStatusEmail(true);
      emailError = "";
    } else {
      if (!isEmail(valuesEdit.email)) emailError = "email 형식이 아닙니다.";
      if (!confirmStatusEmail || confirmStatusEmail === "no")
        emailError = "이메일 중복확인 하세요";
    }
    if (valuesEdit.phone === valuesDeffault.phone) {
      phoneError = "";
    } else {
      if (!isPhone(valuesEdit.phone)) phoneError = "휴대폰 형식이 아닙니다.";
    }
    if (valuesEdit.name === valuesDeffault.name) {
      nameError = "";
    } else {
      if (valuesEdit.name.length === 0) nameError = "이름을 입력해주세요.";
    }

    setError({
      emailError,
      nameError,
      phoneError,
    });

    if (emailError || nameError || phoneError) return false;
    return true;
  };

  const onTextValidationPass = () => {
    let pwdError = "";
    let confirmPwd = "";

    if (!isPwd(valuesPass.newpassword))
      pwdError = "비밀번호 조건을 만족 할 수 없습니다.";
    if (!confirmPassword(valuesPass.newpassword, valuesPass.confirmPassword))
      confirmPwd = "비밀번호가 일치하지 않습니다.";
    if (valuesPass.userId === valuesPass.newpassword)
      pwdError = "아이디를 비밀번호로 사용 할 수 없습니다.";

    setError({
      pwdError,
      confirmPwd,
    });

    if (pwdError || confirmPwd) return false;
    return true;
  };

  const handleChangeFormPass = (e) => {
    setValuesPass({ ...valuesPass, [e.target.name]: e.target.value });
  };

  const handleChangeFormEdit = (e) => {
    setValuesEdit({ ...valuesEdit, [e.target.name]: e.target.value });
  };

  const handleChangeFormExit = (e) => {
    setvaluesPassExit({ ...valuesPassExit, [e.target.name]: e.target.value });
  };

  const handleSubmitEdit = (e) => {
    e.preventDefault();
    const valid = onTextValidation();
    if (!valid) console.error("invalid");
    else if (
      valuesEdit.email === valuesDeffault.email &&
      valuesEdit.name === valuesDeffault.name &&
      valuesEdit.phone === valuesDeffault.phone
    ) {
      toast.error("변경하실 회원정보가 없습니다.");
    } else {
      const token = localStorage.getItem("token");
      const urlUserEdit = `${process.env.REACT_APP_IP}/users`;
      const userid = localStorage.getItem("uid");

      axios
        .put(
          urlUserEdit,
          {
            userId: userid,
            name: valuesEdit.name,
            email: valuesEdit.email,
            phone: valuesEdit.phone,
          },
          {
            headers: {
              AccessToken: `Bearer ${token}`,
            },
          }
        )
        .then((response) => {
          toast.success("회원정보가 변경되었습니다.");
          window.location.href = "/index/mypage/myinformation";
        })
        .catch((error) => {
          //
          setValuesEdit({
            userId: valuesDeffault.userId,
            name: valuesDeffault.name,
            email: valuesDeffault.email,
            phone: valuesDeffault.phone,
          });
        });
    }
  };

  const handleSubmitPassword = (e) => {
    e.preventDefault();
    const valid = onTextValidationPass();

    if (!valid) console.error("invalid");
    else {
      const token = localStorage.getItem("token");
      const url = `${process.env.REACT_APP_IP}/users/sign/auth/`;
      const userid = localStorage.getItem("uid");

      axios
        .post(
          url,
          {
            userId: userid,
            password: valuesPass.password,
          },
          {
            headers: {
              AccessToken: `Bearer ${token}`,
            },
          }
        )
        .then((response) => {
          //
          //alert(response.data);
          if (response.data === 0) {
            toast.error("기존 비밀번호가 틀립니다.");
            setValuesPass({
              password: "",
              newpassword: "",
              confirmPassword: "",
            });
          } else if (response.data === 1) {
            const token = localStorage.getItem("token");
            const userid = localStorage.getItem("uid");
            const urlEdit = `${process.env.REACT_APP_IP}/users/sign/pw`;

            //let form = new FormData();
            //form.append('userId', userId);
            //form.append('password', valuesPass.newpassword);

            axios
              .put(
                urlEdit,
                {
                  userId: userid,
                  password: valuesPass.newpassword,
                },
                {
                  headers: {
                    AccessToken: `Bearer ${token}`,
                  },
                }
              )

              .then((response) => {
                if (response.data === 0) toast.error("인증에 실패 했습니다.");
                else if (response.data === 1) {
                  toast.success("비밀번호가 변경되었습니다.");
                  setTimeout(() => {
                    window.location.href = "/index/mypage/myinformation";
                  }, 1000);
                } else toast.error("비밀번호 규정에 맞지 않습니다.");
              });
          }
        })

        .catch((error) => {
          //
          toast.error("서버 값을 받지 못했습니다.");
          setValuesPass({ password: "", newpassword: "", confirmPassword: "" });
        });
    }
  };

  const handleSubmitExit = (e) => {
    e.preventDefault();

    const token = localStorage.getItem("token");
    const userid = localStorage.getItem("uid");
    const urlUserExit = `${process.env.REACT_APP_IP}/users/secession/${userid}/${valuesPassExit.password}`;

    axios
      .put(
        urlUserExit,
        {
          userId: userid,
          password: valuesPassExit.password,
        },
        {
          headers: {
            AccessToken: `Bearer ${token}`,
          },
        }
      )
      .then((response) => {
        //alert(response.data)
        if (response.data === "success") {
          toast.error("회원탈퇴 되었습니다.. 감사합니다.");
          auth.onLogout();
          window.location.href = "/";
        } else toast.error("비밀번호가 틀렸습니다.");
      })
      .catch((error) => {
        toast.error(
          "서버와의 연결이 원할 하지 않습니다. 잠시 후에 다시 시도 해주세요."
        );
      });

    /*
    axios.get(urlUserExit).then(response => {
        const { data } = response
        
        
        if (data === 'success') {
          toast.error("회원 탈퇴 합니다. 감사합니다.");
        } else {
          toast.error("입력한 비밀번호를 다시 확인 해주세요.");
          setValuesEdit({ passowrd: valuesPassExit.password });
        }
      })
      .catch(error => {
          
          toast.error("서버와의 연결이 원할 하지 않습니다. 잠시 후에 다시 시도 해주세요.");
      })
    */
  };

  const handleConfirmEmail = () => {
    if (!submitEmail) {
      if (!valuesEdit.email || !isEmail(valuesEdit.email)) {
        toast.error(valuesEdit.email + "는 사용할 수 없는 형식입니다.");
      } else {
        const url = `${process.env.REACT_APP_IP}/users/email/checkEmail/${valuesEdit.email}`;
        setConfirmStatusEmail(false);
        axios
          .get(url)
          .then((response) => {
            const { data } = response;
            if (data === "success") {
              setConfirmStatusEmail(true);
              toast.success("사용 가능한 이메일입니다..");
            } else {
              setConfirmStatusEmail(false);
              toast.error("이미 사용하고 있는 이메일입니다.");
            }
          })
          .catch((error) => {
            toast.error(
              "서버와의 연결이 원할 하지 않습니다. 잠시 후에 다시 시도 해주세요."
            );
          });
      }
    }
  };

  return (
    <div className={classes.root}>
      <AppBar position="static">
        <Tabs className={classes.tab} value={value} onChange={handleChange}>
          <Tab
            className={classes.tabBtn}
            value="one"
            label="회원정보 수정하기"
            wrapped
            fullWidth
            {...a11yProps("one")}
          />
          <Tab
            className={classes.tabBtn}
            value="two"
            label="비밀번호 변경하기"
            wrapped
            fullWidth
            {...a11yProps("two")}
          />
          <Tab
            className={classes.tabBtn}
            value="three"
            label="탈퇴하기"
            wrapped
            fullWidth
            {...a11yProps("three")}
          />
        </Tabs>
      </AppBar>
      <TabPanel value={value} index="one">
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <form className={classes.form} onSubmit={handleSubmitEdit}>
              <Grid item xs={12}>
                <TextField
                  helperText="※ ID 변경은 고객센터로 문의주시기 바랍니다."
                  disabled
                  id="outlined-disabled"
                  variant="outlined"
                  margin="normal"
                  label="아이디"
                  value={`${localStorage.getItem("uid")}(변경 불가능)`}
                  fullWidth
                />
              </Grid>
              <Grid item xs={9}>
                <TextField
                  variant="outlined"
                  margin="normal"
                  fullWidth
                  autoComplete="email"
                  label="이메일"
                  name="email"
                  value={valuesEdit.email}
                  onChange={handleChangeFormEdit}
                />
                <div
                  style={{
                    color: "red",
                    fontSize: "12px",
                    margin: "-5px 0 10px 15px",
                    fontFamily: "Noto Sans KR",
                  }}
                >
                  {error.emailError}
                </div>
              </Grid>
              <Grid item xs={3}>
                <Button
                  type="submit"
                  size="large"
                  variant="contained"
                  color="primary"
                  //endIcon={<EmojiPeopleIcon/>}
                  onClick={handleConfirmEmail}
                  style={{
                    margin: "16px 0 0 10px",
                    padding: "16px 52px",
                    borderRadius: "5px",
                    fontSize: "13px",
                    fontWeight: "500",
                    fontFamily: "Noto Sans KR",
                  }}
                >
                  이메일 중복확인
                </Button>
              </Grid>
              <Grid item xs={12}>
                <TextField
                  variant="outlined"
                  margin="normal"
                  autoComplete="name"
                  fullWidth
                  label="이름"
                  name="name"
                  value={valuesEdit.name}
                  onChange={handleChangeFormEdit}
                />
                <div
                  style={{
                    color: "red",
                    fontSize: "12px",
                    margin: "-5px 0 10px 15px",
                    fontFamily: "Noto Sans KR",
                  }}
                >
                  {error.nameError}
                </div>
              </Grid>
              <Grid item xs={12}>
                <TextField
                  variant="outlined"
                  margin="normal"
                  autoComplete="phone"
                  fullWidth
                  label="전화번호"
                  name="phone"
                  value={valuesEdit.phone}
                  onChange={handleChangeFormEdit}
                />
                <div
                  style={{
                    color: "red",
                    fontSize: "12px",
                    fontFamily: "Noto Sans KR",
                  }}
                >
                  {error.phoneError}
                </div>
              </Grid>
              <div style={{ width: "100%", textAlign: "center" }}>
                <div
                  style={{
                    paddingTop: "10px",
                    borderBottom: "1px solid #e3e3e3",
                    margin: "10px 0",
                  }}
                ></div>
              </div>
              <Button
                type="submit"
                size="large"
                variant="contained"
                color="primary"
                className={classes.button}
                style={{
                  margin: "auto",
                  marginTop: "20px",
                  padding: "10px 30px",
                  borderRadius: "50px",
                }}
              >
                회원정보 변경
              </Button>
            </form>
          </Grid>
        </Grid>
      </TabPanel>
      <TabPanel value={value} index="two">
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <form className={classes.form} onSubmit={handleSubmitPassword}>
              <TextField
                variant="outlined"
                margin="normal"
                required
                autoFocus
                autoComplete=""
                fullWidth
                label="기존 비밀번호를 입력하세요."
                name="password"
                type="password"
                value={valuesPass.password}
                onChange={handleChangeFormPass}
              />
              <TextField
                variant="outlined"
                margin="normal"
                required
                autoComplete=""
                fullWidth
                helperText="영문과 숫자, 특수문자(@$!%*#?&)를 조합하여 8-15자 사이로 입력해 주세요."
                label="신규 비밀번호를 입력하세요."
                name="newpassword"
                type="password"
                value={valuesPass.newpassword}
                onChange={handleChangeFormPass}
              />
              <div
                style={{
                  color: "red",
                  fontSize: "12px",
                  margin: "-5px 0 10px 15px",
                  fontFamily: "Noto Sans KR",
                }}
              >
                {error.pwdError}
              </div>
              <TextField
                variant="outlined"
                margin="normal"
                required
                autoComplete=""
                fullWidth
                label="비밀번호 다시한번 입력하세요."
                name="confirmPassword"
                type="password"
                value={valuesPass.confirmPassword}
                onChange={handleChangeFormPass}
              />
              <div
                style={{
                  color: "red",
                  fontSize: "12px",
                  margin: "-5px 0 10px 15px",
                  fontFamily: "Noto Sans KR",
                }}
              >
                {error.confirmPwd}
              </div>
              <div style={{ width: "100%", textAlign: "center" }}>
                <div
                  style={{
                    paddingTop: "10px",
                    borderBottom: "1px solid #e3e3e3",
                    margin: "10px 0",
                  }}
                ></div>
              </div>
              <Button
                type="submit"
                size="large"
                variant="contained"
                color="primary"
                className={classes.button}
                style={{
                  margin: "auto",
                  marginTop: "20px",
                  padding: "10px 30px",
                  borderRadius: "50px",
                }}
              >
                비밀번호 변경
              </Button>
            </form>
          </Grid>
        </Grid>
      </TabPanel>
      <TabPanel value={value} index="three">
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <div>
              <p className="secotext">
                * 회원 탈퇴를 하더라도 결제하신 날짜부터 한 달 간은 학습이
                가능합니다.
              </p>
              <p className="secotext">
                * 회원 탈퇴는 전월 결제하신 일로부터 1달 뒤 완료됩니다.
              </p>
              <p className="secotext">
                * 회원 탈퇴 시 3개월간 개인정보 저장 후 3개월이 지나도
                탈퇴해지가 없을 시 자동으로 개인정보가 삭제됩니다.
              </p>
            </div>
            <form className={classes.form} onSubmit={handleSubmitExit}>
              <div style={{ width: "100%", textAlign: "center" }}>
                <div
                  style={{
                    paddingTop: "10px",
                    borderBottom: "1px solid #e3e3e3",
                    margin: "10px 0",
                  }}
                ></div>
              </div>
              <TextField
                variant="outlined"
                margin="normal"
                required
                autoFocus
                autoComplete=""
                fullWidth
                label="비밀번호를 입력하세요."
                helperText="비밀번호 입력 후 탈퇴하기 버튼을 누르면 탈퇴처리가 완료됩니다."
                name="password"
                type="password"
                value={valuesPassExit.password}
                onChange={handleChangeFormExit}
              />
              <div
                style={{
                  color: "red",
                  fontSize: "12px",
                  margin: "-5px 0 10px 15px",
                  fontFamily: "Noto Sans KR",
                }}
              >
                {error.confirmPwd}
              </div>
              <div style={{ width: "100%", textAlign: "center" }}>
                <div
                  style={{
                    paddingTop: "10px",
                    borderBottom: "1px solid #e3e3e3",
                    margin: "10px 0",
                  }}
                ></div>
              </div>
              <Button
                type="submit"
                size="large"
                variant="contained"
                color="primary"
                className={classes.button}
                style={{
                  margin: "auto",
                  marginTop: "20px",
                  padding: "10px 30px",
                  borderRadius: "50px",
                }}
              >
                <span className="secosub1">탈퇴하기</span>
              </Button>
            </form>
          </Grid>
        </Grid>
      </TabPanel>
    </div>
  );
}
