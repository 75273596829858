import axios from "axios";
import { useMutation } from "react-query";

// const process = require("../../myProcess.json");

const useSelectedProblemCancel = () => {
  const token = localStorage.getItem("token");
  const { mutate, isLoading } = useMutation(async ({ examId, problemId }) => {
    return await axios
      .delete(
        `${process.env.REACT_APP_IP}/teachers/exam/creation/pre-question/${examId}/${problemId}`,
        {
          headers: { AccessToken: `Bearer ${token}` },
        }
      )
      .then((res) => {
        return res.data;
      });
  });
  return { mutate, isLoading };
};
export default useSelectedProblemCancel;
