import React from "react";
import { Button, Modal } from "react-bootstrap";
import { Content, Title, Wrapper } from "../style";

const Detail = ({ data, modalType, handleClose }) => {
  return (
    <Modal
      className="fade modal"
      size="md"
      show={!!modalType}
      onHide={handleClose}
    >
      <Modal.Header>
        <Modal.Title
          className="mt-12 pr-4 pl-4"
          style={{ width: "100%", marginLeft: "5px", padding: "15px" }}
        >
          <Wrapper>
            <Wrapper>
              <div>작성자:</div>
              <div style={{ marginLeft: "5px" }}>{data?.authorName}</div>
            </Wrapper>
            <Wrapper>
              <div>작성일:</div>
              <div style={{ marginLeft: "5px" }}>
                {data?.regDate.replace("T", " ")}
              </div>
            </Wrapper>
          </Wrapper>
          <Title>{data?.title}</Title>
        </Modal.Title>
        <Button variant="" className="close" onClick={handleClose}>
          <span>&times;</span>
        </Button>
      </Modal.Header>
      <Modal.Body style={{ overflow: "auto" }}>
        <Content>
          {data.content.split("\n").map((line, i) => {
            return (
              <span key={i}>
                {line}
                <br />
              </span>
            );
          })}
        </Content>
      </Modal.Body>
    </Modal>
  );
};

export default Detail;
