import React from "react";
import styled, { keyframes } from "styled-components";

const logoFade = keyframes`
  0% {
    opacity: 0;
    transform: translate3d(0, 100%, 0);
  }
  50% {
    opacity: 1;
    transform: translateZ(0);
  }
  100% {
    opacity: 0;
    transform: scale(2);
  }
`;

const LogoWrapper = styled.div`
  width: 400px;
  height: 200px;
  animation: ${logoFade} 1.5s;
`;

const Logo = styled.img`
  width: fit-content;
  height: fit-content;
  object-fit: fill;
`;

const Wrapper = styled.div`
  height: 100vh;
  width: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export default ({ fluid = false }) => (
  <Wrapper>
    <LogoWrapper>
      <Logo src="/logo.png" />
    </LogoWrapper>
  </Wrapper>
);
