import axios from "axios";
import FileSaver from "file-saver";
import { useQuery } from "react-query";
import swal from "sweetalert";

const useDownload = (url) => {
  const token = localStorage.getItem("token");
  const { refetch, isLoading } = useQuery(
    ["download", url],
    async () => {
      return await axios
        .get(url, {
          headers: { AccessToken: `Bearer ${token}` },
          responseType: "blob",
        })
        .then((res) => {
          if (res.headers["content-disposition"]) {
            FileSaver.saveAs(
              res.data,
              decodeURI(
                res.headers["content-disposition"].split("filename=")[1]
              ).normalize("NFC")
            );
          } else {
            swal("상담기록이 없습니다");
          }
        })
        .catch((err) => {
          console.error(err);
        });
    },
    { refetchOnWindowFocus: false, enabled: false }
  );
  return { refetch, isLoading };
};

export default useDownload;
