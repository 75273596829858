import React from "react";
import useStudentDetail from "../../../../hooks/teacher/useStudentDetail";
import useDownload from "../../../../hooks/useDownload";
import { BtnLg, Info, Separator } from "../../style";

// let process = require("../../../../myProcess.json");

const UserInfo = ({ userId, onClick }) => {
  const { data: userData } = useStudentDetail(userId);
  const { refetch, isLoading } = useDownload(
    `${process.env.REACT_APP_IP}/excel/download/${userId}`
  );

  const handleDownloadClick = () => {
    if (isLoading) return;
    refetch();
  };

  return (
    <div className="card" style={{ width: "100%" }}>
      <div className="card-body">
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <div>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                marginBottom: "40px",
                width: "650px",
              }}
            >
              <div>
                <Info>
                  <div>이름</div>
                  <div>{userData?.name}</div>
                </Info>
                <Info>
                  <div>생일</div>
                  <div>{`${userData?.birthday.slice(
                    0,
                    4
                  )}.${userData?.birthday.slice(
                    4,
                    6
                  )}.${userData?.birthday.slice(6)}`}</div>
                </Info>
                <Info>
                  <div>성별</div>
                  <div>{userData?.gender}</div>
                </Info>
              </div>
              <Separator />
              <div>
                <Info>
                  <div>전화번호</div>
                  <div>{userData?.phone}</div>
                </Info>
                <Info>
                  <div>이메일</div>
                  <div>{userData?.email}</div>
                </Info>
              </div>
            </div>
            <Info style={{ width: "100%" }}>
              <div>기타</div>
              <div>{userData?.education}</div>
            </Info>
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              minWidth: "160px",
            }}
          >
            <BtnLg onClick={onClick} color="#d9534f" type="button">
              상담일지 작성
            </BtnLg>
            <BtnLg onClick={handleDownloadClick} color="#ec7422" type="button">
              현황 인쇄하기
            </BtnLg>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UserInfo;
