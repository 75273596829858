import axios from "axios";
import { useContext } from "react";
import { useMutation } from "react-query";
import AuthContext from "../../context/Auth.context";

// const process = require("../../myProcess.json");

const useMutateExam = () => {
  const token = localStorage.getItem("token");
  const auth = useContext(AuthContext);
  const { mutate, isLoading } = useMutation(async (examId) => {
    return await axios
      .put(
        `${process.env.REACT_APP_IP}/teachers/exam/active`,
        {
          userId: auth?.uid,
          groupId: auth?.groupId,
          examId,
          examStatus: 0,
        },
        {
          headers: { AccessToken: `Bearer ${token}` },
        }
      )
      .then((res) => {
        return res.data;
      });
  });
  return { mutate, isLoading };
};

export default useMutateExam;
