import axios from "axios";
import { useMutation } from "react-query";

// const process = require("../../../myProcess.json");

const useEditProblem = () => {
  const token = localStorage.getItem("token");
  const { mutate, isLoading } = useMutation(async (data) => {
    return await axios
      .put(
        `${process.env.REACT_APP_IP}/teachers/exam/creation/problem`,
        data,
        {
          headers: { AccessToken: `Bearer ${token}` },
        }
      )
      .then((res) => {
        return res.data;
      });
  });

  return { mutate, isLoading };
};

export default useEditProblem;
