import React from "react";
import styles from "./RequestForm.module.scss";
import { SignUpConfirm, Backdrop } from "../../ui";
import { useCustomState } from "../../../state/state";

export default () => {
  const [state, actions] = useCustomState();

  return (
    <div
      className={[styles.wrapper, state.request.modal && styles.show].join(" ")}
    >
      <div className={styles.modal}>
        <i onClick={() => actions.toggleRequest()} className="las la-times" />

        <SignUpConfirm />
      </div>
    </div>
  );
};
