import React, { useEffect } from "react";
import { useState } from "react";
import { useHistory } from "react-router-dom";
import astroWorker from "../../../assets/images/astro.gif";
import { Button } from "react-bootstrap";

const Error404 = () => {
  const history = useHistory();
  const [astro, setAstro] = useState(astroWorker);

  const [count, setCount] = React.useState(10);

  useEffect(() => {
    const timer = setInterval(() => {
      setCount((prevTime) => prevTime - 1);
    }, 1000);
    return count <= 1 && clearInterval(timer);
  }, []);

  setTimeout(() => {
    // history.push("/");
    window.location.href = "/";
  }, 10000);

  return (
    <>
      <div
        style={{
          height: "100vh",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "column",
        }}
      >
        <img
          src={astro}
          className="astro"
          alt="astro"
          style={{ width: "50%" }}
        />
        <h2 style={{ fontSize: "38px" }}>
          존재하지 않는 페이지이거나 잘못된 접근을 시도하고 있습니다.
        </h2>
        <br></br>
        <h3 style={{ fontSize: "24px" }}>{count}초 후 메인페이지로 이동합니다.</h3>
        <a href="/"> <Button size={"lg"}>돌아가기</Button>
        </a></div>
      ;
    </>
  );
};

export default Error404;
