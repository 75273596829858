import styled, { css } from "styled-components";

export const NavWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.3);
  height: 55px;
  margin-bottom: 50px;
`;

export const NavList = styled.div`
  width: 100%;
  max-width: 1140px;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
`;

export const NavItem = styled.div`
  color: ${(props) => (props.selected ? "#2E90CF" : "rgba(0, 0, 0, 0.6)")};
  font-size: 16px;
  margin-right: 15px;
  font-weight: 500;
  padding: 15px 0;
  cursor: pointer;
  border-bottom: 3px solid
    ${(props) => (props.selected ? "#2E90CF" : "transparent")};
`;

export const Wrapper = styled.div`
  max-width: 1140px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  margin-bottom: 20px;
`;

export const DetailWrapper = styled.div`
  max-width: 1100px;
  margin: 0 auto;
  margin-top: 50px;
`;

export const Table = styled.div`
  width: 100%;
  box-shadow: 3px 6px 18px -6px rgba(0, 0, 0, 0.29);
  border-radius: 5px;
  overflow: hidden;
`;

export const Title = styled.div`
  margin-top: 50px;
  padding-bottom: 10px;
  width: 100%;
  font-size: 26px;
  font-weight: 600;
  margin-bottom: 15px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
`;

export const TableHeader = styled.div`
  padding: 7px 0;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: space-between;
  align-items: center;
  div {
    text-align: center;
    font-size: 16px;
    color: white;
    width: 100%;
  }
  .sm-div {
    width: 25%;
  }
  .md-div {
    width: 50%;
  }

  .inputWrapper {
    width: 120%;
  }
`;

export const TableRow = styled.div`
  padding: 10px 0;
  height: 60px;
  width: 100%;
  display: flex;
  justify-content: space-around;
  align-items: center;
  font-size: 16px;
  cursor: pointer;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
  &:hover {
    background-color: rgba(0, 0, 0, 0.05);
  }
  div {
    text-align: center;
    font-size: 16px;
    width: 100%;
    color: rgba(0, 0, 0, 0.8);
  }
  .sm-div {
    width: 25%;
  }
  .md-div {
    width: 50%;
  }
  .status-span {
    height: 60px;
    color: rgba(0, 0, 0, 0.6);
  }

  .inputWrapper {
    width: 120%;
    padding-right: 10px;
    input {
      border: 1px solid rgba(0, 0, 0, 0.1);
      width: 100%;
      &::placeholder {
        font-size: 16px;
      }
    }
  }
`;

export const DisabledTableRow = styled(TableRow)`
  cursor: default;
  &:hover {
    background-color: none;
  }
`;

export const Input = styled.input`
  height: 40px;
  width: 50%;
  border: 1px solid rgba(0, 0, 0, 0.1);
  margin-right: 10px;
  outline: none;
  font-size: 14px;
  &:focus {
    outline: none;
  }
  &::placeholder {
    font-size: 14px;
  }
`;

export const BtnWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 30px 0;
`;

export const Btn = styled.button`
  background-color: ${(props) =>
    props.disabled ? "rgba(0,0,0,0.4)" : props.color};
  border: 1px solid
    ${(props) => (props.disabled ? "rgba(0,0,0,0.4)" : props.color)};
  padding: 7px;
  margin: 0 5px;
  color: white;
  border-radius: 5px;
  font-size: 14px;
  cursor: ${(props) => (props.disabled ? "default" : "pointer")};
  &:hover {
    ${(props) =>
      !props.disabled &&
      css`
        background-color: white;
        color: ${(props) => props.color};
      `}
  }
  &:focus {
    outline: none;
  }
`;

export const IconWrapper = styled.span`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  text-align: center;
  height: 40px !important;
  color: rgba(0, 0, 0, 0.4);
  i {
    margin-right: 5px;
  }
`;

export const Separator = styled.div`
  margin: auto 0;
  width: 0.5px;
  height: 50px;
  border: 0.5px solid rgba(0, 0, 0, 0.3);
`;

export const Info = styled.div`
  font-size: 16px;
  color: rgba(0, 0, 0, 0.6);
  display: flex;
  div:first-child {
    color: #ec7422;
    width: 80px;
  }
`;

export const BtnLg = styled(Btn)`
  padding: 10px 30px;
  margin-bottom: 5px;
`;

export const NameTag = styled.div`
  position: absolute;
  top: -40px;
  left: 50px;
  height: 40px;
  background-color: #ec7422;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  font-size: 16px;
  width: 150px;
  border-top-right-radius: 10px;
  border-top-left-radius: 10px;
`;

export const TitleWrapper = styled.div`
  display: flex;
  justify-content: space-around;
`;
