import React, { useState } from "react";
import { withRouter } from "react-router-dom";
import AuthContext from "../context/Auth.context";
import Message from "../utilities/Message";
import axios from "axios";
import { toast } from "react-toastify";

// var process = require("../myProcess.json");

/**
 * https://ourcodeworld.com/articles/read/257/how-to-get-the-client-ip-address-with-javascript-only
 *
 * Get the user IP throught the webkitRTCPeerConnection
 * @param onNewIP {Function} listener function to expose the IP locally
 * @return undefined
 */
function getUserIP(onNewIP) {
  //  onNewIp - your listener function for new IPs
  //compatibility for firefox and chrome
  var myPeerConnection =
    window.RTCPeerConnection ||
    window.mozRTCPeerConnection ||
    window.webkitRTCPeerConnection;
  var pc = new myPeerConnection({
      iceServers: [],
    }),
    noop = function() {
    },
    localIPs = {},
    ipRegex = /([0-9]{1,3}(\.[0-9]{1,3}){3}|[a-f0-9]{1,4}(:[a-f0-9]{1,4}){7})/g;

  function iterateIP(ip) {
    if (!localIPs[ip]) onNewIP(ip);
    localIPs[ip] = true;
  }

  //create a bogus data channel
  pc.createDataChannel("");

  // create offer and set local description
  pc.createOffer()
    .then(function(sdp) {
      sdp.sdp.split("\n").forEach(function(line) {
        if (line.indexOf("candidate") < 0) return;
        line.match(ipRegex).forEach(iterateIP);
      });

      pc.setLocalDescription(sdp, noop, noop);
    })
    .catch(function(reason) {
      // An error occurred, so handle the failure to connect
    });

  //listen for candidate events
  pc.onicecandidate = function(ice) {
    if (
      !ice ||
      !ice.candidate ||
      !ice.candidate.candidate ||
      !ice.candidate.candidate.match(ipRegex)
    )
      return;
    ice.candidate.candidate.match(ipRegex).forEach(iterateIP);
  };
}

let clientIP;
getUserIP(function(ip) {
  clientIP = ip;
});

//사용하고자 하는 컴포넌트 최상위에 지정할 Provider컴포넌트
const AuthProvider = ({ children, history }) => {
  const onLogin = (token, refreshToken, data) => {
    //  TODO: clientIP를 서버로 전달해서 토큰 생성 및 검증에 활용
    if (token && refreshToken && data) {
      localStorage.setItem("token", token);
      localStorage.setItem("refreshToken", refreshToken);
      localStorage.setItem("uid", data.userId);
      localStorage.setItem("userName", data.name);
      localStorage.setItem("userEmail", data.email);
      localStorage.setItem("userPhone", data.phone);
      localStorage.setItem("userType", data.type);
      localStorage.setItem("status", data.status);
      localStorage.setItem("freeYn", data.freeYn);
      localStorage.setItem("allowLanguages", data.allowLanguages);
      localStorage.setItem("classId", data.classId);
      localStorage.setItem("subClassId", data.subClassId);
      localStorage.setItem("groupId", data.groupId);
      localStorage.setItem("initialPw", data.initialPw);
      setAuth({
        ...auth,
        auth: true,
        uid: String(data.userId),
        name: String(data.name),
        type: String(data.type),
        email: String(data.email),
        status: String(data.status),
        token: String(token),
        refreshToken: String(refreshToken),

        authToken: { AccessToken: `Bearer ${token}` },
        freeYn: String(data.freeYn),
        allowLanguage: String(data.allowLanguages),
        classId: String(data.classId),
        subClassId: String(data.subClassId),
        groupId: String(data.groupId),
        initialPw: String(data.initialPw),
      });

      // 유저 정보 헤더 추가
      axios.defaults.headers.common["uid"] = data.userId;
      axios.defaults.headers.common["status"] = data.status;
      axios.defaults.headers.common["userType"] = data.type;
      axios.defaults.headers.common["freeYn"] = data.freeYn;
      axios.defaults.headers.common["allowLanguages"] = data.allowLanguages;
      axios.defaults.headers.common["email"] = data.email;
      axios.defaults.headers.common["initialPw"] = data.initialPw;
      axios.defaults.headers.common["classId"] = data.classId;
      axios.defaults.headers.common["groupId"] = data.groupId;
    } else {
      localStorage.removeItem("token");
      localStorage.removeItem("refreshToken");
      localStorage.removeItem("uid");
      localStorage.removeItem("userName");
      localStorage.removeItem("userEmail");
      localStorage.removeItem("userPhone");
      localStorage.removeItem("userType");
      localStorage.removeItem("status");
      localStorage.removeItem("freeYn");
      localStorage.removeItem("allowLanguages");
      localStorage.removeItem("classId");
      localStorage.removeItem("subClassId");
      localStorage.removeItem("groupId");
      localStorage.removeItem("initialPw");
      setAuth({
        ...auth,
        auth: false,
        uid: "",
        name: "",
        type: -1,
        email: "",
        status: "",
        token: "",
        refreshToken: "",
        authToken: "",
        freeYn: "",
        allowLanguages: "",
        classId: "",
        subClassId: "",
        initialPw: "",
      });
    }
  };

  const onLogout = () => {
    const token = localStorage.getItem("token");

    axios
      .post(
        `${process.env.REACT_APP_IP}/users/logout`,
        {},
        {
          headers: {
            AccessToken: `Bearer ${token}`,
          },
        },
      )
      .then((res) => {
        console.log(res);
      });
    delete axios.defaults.headers.common["uid"];
    delete axios.defaults.headers.common["status"];
    delete axios.defaults.headers.common["userType"];
    delete axios.defaults.headers.common["freeYn"];
    delete axios.defaults.headers.common["allowLanguages"];
    delete axios.defaults.headers.common["email"];
    delete axios.defaults.headers.common["initialPw"];
    delete axios.defaults.headers.common["classId"];
    delete axios.defaults.headers.common["groupId"];

    setAuth((auth) => {
      return {
        ...auth,
        auth: false,
        uid: "",
        name: "",
        type: -1,
        email: "",
        status: "",
        token: "",
        refreshToken: "",
        authToken: "",
        freeYn: "",
        allowLanguages: "",
        classId: "",
        subClassId: "",
        initialPw: "",
      };
    });

    localStorage.removeItem("token");
    localStorage.removeItem("refreshToken");
    localStorage.removeItem("uid");
    localStorage.removeItem("userName");
    localStorage.removeItem("userEmail");
    localStorage.removeItem("userPhone");
    localStorage.removeItem("userType");
    localStorage.removeItem("status");
    localStorage.removeItem("freeYn");
    localStorage.removeItem("allowLanguages");
    localStorage.removeItem("classId");
    localStorage.removeItem("subClassId");
    localStorage.removeItem("groupId");
    localStorage.removeItem("initialPw");
  };

  let initialState = {
    auth: false,
    uid: "",
    name: "",
    type: -1,
    email: "",
    status: "",
    token: "",
    refreshToken: "",
    authToken: "",
    freeYn: "",
    allowLanguages: "",
    classId: "",
    subClassId: "",
    groupId: "",
    initialPw: "",
    onLogin,
    onLogout,
  };
  if (localStorage.getItem("token") !== null) {
    initialState = {
      ...initialState,
      auth: true,
      uid: String(localStorage.getItem("uid")),
      name: String(localStorage.getItem("userName")),
      type: String(localStorage.getItem("userType")),
      email: String(localStorage.getItem("userEmail")),
      status: String(localStorage.getItem("status")),
      token: String(localStorage.getItem("token")),
      refreshToken: String(localStorage.getItem("refreshToken")),
      authToken: { AccessToken: `Bearer ${localStorage.getItem("token")}` },
      freeYn: String(localStorage.getItem("freeYn")),
      allowLanguages: String(localStorage.getItem("allowLanguages")),
      classId: String(localStorage.getItem("classId")),
      subClassId: String(localStorage.getItem("subClassId")),
      groupId: String(localStorage.getItem("groupId")),
      initialPw: String(localStorage.getItem("initialPw")),
    };
  }

  const [auth, setAuth] = useState(initialState);

  if (auth.auth) {
    const userPath = document.location.href.split("/")[3];
    const userType = auth.type; // 1: 학습자, 2: 출제자
    const status = auth.status; // 0: 대기, 1: 승인, 2: 거절, 3: 탈퇴
    // userPath 에 myclass 옵션을 추가함 -2021 12 27
    if (userPath === "teacher" && userType === 1) {
      toast.error(":: " + Message.SERVICE_TEACHER_ONLY);
      history.push("/teacher");
    } else if (userPath === "sgroup" && userType !== "1") {
      toast.error(Message.SERVICE_STUDENT_ONLY);
      history.push("/teacher");
    }
  }

  return <AuthContext.Provider value={auth}>{children}</AuthContext.Provider>;
};

export default withRouter(AuthProvider);
