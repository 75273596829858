import React, { useEffect } from "react";
import styled from "styled-components";
import { useForm } from "react-hook-form";

import ModalButtons from "../../../../components/modalComponents/ModalButtons";
import useEditProblem from "../../../../../hooks/teacher/exam/useEditProblem";
import { useQueryClient } from "react-query";
import swal from "sweetalert";

// 객관식 문제

const onKeyPress = (e) => {
  if (e.key === "Enter") {
    e.preventDefault();
  }
};

const ChoiceProblem = ({ problem, onClose, unitId, examId, examType }) => {
  let examLevel = problem.examLevel;
  let id = problem.id;

  const queryClient = useQueryClient();
  useEffect(() => {
    setValue("examCorrectAnswer", problem?.examCorrectAnswer);
    setValue("examQuestion", problem?.examQuestion);
    setValue("examQuestionContent", problem?.examQuestionContent);
    setValue("objective", problem?.objectiveName);
    setValue("examOption1", problem?.examOption1);
    setValue("examOption2", problem?.examOption2);
    setValue("examOption3", problem?.examOption3);
    setValue("examOption4", problem?.examOption4);
    setValue("examType", examType);
  }, [problem]);
  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm({
    defaultValues: {
      examOption1: "",
      examOption2: "",
      examOption3: "",
      examOption4: "",
      examCorrectAnswer: "",
      examQuestion: "",
      examQuestionContent: "",
      objective: "",
      examType: "",
    },
  });

  const { mutate } = useEditProblem();

  return (
    <div>
      <form
        id="editSubmit"
        onSubmit={handleSubmit(
          (data) => {
            mutate(
              {
                ...data,
                id,
                unitId,
                examId: +examId,
                examType,
                examLevel,
              },
              {
                onSuccess: (data) => {
                  if (data) {
                    queryClient.invalidateQueries(["unitList", unitId]);
                    queryClient.invalidateQueries(["examList", examId]);
                    swal("문제가 수정되었습니다.");
                    onClose();
                  } else {
                    swal("문제 생성자가 아닙니다.");
                  }
                },
                onError: (err) => {
                  swal("수정에 실패하였습니다.");
                },
              }
            );
          },
          (err) => {
            swal("수정에 실패하였습니다.");
          }
        )}
      >
        <div>
          <div>
            <QuestionTypes>
              <QuestionType>
                <h3>객관식</h3>
              </QuestionType>
              <QuestionType>
                {examLevel === 0 && <h3>난이도: 하</h3>}
                {examLevel === 1 && <h3>난이도: 중</h3>}
                {examLevel === 2 && <h3>난이도: 상</h3>}
              </QuestionType>
            </QuestionTypes>
          </div>

          <h3>문제 분류 입력</h3>
          <Objective>
            <ExamOption
              onKeyPress={onKeyPress}
              type="text"
              id="objective"
              placeholder="문제 분류를 기입하세요"
              {...register("objective", { required: "필수 입력입니다" })}
            />
          </Objective>
          <ErrorMsg>{errors.objective?.message}</ErrorMsg>
        </div>
        <Title>문제 입력</Title>
        <TextArea
          type="text"
          id="examQuestion"
          placeholder="예시) 다음이 의미하는 것은?"
          rows="10"
          {...register("examQuestion", {
            required: "필수 입력입니다",
          })}
        ></TextArea>
        <ErrorMsg style={{ marginBottom: "25px" }}>
          {errors.examQuestion?.message}
        </ErrorMsg>

        <>
          <h3>문제 부가 내용</h3>
          <TextArea
            type="text"
            id="examQuestionContent"
            placeholder="예시) 처음으로 개인정보보호 관리체계 인증을 신청하거나 인증범위에 중요한 변경이 있어서 다시 인증을 신청한 때 실시하는 인증심사"
            rows="10"
            {...register("examQuestionContent")}
          />
        </>

        <div>
          <h3>보기 입력</h3>
        </div>
        <Choice>
          <Choice__Div>
            <Input
              onKeyPress={onKeyPress}
              type="text"
              name="examOption1"
              id="examOption1"
              placeholder="보기1을 입력해주세요. 예시) 사후심사"
              {...register("examOption1", { required: "필수 입력입니다" })}
            />
            <Radio
              onKeyPress={onKeyPress}
              name="examOption"
              type="radio"
              {...register("examCorrectAnswer", {
                required: "정답을 선택해주세요",
              })}
              value="1"
            ></Radio>
          </Choice__Div>
          <ErrorMsg>{errors.examOption1?.message}</ErrorMsg>
          <Choice__Div>
            <Input
              onKeyPress={onKeyPress}
              type="text"
              name="examOption2"
              id="examOption2"
              placeholder="보기2의 내용을 입력하세요. 예시) 확장심사"
              {...register("examOption2", { required: "필수 입력입니다" })}
            />
            <Radio
              onKeyPress={onKeyPress}
              name="examOption"
              type="radio"
              {...register("examCorrectAnswer", {
                required: "정답을 선택해주세요",
              })}
              value="2"
            ></Radio>
          </Choice__Div>
          <ErrorMsg>{errors.examOption2?.message}</ErrorMsg>
          <Choice__Div>
            <Input
              onKeyPress={onKeyPress}
              type="text"
              name="examOption3"
              id="examOption3"
              placeholder="보기3의 내용을 입력하세요. 예시) 갱신심사"
              {...register("examOption3", { required: "필수 입력입니다" })}
            />
            <Radio
              onKeyPress={onKeyPress}
              name="examOption"
              type="radio"
              {...register("examCorrectAnswer", {
                required: "정답을 선택해주세요",
              })}
              value="3"
            ></Radio>
          </Choice__Div>
          <ErrorMsg>{errors.examOption3?.message}</ErrorMsg>
          <Choice__Div>
            <Input
              onKeyPress={onKeyPress}
              type="text"
              name="examOption4"
              id="examOption4"
              placeholder="보기4의 내용을 입력하세요. 예시) 최초심사"
              {...register("examOption4", {
                required: "필수 입력입니다",
              })}
            />
            <Radio
              onKeyPress={onKeyPress}
              name="examOption"
              type="radio"
              {...register("examCorrectAnswer", {
                required: "정답을 선택해주세요",
              })}
              value="4"
            ></Radio>
          </Choice__Div>
          <ErrorMsg>{errors.examOption4?.message}</ErrorMsg>
        </Choice>
        <ErrorMsg>{errors.examCorrectAnswer?.message}</ErrorMsg>
        <ModalButtons onClose={onClose} />
      </form>
    </div>
  );
};

const Choice__Div = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
`;

const Title = styled.h3`
  padding-top: 30px;
`;
const Objective = styled.div`
  display: flex;
  align-items: center;
`;
const Choice = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`;

const Radio = styled.input`
  display: block;
  margin-right: 10px;
`;
const ErrorMsg = styled.span`
  display: block;
  color: red;
`;

const TextArea = styled.textarea`
  box-sizing: border-box;
  padding: 12px;
  width: 100%;
  min-height: 20vh;
  resize: none;
  overflow: auto;
  font-size: 16px;
  font-weight: 300;
  border-radius: 10px;
  margin: 10px auto;
  border: 2px solid silver;
  background: #eeeeee66;
`;
const Input = styled.input`
  margin-right: 20px;
  box-sizing: border-box;
  display: inline-block;
  width: 97%;
  height: 32px;
  padding-left: 12px;
  resize: none;
  overflow: auto;
  font-size: 16px;
  font-weight: 300;
  border-radius: 10px;
  margin-left: 2px;
  margin-top: 10px;
  margin-bottom: 10px;
  border: 2px solid silver;
  background: #eeeeee66;
`;

const Label = styled.label`
  display: flex;
  align-items: center;
  h2 {
    margin-bottom: 0;
    margin-left: 10px;
  }
`;

const QuestionType = styled.div`
  justify-content: center;
  background-color: rgb(231, 235, 238);
  width: 160px;
  height: 40px;
  padding-left: 20px;
  padding-right: 20px;
  border: solid 1px silver;
  display: flex;
  align-items: center;
  float: right;
  span {
    display: inline-block;
  }
  h3 {
    font-weight: 300;
  }
`;
const ExamOption = styled.input`
  width: 100%;
  box-sizing: border-box;
  display: inline-block;
  height: 32px;
  padding-left: 12px;
  resize: none;
  overflow: auto;
  font-size: 16px;
  font-weight: 300;
  border-radius: 10px;
  margin-left: 2px;
  margin-top: 10px;
  margin-bottom: 10px;
  border: 2px solid silver;
  background: #eeeeee66;
`;
const QuestionTypes = styled.div`
  padding-bottom: 48px;
`;
export default ChoiceProblem;
