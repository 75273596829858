import React, { useEffect, useState, useContext, Fragment } from "react";
import { makeStyles } from "@material-ui/core/styles";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import TextField from "@material-ui/core/TextField";
import Grid from "@material-ui/core/Grid";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import { toast } from "react-toastify";
import axios from "axios";
import "./ContactForm.css";
import { FormHelperText } from "@material-ui/core";

// var process = require("../../../myProcess.json");

const useStyles = makeStyles((theme) => ({
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  button: {
    color: "white",
    fontSize: "1.4rem",
    margin: theme.spacing(3, 0, 2),
    backgroundColor: "#343a40",
    cursor: "pointer",
    border: "thin solid #343a40 !important",
  },
  TextField: {
    label: {
      fontSize: "10px",
    },
  },
}));

export default function ContactWrite({ history }) {
  const classes = useStyles();

  const handleChangeForm = (e) => {
    setValues({ ...values, [e.target.name]: e.target.value });
  };

  const [values, setValues] = React.useState({
    postCategory: "",
    postTitle: "",
    postContent: "",
    postAuthor: "",
    userId: "",
  });
  const [error, setError] = React.useState({
    postTitleError: "",
    postCategoryError: "",
    postContentError: "",
  });

  const onTextValidation = () => {
    let postTitleError = "";
    let postCategoryError = "";
    let postContentError = "";

    if (values.postTitle.length === 0) postTitleError = "제목을 입력해주세요.";
    if (values.postCategory === "")
      postCategoryError = "카테고리를 선택해 주세요.";
    if (values.postContent.length === 0)
      postContentError = "문의내용을 입력해 주세요.";

    setError({
      postTitleError,
      postCategoryError,
      postContentError,
    });

    if (postTitleError || postCategoryError || postContentError) return false;
    return true;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const valid = onTextValidation();

    if (!valid) console.error("invalid");
    else {
      const url = `${process.env.REACT_APP_IP}/common/question`;
      const token = localStorage.getItem("token");
      const uid = localStorage.getItem("uid");
      const userName = localStorage.getItem("userName");
      axios
        .post(
          url,
          {
            postCategoryId: values.postCategory,
            postTitle: values.postTitle,
            postContent: values.postContent,
            postAuthor: userName,
            userId: uid,
          },
          {
            headers: {
              AccessToken: `Bearer ${token}`,
              "Access-Control-Allow-Origin": "*",
            },
          }
        )
        .then((response) => {
          toast.success("문의글이 정상 등록되었습니다.");
          history.push("/mypagemyqanda");
        })
        .catch((error) => {
          console.log(error);
          toast.error("죄송합니다. 문의글이 정상 등록되지 못했습니다.");
          setValues({
            postCategory: "",
            postTitle: "",
            postContent: "",
            postAuthor: "",
          });
        });
    }
  };

  return (
    <form className={classes.form} onSubmit={handleSubmit}>
      <div className="d-flex">
        <Grid item xs={2}>
          <FormControl fullWidth>
            <Select
              variant="outlined"
              displayEmpty
              required
              name="postCategory"
              value={values.postCategory}
              onChange={handleChangeForm}
            >
              <MenuItem value="" disable>
                <em style={{ color: "grey" }}>카테고리 선택</em>
              </MenuItem>
              <MenuItem value={39}>교육문의</MenuItem>
              <MenuItem value={40}>운영문의</MenuItem>
            </Select>
            <div
              style={{
                color: "red",
                fontSize: "12px",
                margin: "-5px 0 10px 15px",
                fontFamily: "Noto Sans KR",
              }}
            >
              {error.postCategoryError}
            </div>
          </FormControl>
        </Grid>
        <Grid item xs={10}>
          <TextField
            variant="outlined"
            required
            autoComplete="postTitle"
            fullWidth
            label="제목을 입력하세요."
            name="postTitle"
            value={values.postTitle}
            onChange={handleChangeForm}
          />
          <div
            style={{
              color: "red",
              fontSize: "12px",
              margin: "-5px 0 10px 15px",
              fontFamily: "Noto Sans KR",
            }}
          >
            {error.postTitleError}
          </div>
        </Grid>
      </div>
      <Grid item xs={12}>
        <TextField
          variant="outlined"
          multiline
          rows={10}
          required
          autoComplete="postContent"
          fullWidth
          label="문의사항을 입력해주세요."
          name="postContent"
          value={values.postContent}
          onChange={handleChangeForm}
        />
        <div
          style={{
            color: "red",
            fontSize: "12px",
            margin: "-5px 0 10px 15px",
            fontFamily: "Noto Sans KR",
          }}
        >
          {error.postContentError}
        </div>
      </Grid>

      <Grid item xs={12}>
        <div
          style={{
            paddingTop: "10px",
            borderBottom: "1px solid #e3e3e3",
            margin: "15px 0",
            fontFamily: "Noto Sans KR",
          }}
        ></div>
      </Grid>

      <button
        type="submit"
        size="large"
        variant="contained"
        color="primary"
        //endIcon={<EmojiPeopleIcon/>}
        className={classes.button}
        style={{
          margin: "auto",
          marginTop: "20px",
          padding: "10px 30px",
          borderRadius: "50px",
          fontFamily: "Noto Sans KR",
        }}
      >
        <span className="secosub2">문의 등록</span>
      </button>
    </form>
  );
}
