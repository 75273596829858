import axios from "axios";
import { useContext } from "react";
import { useQuery } from "react-query";
import AuthContext from "../../context/Auth.context";

// const process = require("../../myProcess.json");

const useExamUnitList = (examId) => {
  const token = localStorage.getItem("token");
  const auth = useContext(AuthContext);
  const { data, isLoading } = useQuery(
    ["examList", examId],
    async () => {
      return await axios
        .get(
          `${process.env.REACT_APP_IP}/teachers/exam/creation/unit/${auth?.classId}/${examId}`,
          {
            headers: { AccessToken: `Bearer ${token}` },
          }
        )
        .then((response) => {
          return response.data;
        });
    },
    { enabled: !!examId }
  );
  return { data, isLoading };
};

export default useExamUnitList;
