import React, {
  Fragment,
  useEffect,
  useRef,
  useState,
  useContext,
} from "react";
import { useHistory } from "react-router";
import styles from "../Capatest.module.scss";
import { withStyles, makeStyles } from "@material-ui/core/styles";
import { toast } from "react-toastify";
import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableBody from "@material-ui/core/TableBody";
import TableRow from "@material-ui/core/TableRow";
import axios from "axios";
import "./TestResult.css";
import AuthContext from "../../../context/Auth.context";
import Message from "../../../utilities/Message";
import Header from "../../components/header/Header";
// import * as Sentry from "@sentry/react";

// var process = require("../../../myProcess.json");

export default function TestResult({ sidebar = "left" }) {
  const ref = useRef(null);
  const userId = localStorage.getItem("uid");
  const auth = useContext(AuthContext);
  const token = localStorage.getItem("token");
  const [testResult, setTestResult] = useState([]);
  const [testSubject, setTestSubject] = useState([]);
  const [selected, setSelected] = useState();

  useEffect(() => {
    window.addEventListener("resize", resizeListener);
    return window.removeEventListener("resize", resizeListener);
  }, []);

  const resizeListener = () => {};

  const ColorTableRow = withStyles((theme) => ({
    root: {
      backgroundColor: "white",
    },
    selected: { color: "black", backgroundColor: "#ffebd4 !important" },
  }))(TableRow);

  const StyledTableCell = withStyles((theme) => ({
    head: {
      backgroundColor: "#868686",
      color: theme.palette.common.white,
      fontSize: "1.45rem",
      fontWeight: "600",
      textAlign: "center",
    },
    body: {
      fontSize: 14,
    },
  }))(TableCell);

  useState(() => {
    if (!auth.auth) return;

    const url = `${process.env.REACT_APP_IP}/users/exam/result/${userId}`;
    axios
      .get(url, {
        headers: {
          AccessToken: `Bearer ${token}`,
        },
      })
      .then((response) => {
        setTestResult(response.data);
      })
      .catch((error) => {
        if (error.response !== undefined) {
          if (error.response.status === 401) {
            toast.error(Message.SERVICE_INVALID_TOKEN);
          } else if (error.response.status === 403) {
            toast.error(Message.ERROR_FORBIDDEN);
          } else if (error.response.status === 500) {
            toast.error(Message.ERROR_500);
          }
          // auth.onLogout();
          // Sentry.captureException(error);
        }
      });
  });

  const history = useHistory();
  const goToTestResultDetail = (
    userId,
    event
  ) => {
    event.preventDefault();
      history.push({
        pathname: "/capability/test-result-detail",
        state: {
          userId: userId
        },
      })
  };

  const printFunc = () => {
    const html = document.querySelector("html");
    // 출력할 내용 가져오기
    const printContents = document.querySelector("#content").innerHTML;

    const printDiv = document.createElement("DIV");
    printDiv.className = "print-div";

    html.appendChild(printDiv);

    // 출력할 내용 인쇄 화면 속 태그에 넣어주기
    printDiv.innerHTML = printContents;
    document.body.style.display = "none";

    window.print();
    document.body.style.display = "block";
    printDiv.style.display = "none";
  };

  return (
    <Fragment>
      <Header
        data={{
          title: "역량평가 결과",
        }}
      />
      <div className={styles.mypage} ref={ref}>
        {testResult.length === 0 && (
          <div className={styles.noResult}>채점된 시험결과가 없습니다.</div>
        )}
        <section
          className={[
            styles.wrapper,
            sidebar === "left"
              ? styles.with_sidebar + " " + styles.left
              : sidebar === "right"
              ? styles.with_sidebar + " " + styles.right
              : null,
          ].join(" ")}
        >
          <article className="capaTestResult" id="content">
            {testResult.map((result, option) => (
              <>
                <h2>{result.categoryName}</h2>
                <hr />
                <div className="capaTestScore">
                  <h3>사전 역량평가 결과</h3>
                  <TableContainer component={Paper}>
                    <Table>
                      <TableHead>
                        <TableRow>
                          <StyledTableCell style={{ width: "28%" }}>
                            응시일자
                          </StyledTableCell>
                          <StyledTableCell style={{ width: "24%" }}>
                            목표 점수
                          </StyledTableCell>
                          <StyledTableCell style={{ width: "24%" }}>
                            전체 평균 점수
                          </StyledTableCell>
                          <StyledTableCell style={{ width: "24%" }}>
                            내 점수
                          </StyledTableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        <ColorTableRow>
                          <TableCell
                            style={{ fontSize: "1.4rem", textAlign: "center" }}
                          >
                            {result.firstExamStartDate}
                          </TableCell>
                          <TableCell
                            style={{ fontSize: "1.4rem", textAlign: "center" }}
                          >
                            {result.firstExamGroupTargetScore <= 0
                              ? "-"
                              : result.firstExamGroupTargetScore}
                          </TableCell>
                          <TableCell
                            style={{ fontSize: "1.4rem", textAlign: "center" }}
                          >
                            {result.firstExamGroupScoreAvg <= 0
                              ? "-"
                              : result.firstExamGroupScoreAvg}
                          </TableCell>
                          {result.firstExamGroupTargetScore <=
                          result.firstExamScoreResult ? (
                            <TableCell
                              style={{
                                fontSize: "1.4rem",
                                textAlign: "center",
                                color: "#FA7F42",
                              }}
                            >
                              <strong>{result.firstExamScoreResult}</strong>
                            </TableCell>
                          ) : (
                            <TableCell
                              style={{
                                fontSize: "1.4rem",
                                textAlign: "center",
                                color: "#2C95D2",
                              }}
                            >
                              <strong>{result.firstExamScoreResult}</strong>
                            </TableCell>
                          )}
                        </ColorTableRow>
                      </TableBody>
                    </Table>
                  </TableContainer>
                </div>
                {result.secondExamStartDate && (
                  <>
                    <div className="capaTestScore">
                      <h3>사후 역량평가 결과</h3>
                      <TableContainer component={Paper}>
                        <Table>
                          <TableHead>
                            <TableRow>
                              <StyledTableCell style={{ width: "28%" }}>
                                응시일자
                              </StyledTableCell>
                              <StyledTableCell style={{ width: "24%" }}>
                                목표 점수
                              </StyledTableCell>
                              <StyledTableCell style={{ width: "24%" }}>
                                전체 평균 점수
                              </StyledTableCell>
                              <StyledTableCell style={{ width: "24%" }}>
                                내 점수
                              </StyledTableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            <ColorTableRow>
                              <TableCell
                                style={{
                                  fontSize: "1.4rem",
                                  textAlign: "center",
                                }}
                              >
                                {result.secondExamStartDate}
                              </TableCell>
                              <TableCell
                                style={{
                                  fontSize: "1.4rem",
                                  textAlign: "center",
                                }}
                              >
                                {result.secondExamGroupTargetScore <= 0
                                  ? "-"
                                  : result.secondExamGroupTargetScore}
                              </TableCell>
                              <TableCell
                                style={{
                                  fontSize: "1.4rem",
                                  textAlign: "center",
                                }}
                              >
                                {result.secondExamGroupScoreAvg <= 0
                                  ? "-"
                                  : result.secondExamGroupScoreAvg}
                              </TableCell>
                              {result.secondExamGroupTargetScore <=
                              result.secondExamScoreResult ? (
                                <TableCell
                                  style={{
                                    fontSize: "1.4rem",
                                    textAlign: "center",
                                    color: "#FA7F42",
                                  }}
                                >
                                  <strong>
                                    {result.secondExamScoreResult}
                                  </strong>
                                </TableCell>
                              ) : (
                                <TableCell
                                  style={{
                                    fontSize: "1.4rem",
                                    textAlign: "center",
                                    color: "#2C95D2",
                                  }}
                                >
                                  <strong>
                                    {result.secondExamScoreResult}
                                  </strong>
                                </TableCell>
                              )}
                            </ColorTableRow>
                          </TableBody>
                        </Table>
                      </TableContainer>
                    </div>
                  </>
                )}
                {result.retakeExamStartDate && (
                  <>
                    <div className="capaTestScore">
                      <h3>재평가 결과</h3>
                      <TableContainer component={Paper}>
                        <Table>
                          <TableHead>
                            <TableRow>
                              <StyledTableCell style={{ width: "28%" }}>
                                응시일자
                              </StyledTableCell>
                              <StyledTableCell style={{ width: "24%" }}>
                                목표 점수
                              </StyledTableCell>
                              <StyledTableCell style={{ width: "24%" }}>
                                전체 평균 점수
                              </StyledTableCell>
                              <StyledTableCell style={{ width: "24%" }} >
                                내 점수
                              </StyledTableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            <ColorTableRow>
                              <TableCell
                                style={{
                                  fontSize: "1.4rem",
                                  textAlign: "center",
                                }}
                              >
                                {result.retakeExamStartDate}
                              </TableCell>
                              <TableCell
                                style={{
                                  fontSize: "1.4rem",
                                  textAlign: "center",
                                }}
                              >
                                {result.retakeExamGroupTargetScore <= 0
                                  ? "-"
                                  : result.retakeExamGroupTargetScore}
                              </TableCell>
                              <TableCell
                                style={{
                                  fontSize: "1.4rem",
                                  textAlign: "center",
                                }}
                              >
                                {result.retakeExamGroupScoreAvg <= 0
                                  ? "-"
                                  : result.retakeExamGroupScoreAvg}
                              </TableCell>
                              {result.retakeExamGroupTargetScore <=
                              result.retakeExamScoreResult ? (
                                <TableCell
                                  style={{
                                    fontSize: "1.4rem",
                                    textAlign: "center",
                                    color: "#FA7F42",
                                  }}
                                >
                                  <strong>
                                    {result.retakeExamScoreResult}
                                  </strong>
                                </TableCell>
                              ) : (
                                <TableCell
                                  style={{
                                    fontSize: "1.4rem",
                                    textAlign: "center",
                                    color: "#2C95D2",
                                  }}
                                >
                                  <strong>
                                    {result.retakeExamScoreResult}
                                  </strong>
                                </TableCell>
                              )}
                            </ColorTableRow>
                          </TableBody>
                        </Table>
                      </TableContainer>
                    </div>
                  </>
                )}
              </>
            ))}
          </article>
        </section>
        {testResult.length !== 0 && (
          <div class="d-flex justify-content-center">
            <button onClick={(e) => goToTestResultDetail(userId,e)} className="detailButton">
              <i class="fas fa-search"></i> 상세보기
            </button>
            
            <button onClick={() => printFunc()} className="printButton">
              <i class="fas fa-print"></i> 인쇄하기
            </button>
          </div>
        )}
      </div>
    </Fragment>
  );
}
