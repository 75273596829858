import React, { useEffect, Fragment } from "react";
import TextField from "@material-ui/core/TextField";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";

import "./ReviewForm.css";
import axios from "axios";
import { toast } from "react-toastify";

// var process = require("../../../myProcess.json");

const useStyles = makeStyles((theme) => ({
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  button: {
    color: "white",
    fontSize: "1.4rem",
    margin: theme.spacing(3, 0, 2),
    backgroundColor: "#343a40",
    cursor: "pointer",
    border: "thin solid #343a40 !important",
  },
  TextField: {
    label: {
      fontSize: "10px",
    },
  },
}));

export default function SignupForm({ history }) {
  const classes = useStyles();

  useEffect(() => {
    const userType = localStorage.getItem("userType");
    const status = localStorage.getItem("status");
    //alert("userType:"+userType);

    if (userType === null) {
      toast.error("로그인이 필요합니다.");
      history.push("/login");
    } else if (status === 0) {
      toast.error("신청 대기중입니다");
      history.push("/myclass");
    }
  }, []);

  const [values, setValues] = React.useState({
    postCategoryId: "",
    postTitle: "",
    postContent: "",
    postAuthor: "",
    userId: "",
  });

  const [error, setError] = React.useState({
    postTitleError: "",
    postCategoryIdError: "",
    postContentError: "",
  });

  const onTextValidation = () => {
    let postTitleError = "";
    let postContentError = "";

    if (values.postTitle.length === 0) postTitleError = "제목을 입력해주세요.";
    if (values.postContent.length === 0)
      postContentError = "문의내용을 입력해 주세요.";

    setError({
      postTitleError,
      postContentError,
    });

    if (postTitleError || postContentError) return false;
    return true;
  };

  const handleChangeForm = (e) => {
    setValues({ ...values, [e.target.name]: e.target.value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const valid = onTextValidation();

    if (!valid) console.error("invalid");
    else {
      const url = `${process.env.REACT_APP_IP}/users/review`;

      const token = localStorage.getItem("token");
      const uid = localStorage.getItem("uid");
      const userName = localStorage.getItem("userName");

      axios
        .post(
          url,
          {
            postTitle: values.postTitle,
            postContent: values.postContent,
            postAuthor: userName,
            userId: uid,
          },
          {
            headers: {
              AccessToken: `Bearer ${token}`,
            },
          }
        )
        .then((response) => {
          toast.success("후기가 정상 등록되었습니다.");
          history.push("/customer/review");
        })
        .catch((error) => {
          //
          toast.error("죄송합니다. 정상 등록되지 못했습니다.");
          setValues({
            postCategoryId: "",
            postTitle: "",
            postContent: "",
            postAuthor: "",
          });
        });
    }
  };

  return (
    <Fragment>
      <form className={classes.form} onSubmit={handleSubmit}>
        <Grid item xs={12}>
          <TextField
            variant="outlined"
            margin="normal"
            required
            autoComplete="postTitle"
            fullWidth
            label="제목을 입력하세요."
            name="postTitle"
            value={values.postTitle}
            onChange={handleChangeForm}
          />
          <div
            style={{
              color: "red",
              fontSize: "12px",
              margin: "-5px 0 10px 15px",
              fontFamily: "Noto Sans KR",
            }}
          >
            {error.postTitleError}
          </div>
        </Grid>

        <Grid item xs={12}>
          <TextField
            variant="outlined"
            margin="normal"
            multiline
            rows={20}
            required
            autoComplete="postContent"
            fullWidth
            label="내용을 입력해주세요."
            name="postContent"
            value={values.postContent}
            onChange={handleChangeForm}
          />
          <div
            style={{
              color: "red",
              fontSize: "12px",
              margin: "-5px 0 10px 15px",
              fontFamily: "Noto Sans KR",
            }}
          >
            {error.postContentError}
          </div>
        </Grid>

        <Grid item xs={12}>
          <div
            style={{
              paddingTop: "10px",
              borderBottom: "1px solid #e3e3e3",
              margin: "15px 0",
              fontFamily: "Noto Sans KR",
            }}
          ></div>
        </Grid>

        <button
          type="submit"
          size="large"
          variant="contained"
          color="primary"
          //endIcon={<EmojiPeopleIcon/>}
          className={classes.button}
          style={{
            margin: "auto",
            marginTop: "20px",
            padding: "10px 30px",
            borderRadius: "50px",
            fontFamily: "Noto Sans KR",
          }}
        >
          후기 등록하기
        </button>
      </form>
    </Fragment>
  );
}
