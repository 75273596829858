import React, { useContext, useEffect, useState } from "react";
import styled from "styled-components";
import { useParams } from "react-router-dom";
import Header from "../../../components/header/Header";
import Select from "../../../components/select/select";
import useExamUnitList from "../../../../hooks/exam/useExamUnitList";
import useProblemList from "../../../../hooks/exam/useProblemList";
import Badge from "../../../components/badge/Badge";
import {
  Table,
  TableHeader,
  Wrapper,
  DisabledTableRow,
  BtnWrapper,
  Btn,
} from "../../style";
import { withRouter } from "react-router";
import useMutateExam from "../../../../hooks/exam/useMutateExam";
import PrevProblemList from "./PrevProblemList";
import useSelectedProblemCancel from "../../../../hooks/exam/useSelectedProblemCancel";
import EditProblem from "./EditProblem";
import ProblemDetail from "./ProblemDetail";
import swal from "sweetalert";
import { useQueryClient } from "react-query";

const ProblemList = ({ history }) => {
  const queryClient = useQueryClient();
  const [selectedUnitId, setSelectedUnitId] = useState(null);
  const [modalInfo, setModalInfo] = useState({
    type: null,
    id: null,
    level: null,
    isOpen: false,
    examType: null,
  });

  const handleModalOpen = (type) => {
    setModalInfo(type);
  };

  const { examId } = useParams();
  const { data, isLoading } = useExamUnitList(examId);
  const { data: problemData } = useProblemList(examId, selectedUnitId);
  const handleUnitChange = (selectedUnitId) => {
    setSelectedUnitId(selectedUnitId);
  };
  const { mutate: addExam, isLoading: addExamLoading } = useMutateExam();
  const handleExamSubmit = () => {
    if (addExamLoading) return;

    swal({
      title: "문제 제출을 확정합니다.",
      text: "확정하시겠습니까?",
      icon: "warning",
      buttons: true,
      dangerMode: true,
      className: "mySwal",
    }).then((confirm) => {
      if (confirm) {
        addExam(examId, {
          onSuccess: (data) => {
            if (data === "success") {
              swal({
                text: "시험 문제가 확정되었습니다.",
                icon: "success",
              });
            } else {
              swal({
                text: "요청을 성공하지 못했습니다.",
                icon: "error",
              });
            }
            history.push("/teacher/test");
          },
          onError: (err) => {
            swal({
              text: "요청을 성공하지 못했습니다.",
              icon: "error",
            });
            history.push("/teacher/test");
          },
        });
      } else {
        swal({
          text: "시험 문제 제출을 취소했습니다.",
          icon: "error",
        });
      }
    });
  };

  const { mutate: cancelProblem } = useSelectedProblemCancel();
  const handleCancelSelect = (problemId) => {
    swal({
      title: "사전 문제 선택을 취소합니다.",
      text: "취소하시겠습니까?",
      icon: "warning",
      buttons: true,
      dangerMode: true,
      className: "mySwal",
    }).then((cancel) => {
      if (cancel) {
        cancelProblem(
          { examId, problemId },
          {
            onSuccess: (data) => {
              if (data) {
                swal({
                  text: "사전 문제 선택이 취소되었습니다.",
                  icon: "success",
                });
              } else {
                swal({
                  text: "취소 요청을 성공하지 못했습니다.",
                  icon: "error",
                });
              }
            },
            onError: (err) => {
              swal({
                text: "취소 요청을 성공하지 못했습니다.",
                icon: "error",
              });
            },
            onSettled: () => {
              queryClient.invalidateQueries(["unitList", selectedUnitId]);
              queryClient.invalidateQueries(["examList", examId]);
            },
          },
        );
      } else {
        swal({
          text: "요청을 취소했습니다.",
          icon: "error",
        });
      }
    });
  };

  return (
    <>
      <Header
        data={{
          title:
            (data?.categoryName && data?.categoryName) +
            " - " +
            (data?.count === 1
              ? "사전 평가"
              : data?.count === 2
                ? "사후 평가"
                : data?.count === 3
                  ? "재시험"
                  : null),
        }}
      />

      <Container>
        <List>
          <ListSelect>
            {data && (
              <Select
                defaultValue={data.units[0].unitId}
                defaultText={
                  data.units[0].isDone
                    ? `✅ ${data.units[0].unitName}`
                    : data.units[0].unitName
                }
                onChange={handleUnitChange}
              >
                <Select.Trigger>단원을 선택해주세요</Select.Trigger>
                <Select.OptionList>
                  {data?.units?.map((item, i) => {
                    return (
                      <Select.Option value={item.unitId} key={i}>
                        {item.isDone ? `✅ ${item.unitName}` : item.unitName}
                      </Select.Option>
                    );
                  })}
                </Select.OptionList>
              </Select>
            )}
          </ListSelect>
          <Wrapper>
            <Table>
              <TableHeader>
                <div className="sm-div">TYPE</div>
                <div className="sm-div">LEVEL</div>
                <div>문제</div>
                <div className="sm-div">사전문제</div>
                <div className="sm-div">MORE</div>
              </TableHeader>

              {selectedUnitId &&
                problemData?.problems.map((problem, i) => (
                  <DisabledTableRow key={i}>
                    <div className="sm-div">
                      {problem.examType === 0 ? (
                        <Badge size="medium" color="#2E90CF">
                          객관식
                        </Badge>
                      ) : problem.examType === 2 ? (
                        <Badge size="medium" color="#3e4954">
                          OX
                        </Badge>
                      ) : problem.examType === 3 ? (
                        <Badge size="medium" color="#f35757">
                          주관식
                        </Badge>
                      ) : null}
                    </div>
                    <div className="sm-div">
                      {problem?.examLevel === 0
                        ? "하"
                        : problem?.examLevel === 1
                          ? "중"
                          : "상"}
                    </div>
                    <ExamQ>{problem?.examQuestion}</ExamQ>
                    <div className="sm-div">
                      {problem?.isPrev === true ? (
                        <Cancel onClick={() => handleCancelSelect(problem.id)}>
                          선택취소
                        </Cancel>
                      ) : (
                        "-"
                      )}
                    </div>
                    <div className="sm-div">
                      <Icons>
                        {!problem?.isPrev && (
                          <I
                            className="far fa-pen more-btn-icon-box"
                            onClick={() =>
                              handleModalOpen((prev) => ({
                                ...prev,
                                type: "edit",
                                isOpen: true,
                                id: problem?.id,
                                examType: problem?.examType,
                                level: problem?.examLevel,
                              }))
                            }
                          />
                        )}
                        <I
                          className="far fa-file-alt more-btn-icon-box-dark"
                          onClick={() =>
                            handleModalOpen((prev) => ({
                              ...prev,
                              type: "detail",
                              isOpen: true,
                              id: problem?.id,
                              examType: problem?.examType,
                              level: problem?.examLevel,
                            }))
                          }
                        ></I>
                      </Icons>
                    </div>
                  </DisabledTableRow>
                ))}

              {selectedUnitId &&
                problemData?.high > 0 &&
                new Array(problemData?.high).fill(null).map((_, i) => (
                  <DisabledTableRow key={i}>
                    <div className="sm-div">-</div>
                    <div className="sm-div">상</div>
                    <ExamQ>-</ExamQ>
                    <div className="sm-div">
                      {data?.count === 1 ? (
                        "-"
                      ) : (
                        <PrevProblemList
                          examLevel={2}
                          examId={examId}
                          unitId={selectedUnitId}
                        />
                      )}
                    </div>
                    <div className="sm-div">
                      <Icons>
                        <I
                          className="far fa-pen more-btn-icon-box"
                          onClick={() =>
                            handleModalOpen((prev) => ({
                              ...prev,
                              type: "add",
                              isOpen: true,
                              level: 2,
                            }))
                          }
                        />
                      </Icons>
                    </div>
                  </DisabledTableRow>
                ))}
              {selectedUnitId &&
                problemData?.middle > 0 &&
                new Array(problemData?.middle).fill(null).map((_, i) => (
                  <DisabledTableRow key={i}>
                    <div className="sm-div">-</div>
                    <div className="sm-div">중</div>
                    <ExamQ>-</ExamQ>
                    <div className="sm-div">
                      {data?.count === 1 ? (
                        "-"
                      ) : (
                        <PrevProblemList
                          examLevel={1}
                          examId={examId}
                          unitId={selectedUnitId}
                        />
                      )}
                    </div>
                    <div className="sm-div">
                      <Icons>
                        <I
                          className="far fa-pen more-btn-icon-box"
                          onClick={() =>
                            handleModalOpen((prev) => ({
                              ...prev,
                              type: "add",
                              isOpen: true,
                              level: 1,
                            }))
                          }
                        />
                      </Icons>
                    </div>
                  </DisabledTableRow>
                ))}
              {selectedUnitId &&
                problemData?.low > 0 &&
                new Array(problemData?.low).fill(null).map((_, i) => (
                  <DisabledTableRow key={i}>
                    <div className="sm-div">-</div>
                    <div className="sm-div">하</div>
                    <ExamQ>-</ExamQ>
                    <div className="sm-div">
                      {data?.count === 1 ? (
                        "-"
                      ) : (
                        <PrevProblemList
                          examLevel="0"
                          examId={examId}
                          unitId={selectedUnitId}
                        />
                      )}
                    </div>
                    <div className="sm-div">
                      <Icons>
                        <I
                          className="far fa-pen more-btn-icon-box"
                          onClick={() =>
                            handleModalOpen((prev) => ({
                              ...prev,
                              type: "add",
                              isOpen: true,
                              level: 0,
                            }))
                          }
                        />
                      </Icons>
                    </div>
                  </DisabledTableRow>
                ))}
              {!selectedUnitId && (
                <DisabledTableRow>
                  <div>단원을 선택하세요</div>
                </DisabledTableRow>
              )}
            </Table>
            <BtnWrapper>
              <GoBackBtn
                color="#aaa"
                onClick={() => history.push("/teacher/test")}
              >
                뒤로가기
              </GoBackBtn>
              {data?.units.every((unit) => unit.isDone === true) ? (
                <ConfirmBtn color="#2E90CF" onClick={handleExamSubmit}>
                  출제 확정
                </ConfirmBtn>
              ) : (
                <ConfirmBtn color="#aaa">출제 중</ConfirmBtn>
              )}
            </BtnWrapper>
          </Wrapper>
        </List>
        {modalInfo.type === "edit" || modalInfo.type === "add"
          ? modalInfo.isOpen && (
          <EditProblem
            handleClose={() => {
              setModalInfo((prev) => ({
                type: null,
                id: null,
                level: null,
                isOpen: false,
                examType: null,
              }));
            }}
            examType={modalInfo.examType}
            type={modalInfo.type}
            problemId={modalInfo?.id}
            level={modalInfo.level}
            unitId={selectedUnitId}
            examId={examId}
          />
        )
          : modalInfo.isOpen &&
          modalInfo.type === "detail" && (
            <ProblemDetail
              handleClose={() =>
                setModalInfo((prev) => ({
                  type: null,
                  id: null,
                  level: null,
                  isOpen: false,
                  examType: null,
                }))
              }
              problemId={modalInfo?.id}
            />
          )}
      </Container>
    </>
  );
};

const Container = styled.div`
  width: 100%;
  box-sizing: border-box;
`;

const List = styled.div`
  width: 80%;
  max-width: 1140px;
  padding-top: 40px;
  margin: 0 auto;
  white-space: nowrap;
`;

const ListSelect = styled.div`
  width: 100%;
  box-sizing: border-box;
  padding-bottom: 20px;
  position: relative;
`;

const GoBackBtn = styled(Btn)`
  width: 100px;
`;

const ConfirmBtn = styled(Btn)`
  width: 100px;
`;

const ExamQ = styled.div`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

const Icons = styled.div`
  width: 100%;
  display: flex;
  align-items: baseline;
  justify-content: center;
`;
const I = styled.i`
  width: 30px;
  height: 30px;
  display: flex;
  justify-content: space-around;
  font-size: 1.5rem;
  margin-right: 4px;
  cursor: pointer;
`;

const Cancel = styled.span`
  background-color: #f14a16;
  border: 1px solid #f14a16;
  padding: 2px 4px;
  margin: 0 5px;
  color: white;
  border-radius: 5px;
  font-size: 14px;
  cursor: pointer;

  &:hover {
    background-color: white;
    color: #f14a16;
  }
`;

export default withRouter(ProblemList);
