import React from "react";
import { useState } from "react";
import styled from "styled-components";

import Modal from "../../../components/modalComponents/Modal";
import ProblemDetailContent from "../../../components/modalComponents/ProblemDetailContent";
import ShortAnswer from "./InputComponents/ShortAnswer";
import OXAnswer from "./InputComponents/OXAnswer";
import ChoiceAnswer from "./InputComponents/ChoiceAnswer";
import useProblem from "../../../../hooks/teacher/exam/useProblem";

const ProblemDetail = ({ problemId, handleClose }) => {
  const { data, isLoading } = useProblem(problemId);
  return (
    <>
      <div>
        <Modal
          cancelBtn="닫기"
          open={true}
          size="large"
          // large, medium or else
          onClose={handleClose}
        >
          {data?.examType === 0 && (
            <ChoiceAnswer level={data?.examLevel} data={data} />
          )}
          {data?.examType === 2 && (
            <OXAnswer level={data?.examLevel} data={data} />
          )}
          {data?.examType === 3 && (
            <ShortAnswer level={data?.examLevel} data={data} />
          )}
          <ProblemDetailContent></ProblemDetailContent>
        </Modal>
      </div>
    </>
  );
};
const Btn = styled.button`
  background-color: #2E90CF;
  border: 1px solid #2E90CF;
  padding: 7px;
  margin: 0 5px;
  color: white;
  border-radius: 5px;
  font-size: 14px;
  cursor: pointer;
  &:hover {
    background-color: white;
    color: #2E90CF;
  }
`;
export default ProblemDetail;
