import React, { Fragment, useContext } from "react";
import { Link } from "react-router-dom";
import styles from "./Sitemap.module.scss";
import { Footer, Header, Layout } from "../../layouts";
import Header1 from "../../../modules/components/header/Header";
import AuthContext from "../../../context/Auth.context";

// var process = require("../../../myProcess.json");
const cms_url = process.env.REACT_APP_CMS_URL;

export default ({ data }) => {
  const auth = useContext(AuthContext);

  return (
    <Fragment>
      <Header data={data} />
      <Header1 data={{ title: "사이트맵" }} />
      <Layout
        col="3"
        padding
        mode="light"
        style={{ background: "rgb(255,255,255)" }}
      >
        <div className={styles.wrapper}>
          <div className={styles.step}>
            <p
              className={`${styles.number} ${styles.wrapper} secosub1 secoblue`}
            >
              내클래스
            </p>
            <div className={styles.info}>
              <Link to="/myclass/roadmap">
                <p className="secosub2">내클래스룸</p>
              </Link>
              <Link to="/myclass/board/notice">
                <p className="secosub2">학습 게시판</p>
              </Link>
              <Link to="/myclass/practice/select">
                <p className="secosub2">스마트실습</p>
              </Link>
            </div>
          </div>
        </div>
        <div className={styles.wrapper}>
          <div className={styles.step}>
            <p
              className={`${styles.number} ${styles.wrapper} secosub1 secoblue`}
            >
              지식컨텐츠
            </p>
            <div className={styles.info}>
              <Link to="/pre-course/list">
                <p className="secosub2">지식컨텐츠</p>
              </Link>
            </div>
          </div>
        </div>
        <div className={styles.wrapper}>
          <div className={styles.step}>
            <p
              className={`${styles.number} ${styles.wrapper} secosub1 secoblue`}
            >
              학습지원
            </p>
            <div className={styles.info}>
              <Link to="/customer/library">
                <p className="secosub2">자료실</p>
              </Link>
              <Link to="/customer/faq">
                <p className="secosub2">자주묻는 질문</p>
              </Link>
              <Link to="/customer/review">
                <p className="secosub2">사용자 후기</p>
              </Link>
              <Link to="/customer/contact">
                <p className="secosub2">문의하기</p>
              </Link>
            </div>
          </div>
        </div>

        <div className={["layout-footer", styles.footer].join(" ")}></div>
        <div className={styles.wrapper}>
          <div className={styles.step}>
            <p
              className={`${styles.number} ${styles.wrapper} secosub1 secoblue`}
            >
              스터디그룹
            </p>
            <div className={styles.info}>
              {auth.type === "1" ? (
                <Link to="/sgroup/list">
                  <p className="secosub2">스터디그룹</p>
                </Link>
              ) : (
                <p className="secosub2">스터디그룹</p>
              )}
            </div>
          </div>
        </div>
        <div className={styles.wrapper}>
          <div className={styles.step}>
            <p
              className={`${styles.number} ${styles.wrapper} secosub1 secoblue`}
            >
              역량평가
            </p>
            <div className={styles.info}>
              {auth.type === "1" ? (
                <Link to="/capability/test">
                  <p className="secosub2">역량평가</p>
                </Link>
              ) : (
                <p className="secosub2">역량평가</p>
              )}
              {auth.type === "1" ? (
                <Link to="/capability/reserve">
                  <p className="secosub2">상담예약</p>
                </Link>
              ) : (
                <p className="secosub2">상담예약</p>
              )}
            </div>
          </div>
        </div>
        <div className={styles.wrapper}>
          <div className={styles.step}>
            <p
              className={`${styles.number} ${styles.wrapper} secosub1 secoblue`}
            >
              교강사
            </p>
            <div className={styles.info}>
              {auth.type !== "1" ? (
                <Link to="/teacher/task">
                  <p className="secosub2">과제관리</p>
                </Link>
              ) : (
                <p className="secosub2">과제관리</p>
              )}
              {auth.type !== "1" ? (
                <Link to="/teacher/test">
                  <p className="secosub2">시험관리</p>
                </Link>
              ) : (
                <p className="secosub2">시험관리</p>
              )}
              {/* {auth.type !== "1" ? (
                <Link to="/teacher/consulting">
                  <p className="secosub2">면담관리</p>
                </Link>
              ) : (
                <p className="secosub2">면담관리</p>
              )} */}
              {auth.type !== "1" ? (
                <Link to="/teacher/unit-detail">
                  <p className="secosub2">수업관리</p>
                </Link>
              ) : (
                <p className="secosub2">수업관리</p>
              )}
              {auth.type !== "1" ? (
                <Link to="/teacher/rewatch">
                  <p className="secosub2">다시보기 관리</p>
                </Link>
              ) : (
                <p className="secosub2">다시보기 관리</p>
              )}
              {/*{auth.type === "3" ? (*/}
              {/*  <a href={cms_url}>*/}
              {/*    <p className="secosub2">Go to CMS</p>*/}
              {/*  </a>*/}
              {/*) : null}*/}
            </div>
          </div>
        </div>
        <div className={["layout-footer", styles.footer].join(" ")}></div>
      </Layout>
      <Footer />
    </Fragment>
  );
};
