import React from "react";
import { Tr } from "../style";

const TableList = ({ rowNum, value, sortedKeys, onClick }) => {
  return (
    <Tr onClick={() => onClick(value.userId)}>
      <td role="cell">{rowNum}</td>
      {sortedKeys.map((item, i) => {
        let result = value[item];
        if (i < 6) {
          return <td key={i}>{result}</td>;
        } else if (i === 6) {
          return (
            <td style={{ fontWeight: "600" }} key={i}>
              {value[item] === 1 ? (
                <>
                  <i
                    className="fa-solid fa-check"
                    style={{ color: "green" }}
                  ></i>
                  {" /  ─  /  ─  "}
                </>
              ) : value[item] === 2 ? (
                <>
                  <i
                    className="fa-solid fa-check"
                    style={{ color: "green" }}
                  ></i>
                  {" / "}
                  <i
                    className="fa-solid fa-check"
                    style={{ color: "green" }}
                  ></i>
                  {" /  ─"}
                </>
              ) : value[item] >= 3 ? (
                <>
                  <i
                    className="fa-solid fa-check "
                    style={{ color: "green" }}
                  ></i>
                  {" / "}
                  <i
                    className="fa-solid fa-check "
                    style={{ color: "green" }}
                  ></i>
                  {" / "}
                  <i
                    className="fa-solid fa-check "
                    style={{ color: "green" }}
                  ></i>
                </>
              ) : (
                "─  /  ─  /  ─"
              )}
            </td>
          );
        }
      })}
    </Tr>
  );
};

export default TableList;
