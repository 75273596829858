import axios from "axios";
import { useQuery } from "react-query";

// const process = require("../../myProcess.json");

const useCounselingComments = (userId) => {
  const token = localStorage.getItem("token");
  const { data, isLoading } = useQuery(
    ["counselingComments", userId],
    async () => {
      return await axios
        .get(
          `${process.env.REACT_APP_IP}/teachers/exam/counseling/comments/${userId}`,
          {
            headers: { AccessToken: `Bearer ${token}` },
          }
        )
        .then((res) => {
          return res.data;
        });
    },
    { enabled: !!userId }
  );
  return { data, isLoading };
};

export default useCounselingComments;
