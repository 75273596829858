import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useQueryClient } from "react-query";
import styled from "styled-components";
import swal from "sweetalert";
import useEditProblem from "../../../../../hooks/teacher/exam/useEditProblem";
import ModalButtons from "../../../../components/modalComponents/ModalButtons";

// O X 문제
const onKeyPress = (e) => {
  if (e.key === "Enter") {
    e.preventDefault();
  }
};
const OXProblem = ({ problem, onClose, examId, unitId, examType }) => {
  let examLevel = problem.examLevel;
  let id = problem.id;
  useEffect(() => {
    setValue("examCorrectAnswer", problem?.examCorrectAnswer);
    setValue("examQuestion", problem?.examQuestion);
    setValue("examQuestionContent", problem?.examQuestionContent);
    setValue("objective", problem?.objectiveName);
    setValue("examType", examType);
  }, [problem]);
  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm({
    defaultValues: {
      examQuestion: "",
      examQuestionContent: "",
      objective: "",
      examCorrectAnswer: "",
      examType: "",
    },
  });
  const { mutate } = useEditProblem();
  const queryClient = useQueryClient();
  return (
    <div>
      <form
        id="editSubmit"
        onSubmit={handleSubmit(
          (data) => {
            mutate(
              {
                ...data,
                id,
                unitId,
                examId: +examId,
                examType,
                examLevel,
              },
              {
                onSuccess: (data) => {
                  if (data) {
                    queryClient.invalidateQueries(["unitList", unitId]);
                    queryClient.invalidateQueries(["examList", examId]);
                    swal("문제가 수정되었습니다.");
                    onClose();
                  } else {
                    swal("문제 생성자가 아닙니다.");
                  }
                },
              },
              {
                onError: (error) => {
                  swal("수정에 실패하였습니다.");
                },
              }
            );
          },
          (err) => {
            swal("수정에 실패하였습니다.");
          }
        )}
      >
        <div>
          <QuestionTypes>
            <QuestionType>
              <h3>OX퀴즈</h3>
            </QuestionType>
            <QuestionType>
              {examLevel === 0 && <h3>난이도: 하</h3>}
              {examLevel === 1 && <h3>난이도: 중</h3>}
              {examLevel === 2 && <h3>난이도: 상</h3>}
            </QuestionType>
          </QuestionTypes>
          <h3>문제 분류 입력</h3>
          <Objective>
            <ExamOption
              onKeyPress={onKeyPress}
              type="text"
              id="objective"
              placeholder="문제 분류를 기입하세요"
              {...register("objective", { required: "필수 입력입니다" })}
            />
          </Objective>
          <ErrorMsg>{errors.objective?.message}</ErrorMsg>
          <Title>문제 입력</Title>
        </div>
        <TextArea
          type="text"
          id="examQuestion"
          placeholder="예시) XSS취약점에 대한 다음 설명이 맞으면 O, 틀리면 X를 택하시오"
          rows="10"
          {...register("examQuestion", {
            required: "필수 입력입니다",
          })}
        ></TextArea>
        <ErrorMsg style={{ marginBottom: "25px" }}>
          {errors.examQuestion?.message}
        </ErrorMsg>
        <div>
          <h3>문제 부가 내용 입력</h3>
        </div>
        <TextArea
          type="text"
          id="examQuestionContent"
          placeholder="예시) 사용자 입력 값에 클라이언트 스크립트(Javascript, VBScript 등)을 이용하여 악의적인 서버로 유도하여 특정 행위를 하게 한다. 악성코드 배포 목적으로도 많이 사용한다."
          rows="10"
          {...register("examQuestionContent")}
        />
        <div>
          <h3>정답 입력</h3>
        </div>
        <Label>
          <input
            onKeyPress={onKeyPress}
            type="radio"
            {...register("examCorrectAnswer", {
              required: "정답을 선택해주세요",
            })}
            value="O"
          ></input>
          <h2>O</h2>
        </Label>
        <Label>
          <input
            onKeyPress={onKeyPress}
            type="radio"
            {...register("examCorrectAnswer", {
              required: "정답을 선택해주세요",
            })}
            value="X"
          ></input>
          <h2>X</h2>
        </Label>
        <ErrorMsg>{errors.examCorrectAnswer?.message}</ErrorMsg>
        <ModalButtons onClose={onClose} />
      </form>
    </div>
  );
};
const ErrorMsg = styled.span`
  display: block;
  color: red;
`;

const Objective = styled.div`
  display: flex;
  align-items: center;
`;

const Choices = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
`;

const TextArea = styled.textarea`
  box-sizing: border-box;
  padding: 12px;
  display: block;
  width: 100%;
  min-height: 20vh;
  resize: none;
  overflow: auto;
  font-size: 16px;
  font-weight: 300;
  border-radius: 10px;
  margin: 10px auto;
  border: 2px solid silver;
  background: #eeeeee66;
`;

const Label = styled.label`
  display: flex;
  align-items: center;
  h2 {
    margin-bottom: 0;
    margin-left: 10px;
  }
`;

const Input = styled.input`
  box-sizing: border-box;
  display: inline-block;
  width: 90%;
  height: 32px;
  padding-left: 12px;
  resize: none;
  overflow: auto;
  font-size: 16px;
  font-weight: 300;
  border-radius: 10px;
  margin-left: 2px;
  margin-top: 10px;
  margin-bottom: 10px;
  border: 2px solid silver;
  background: #eeeeee66;
  margin-right: 50px;
`;

const ExamOption = styled.input`
  width: 100%;
  box-sizing: border-box;
  display: inline-block;
  height: 32px;
  padding-left: 12px;
  resize: none;
  overflow: auto;
  font-size: 16px;
  font-weight: 300;
  border-radius: 10px;
  margin-left: 2px;
  margin-top: 10px;
  margin-bottom: 10px;
  border: 2px solid silver;
  background: #eeeeee66;
`;
const QuestionTypes = styled.div`
  padding-bottom: 48px;
`;
const QuestionType = styled.div`
  justify-content: center;
  background-color: rgb(231, 235, 238);
  width: 160px;
  height: 40px;
  padding-left: 20px;
  padding-right: 20px;
  border: solid 1px silver;
  display: flex;
  align-items: center;
  float: right;
  span {
    display: inline-block;
  }
  h3 {
    font-weight: 300;
  }
`;
const Title = styled.h3`
  padding-top: 30px;
`;
export default OXProblem;
