import React, { useState, useEffect, useRef, useContext } from "react";
import { withRouter } from "react-router";
import styles from "./Services3.module.scss";
import Layout from "../../layouts/Layout/Layout";
import THEME from "../../../state/theme";
import { Button } from "../../elements";
import { toast } from "react-toastify";
import AuthContext from "../../../context/Auth.context";
import Message from "../../../utilities/Message";

export default withRouter(({ data = [], history }) => {
  const ref = useRef(null);
  const [height, setHeight] = useState(0);
  const auth = useContext(AuthContext);

  useEffect(() => {
    setHeight(ref.current.clientHeight);
    window.addEventListener("resize", resizeListener);
    return window.removeEventListener("resize", resizeListener);
  }, [auth]);

  const handleSecurePage = (id) => {
    if (id === "submittersignup") {
      history.push(`/submittersignup`);
    } else {
      if (auth.auth) {
        if (auth.type !== "1") {
          toast.error(Message.SERVICE_STUDENT_ONLY);
        } else if (
          auth.type === "1" &&
          auth.status !== "1" &&
          id === "capabilitytest"
        ) {
          history.push(`/student/${id}`);
        } else if (auth.type === "1" && auth.status !== "1") {
          toast.error(Message.STATUE_NOT_CONFIRMED);
        } else if (auth.type === "1" && auth.status === "1") {
          history.push(`/student/${id}`);
        }
      } else {
        toast.error(Message.SERVICE_AUTHENTICATED_ONLY);
      }
    }
  };

  const resizeListener = () => {
    setHeight(ref.current.clientHeight);
  };

  let listStatistic = data
    .map((item, index) => (
      <div
        key={index}
        className={styles.wrapper}
        style={{
          borderColor: THEME.color,
        }}
        onClick={() => handleSecurePage(item.id)}
      >
        <div className={styles.card}>
          <div className={styles.icon}>
            <img src={item.icon} alt="card_icon" />
          </div>

          <h3 className="secosub1 secoblue">{item.subtitle}</h3>
          <p className="secotext secogray">
            {item.text.replace(/^(.{60}[^\s]*).*/, "$1")}
          </p>
          {/* <div className={styles.button}>
            <Button
              to=""
              type="solid-white-tb"
              hoverType="solid-white-tb"
              round
              small
              before="&#xf105;"
            />
          </div> */}
        </div>
      </div>
    ))
    .slice(0, 4);

  return (
    <div className={styles.stat_area}>
      <Layout
        style={{
          transform: "translateY(-50%)",
        }}
        col="1"
      >
        <div className={styles.statistic} ref={ref}>
          {listStatistic}
        </div>
      </Layout>
      <span
        className={styles.negative_space}
        style={{
          height: height / 2 + 20 + "px",
          marginTop: -height + "px",
        }}
      />
    </div>
  );
});
