import React, { useContext, useEffect } from "react";
import MypageMyInformationIndex from "./MypageMyInformationIndex";
import MypageMyInformationSub from "./MypageMyInformationSub";
import { toast } from "react-toastify";
import AuthContext from "../../../context/Auth.context";
import Message from "../../../utilities/Message";
import Header1 from "../../../modules/components/header/Header";
import { Footer, Header } from "../../layouts";
import { useHistory } from "react-router";

export default ({ data }) => {
  const history = useHistory();
  const auth = useContext(AuthContext);

  useEffect(() => {
    if (!auth.auth) {
      toast.error(Message.SERVICE_AUTHENTICATED_ONLY);
      history.push("/login");
    }
  }, [auth]);

  return (
    <>
      <Header data={data} />
      <Header1 data={{ title: "내 정보 관리" }} />
      {auth.auth && <MypageMyInformationIndex />}
      <Footer />
    </>
  );
};
