import React, { useEffect, useState, useContext, Fragment } from "react";
import { Link } from "react-router-dom";
import { withStyles } from "@material-ui/core/styles";
import axios from "axios";
import { toast } from "react-toastify";
import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableBody from "@material-ui/core/TableBody";
import TableRow from "@material-ui/core/TableRow";
import TablePagination from "@material-ui/core/TablePagination";
import FormControl from "@material-ui/core/FormControl";
import InputBase from "@material-ui/core/InputBase";
import Select from "@material-ui/core/Select";
import AuthContext from "../../../../context/Auth.context";
import Message from "../../../../utilities/Message";
import moment from "moment";
// import * as Sentry from "@sentry/react";

// var process = require("../../../../myProcess.json");

const ColorTableRow = withStyles((theme) => ({
  root: {
    backgroundColor: "white",
  },
  selected: { color: "black", backgroundColor: "#ffebd4 !important" },
}))(TableRow);

//전체 유저리스트 페이지
const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: "#757575",
    color: theme.palette.common.white,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const BootstrapInput = withStyles((theme) => ({
  root: {
    "label + &": {
      marginTop: theme.spacing(3),
    },
  },
  input: {
    marginLeft: "3px",
    borderRadius: 4,
    position: "relative",
    backgroundColor: theme.palette.background.paper,
    border: "1px solid #ced4da",
    fontSize: "15.5px",
    letterSpacing: "-0.175px",
    padding: "10px 26px 10px 12px",
    transition: theme.transitions.create(["border-color", "box-shadow"]),
    // Use the system font instead of the default Roboto font.
    fontFamily: [
      "Noto Sans KR",
      "-apple-system",
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(","),
    "&:focus": {
      borderRadius: 4,
      borderColor: "#80bdff",
      boxShadow: "0 0 0 0.2rem rgba(0,123,255,.25)",
    },
    "&::placeholder": {
      fontSize: 15.5,
    },
  },
}))(InputBase);

export default function ContactList({ history }) {
  const auth = useContext(AuthContext);
  const token = localStorage.getItem("token");

  useEffect(() => {
    if (!auth.auth) {
      toast.error(Message.SERVICE_AUTHENTICATED_ONLY);
      history.push("/login");
    }
  }, [auth, history]);

  const [searchedData, setSearchedData] = React.useState([]);
  const [search, setSearch] = React.useState({
    selected: "all",
    title: "전체",
    content: "",
  });

  const selectChange = (e) => {
    if (e.target.value === "title") {
      setSearch({ ...search, selected: "title", title: "제목" });
    } else if (e.target.value === "content") {
      setSearch({ ...search, selected: "content", title: "내용" });
    } else if (e.target.value === "all") {
      setSearch({ ...search, selected: "all", title: "전체" });
    }
  };

  const handleSearch = (e) => {
    if (e.key === "Enter") {
      setSearch({ ...search, content: e.target.value });
    }
  };

  const handleChangeSearch = (e) => {
    setContent(e.target.value);
  };

  const handleChangePage = (event, newPage) => {
    setPageNum(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setPageSize(parseInt(event.target.value, 10));
    setPageNum(0);
  };

  const [content, setContent] = useState("");
  const [pageSize, setPageSize] = useState(10);
  const [pageNum, setPageNum] = useState(0);

  useState(() => {
    if (!auth.auth) return;
  });

  useEffect(() => {
    const url = `${
      process.env.REACT_APP_IP
    }:10000/teachers/lc-post/questions?pageNum=${
      pageNum + 1
    }&pageSize=${pageSize}&searchType=${search.selected}&searchContent=${
      search.content
    }`;
    axios
      .get(url, {
        headers: { AccessToken: `Bearer ${token}` },
      })
      .then((response) => {
        console.log(response.data);
        setSearchedData(response.data);
      })
      .catch((error) => {
        if (error.response !== undefined) {
          if (error.response.status === 401) {
            toast.error(Message.SERVICE_INVALID_TOKEN);
          } else if (error.response.status === 403) {
            toast.error(Message.ERROR_FORBIDDEN);
          } else if (error.response.status === 500) {
            toast.error(Message.ERROR_500);
          } else if (error.response.status === 500) {
            toast.error(Message.ERROR_500);
          }
          // auth.onLogout();
          // history.push("/login");
          // Sentry.captureException(error);
        }
      });
  }, [pageNum, pageSize, search.selected, search.content]);

  return (
    <Fragment>
      <div style={{ marginBottom: "1vh", float: "right" }}>
        <FormControl>
          <Select
            native
            id="select"
            value={search.selected}
            onChange={selectChange}
            input={<BootstrapInput />}
          >
            <option className="secotext" value="all">
              전체
            </option>
            <option className="secotext" value="title">
              제목
            </option>
            <option className="secotext" value="content">
              내용
            </option>
          </Select>
        </FormControl>
        <FormControl>
          <BootstrapInput
            id="demo-customized-textbox"
            placeholder={`${search.title} 검색하기`}
            name="content"
            value={content}
            onKeyPress={handleSearch}
            onChange={handleChangeSearch}
            style={{ width: "300px" }}
          />
        </FormControl>
      </div>
      <div className="mypageContents">
        <TableContainer component={Paper}>
          <Table aria-label="customized table">
            <TableHead rowcount={searchedData.size}>
              <TableRow>
                <StyledTableCell width="5vw">
                  <span className="secotext">No.</span>
                </StyledTableCell>
                <StyledTableCell align="center">
                  <span className="secotext">제목</span>
                </StyledTableCell>
                <StyledTableCell align="center">
                  <span className="secotext">작성자</span>
                </StyledTableCell>
                <StyledTableCell align="center">
                  <span className="secotext">등록일</span>
                </StyledTableCell>
                <StyledTableCell align="center">
                  <span className="secotext">답변 여부</span>
                </StyledTableCell>
              </TableRow>
            </TableHead>
            {searchedData.length === 0 ? (
              <TableBody style={{ width: "100%", textAlign: "center" }}>
                <TableCell colSpan={5}>
                  <div style={{ textAlign: "center", fontSize: "14px" }}>
                    게시글이 없습니다.
                  </div>
                </TableCell>
              </TableBody>
            ) : (
              <TableBody>
                {searchedData.list.map((row, index) => {
                  return (
                    <ColorTableRow key={index} hover tabIndex={-1}>
                      <TableCell align="center">
                        <span className="secotext">
                          {pageNum * pageSize + index + 1}
                        </span>
                      </TableCell>
                      <TableCell>
                        <span className="secotext">
                          <Link to={"/customer/contactdetail/" + row.id}>
                            {row.postCategoryName === "교육" ? (
                              <span
                                className={`badge rounded-pill bg-info text-white`}
                                style={{ opacity: "0.7" }}
                              >
                                {row.postCategoryName}
                              </span>
                            ) : (
                              <span
                                className={`badge rounded-pill bg-primary text-white`}
                                style={{ opacity: "0.7" }}
                              >
                                {row.postCategoryName}
                              </span>
                            )}{" "}
                            {row.postTitle}
                          </Link>
                        </span>
                      </TableCell>

                      <TableCell align="center">
                        <span className="secotext">{row.postAuthor}</span>
                      </TableCell>
                      <TableCell align="center">
                        <span className="secotext">
                          {moment(row.postRegDate).format("yyyy-MM-DD")}
                        </span>
                      </TableCell>
                      <TableCell align="center">
                        <span className="secotext">
                          {row.replyYn == "Y" ? (
                            <div
                              className={`badge rounded-pill bg-success text-white`}
                              style={{ opacity: "0.7" }}
                            >
                              답변 완료
                            </div>
                          ) : (
                            <div
                              className={`badge rounded-pill bg-secondary text-white`}
                              style={{ opacity: "0.7" }}
                            >
                              답변 대기중
                            </div>
                          )}
                        </span>
                      </TableCell>
                    </ColorTableRow>
                  );
                })}
              </TableBody>
            )}
          </Table>
        </TableContainer>
      </div>
      <TablePagination
        rowsPerPageOptions={[5, 10, 15]}
        component="div"
        count={searchedData.total}
        rowsPerPage={pageSize}
        page={pageNum}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </Fragment>
  );
}
