import React, { useContext } from "react";
import styles from "./Footer.module.scss";
import Layout from "../Layout/Layout";
import { Logo, Link } from "../../elements";
import THEME from "../../../state/theme";
import { useCustomState } from "../../../state/state";
import AuthContext from "../../../context/Auth.context";

export default () => {
  const state = useCustomState()[0];
  const auth = useContext(AuthContext);

  // 하단 Services 링크 출력
  const servicesList = state.data.footerMenu
    .map((item, index) => (
      <Link key={index} url={item.url} hoverStyle={{ color: THEME.color }}>
        <i className="las la-angle-right" />
        <span className="secomin secodgray">{item.title}</span>
      </Link>
    ))
    .slice(0, 5);

  // 하단 Links 링크 출력
  const fastLinkList = state.data.fast_links.map((item, index) => (
    <a key={index} href={item.url} target="_blank" rel="noopener noreferrer">
      <i className="las la-angle-right" />
      <span className="secomin secodgray">{item.title}</span>
    </a>
    // <Link key={index} url={item.url} hoverStyle={{ color: THEME.color }}>
    //   <i className="las la-angle-right" />
    //   <span className="secomin secodgray">{item.title}</span>
    // </Link>
  ));
  return (
    <div id="mainFooter" className={styles.footerWrapper}>
      <Layout
        col="1"
        padding
        style={{ background: "#e6e6e6", padding: "10px" }}
      >
        <div className={styles.footer}>
          <div className={styles.logo_area}>
            <Logo auth={auth.auth} />
            <p className="secomin secogray">
              주식회사 오픈이지
              <br />
              사업자 등록번호 : 737 – 81 – 00396
              <br />
              주소 : 서울시 중구 퇴계로 36길 2 동국대 충무로영상센터 704호
            </p>
            <div className={styles.social}>
              <a target="_blank" href="https://www.facebook.com/openeg2016">
                <i className="lab la-facebook-f" />
              </a>

              <a
                target="_blank"
                href="https://www.youtube.com/channel/UC_IoWm-YVqHEUiBW7TQQ6bw"
              >
                <i className="fab fa-youtube"></i>
              </a>

              <a target="_blank" href="https://www.instagram.com/openeg_2016/">
                <i className="fab fa-instagram"></i>
              </a>
            </div>
          </div>
          <div className={styles.link_area}>
            {/*<div className={styles.links}>*/}
            {/*  <h4 className="secodgray">Services</h4>*/}
            {/*  {servicesList}*/}
            {/*</div>*/}
            {/*<div className={styles.links}>*/}
            {/*  <h4 className="secodgray">Links</h4>*/}
            {/*  {fastLinkList}*/}
            {/*</div>*/}
          </div>
          <div className={styles.subscribe}>
            <h4 className="secodgray">교육운영사무국</h4>
            <p className="secomin">
              전화 : 02 - 6011 - 9633
              <br />
              운영시간 : 09 : 00 ~ 18 : 00
              <br />
              이메일 : hello@openeg.co.kr
            </p>
            <p>
              Copyright &copy; {new Date().getFullYear()}. OPENEG Corporation.
              All rights reserved.
            </p>
          </div>
        </div>
      </Layout>
    </div>
  );
};
