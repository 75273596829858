import Axios from "axios";
import React, { useContext } from "react";
import { useState } from "react";
import { useEffect } from "react";
import { Link } from "react-router-dom";
import { withRouter } from "react-router";
import { toast } from "react-toastify";
import AuthContext from "../../../context/Auth.context";
import Message from "../../../utilities/Message";
import Header from "../../components/header/Header";
import Navbar from "../Navbar";
import {
  DisabledTableRow,
  Table,
  TableHeader,
  TableRow,
  Wrapper,
} from "../style";
// import * as Sentry from "@sentry/react";

// var process = require("../../../myProcess.json");

const TeacherTask = ({ history }) => {
  const auth = useContext(AuthContext);
  const token = localStorage.getItem("token");
  const [tasks, setTasks] = useState();

  useEffect(() => {
    const url = `${process.env.REACT_APP_IP}/teachers/lc-posts/tasks`;

    Axios.get(url, { headers: { AccessToken: `Bearer ${token}` } })
      .then((response) => {
        setTasks(response.data);
      })
      .catch((error) => {
        if (error.response !== undefined) {
          if (error.response.status === 401) {
            toast.error(Message.SERVICE_INVALID_TOKEN);
          } else if (error.response.status === 403) {
            toast.error(Message.ERROR_FORBIDDEN);
          } else if (error.response.status === 500) {
            toast.error(Message.ERROR_500);
          }
          // auth.onLogout();
          // history.push("/login");
          // Sentry.captureException(error);
        }
      });
  }, []);

  return (
    <>
      <Header data={{ title: "과제 관리", contents: "수강전 선수과제" }} />
      <Navbar title={"과제 관리"} />
      <Wrapper>
        <Table>
          <TableHeader>
            <div className="sm-div">No.</div>
            <div>과제명</div>
            <div className="md-div">제출기간</div>
            <div className="sm-div">제출인원</div>
            <div className="sm-div">채점완료</div>
          </TableHeader>
          {tasks && tasks.length !== 0 ? (
            tasks.map((item, i) => (
              <Link key={item.id} to={`/teacher/task/${item.id}`}>
                <TableRow>
                  <div className="sm-div">{i + 1}</div>
                  <div>{item.title}</div>
                  <div className="md-div">{`${item.startDate.split("T")[0]} ~ ${
                    item.endDate.split("T")[0]
                  }`}</div>
                  <div className="sm-div">{`${item.submitCount}`}</div>
                  {console.log(item)}
                  <div className="sm-div">{`${item.scoreCount}`}</div>
                </TableRow>
              </Link>
            ))
          ) : (
            <>
              <DisabledTableRow>
                <div>출제된 과제가 없습니다.</div>
              </DisabledTableRow>
            </>
          )}
        </Table>
      </Wrapper>
    </>
  );
};

export default withRouter(TeacherTask);
