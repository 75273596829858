import axios from "axios";
import { useContext } from "react";
import { useQuery } from "react-query";
import { toast } from "react-toastify";
import AuthContext from "../../context/Auth.context";
import { birthdayFormatter } from "../../modules/libs/util";
import Message from "../../utilities/Message";

// const process = require("../../myProcess.json");

const useStudents = () => {
  const token = localStorage.getItem("token");
  const auth = useContext(AuthContext);
  const { data, isLoading } = useQuery(
    ["students", token],
    async () => {
      return await axios
        .get(
          `${process.env.REACT_APP_IP}/teachers/exam/counseling/${auth.classId}`,
          {
            headers: { AccessToken: `Bearer ${token}` },
          }
        )
        .then((res) => {
          console.log(res.data);
          return res.data
          // return res.data.map((v) => {
          //   if (v.regDate) {
          //     return { ...v, regDate: v.regDate.split("T")[0] };
          //   }
          //   if (v.birthday) {
          //     return { ...v, birthday: birthdayFormatter(v.birthday) };
          //   }
          //   return v;
          // });
        })
        .catch((error) => {
          if (error.response.status === 401) {
            toast.error(Message.SERVICE_INVALID_TOKEN);
          } else if (error.response.status === 403) {
            toast.error(Message.ERROR_FORBIDDEN);
          } else if (error.response.status === 500) {
            toast.error(Message.ERROR_500);
          }
        });
    },
    { enabled: !!token }
  );
  return { data, isLoading };
};

export default useStudents;
