import React from "react";
import styled from "styled-components";

const ShortAnswer = ({ level, data }) => {
  return (
    <div>
      <QuestionType>
        <h3>주관식</h3>
      </QuestionType>
      <QuestionType>
        {level === 0 && <h3>난이도: 하</h3>}
        {level === 1 && <h3>난이도: 중</h3>}
        {level === 2 && <h3>난이도: 상</h3>}
      </QuestionType>
      <H2>문제 분류</H2>

      <H3>{data?.objectiveName}</H3>

      <br />
      <H2>문제</H2>

      <H3 style={{ whiteSpace: "pre-line", lineHeight: "2.4rem" }}>
        {data?.examQuestion}
      </H3>

      <br />

      {data?.examQuestionContent === "" ||
      data?.examQuestionContent === null ? null : (
        <QuestionBox>
          <H3 style={{ whiteSpace: "pre-line", lineHeight: "2.4rem" }}>
            {data?.examQuestionContent}
          </H3>
        </QuestionBox>
      )}

      <br />
      <H2>정답</H2>

      <Answer>{data?.examCorrectAnswer}</Answer>

      <br />
    </div>
  );
};
const Answer = styled.div`
  font-weight: 400;
  min-width: 30px;
  padding-left: 20px;
  border-left: solid gray 4px;
  font-size: 22px;
`;

const QuestionBox = styled.div`
  background-color: rgb(233, 233, 233);
  padding: 20px;
`;
const H3 = styled.h3`
  font-weight: 300;
`;
const H2 = styled.h2`
  font-weight: 500;
`;

const QuestionType = styled.div`
  justify-content: center;
  float: right;
  background-color: rgb(231, 235, 238);
  width: 160px;
  min-height: 40px;
  padding-left: 20px;
  padding-right: 20px;
  border: solid 1px silver;
  display: flex;
  align-items: center;
  span {
    display: inline-block;
  }
  h3 {
    font-weight: 300;
  }
`;

export default ShortAnswer;
