import axios from "axios";
import { useQuery } from "react-query";

// const process = require("../../../myProcess.json");

const useProblem = (problemId) => {
  const token = localStorage.getItem("token");

  const { data, isLoading } = useQuery(
    ["teacher", "problem", problemId],
    async () => {
      return await axios
        .get(
          `${process.env.REACT_APP_IP}/teachers/exam/creation/problem/${problemId}`,
          {
            headers: { AccessToken: `Bearer ${token}` },
          }
        )
        .then((res) => {
          return res.data;
        });
    },
    { enabled: !!token && !!problemId }
  );

  return { data, isLoading };
};

export default useProblem;
