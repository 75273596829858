import Axios from "axios";
import React, { useContext } from "react";
import { useState, useEffect } from "react";
import FileSaver from "file-saver";
import { useParams, useHistory } from "react-router";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import AuthContext from "../../../context/Auth.context";
import Message from "../../../utilities/Message";
import Header from "../../components/header/Header";
import Navbar from "../Navbar";
import {
  Btn,
  BtnWrapper,
  DisabledTableRow,
  Table,
  TableHeader,
  TableRow,
  Wrapper,
} from "../style";
// import * as Sentry from "@sentry/react";

// var process = require("../../../myProcess.json");

const StudentList = ({ history }) => {
  const auth = useContext(AuthContext);
  const token = localStorage.getItem("token");
  const [list, setList] = useState();

  const { taskId } = useParams();

  const historyRoute = useHistory();

  useEffect(() => {
    const url = `${process.env.REACT_APP_IP}/teachers/lc-posts/tasks/${taskId}`;

    if (token != null || token != undefined) {
      Axios.get(url, { headers: { AccessToken: `Bearer ${token}` } })
        .then((response) => {
          setList(response.data);
        })
        .catch((error) => {
          if (error.response !== undefined) {
            if (error.response.status === 401) {
              toast.error(Message.SERVICE_INVALID_TOKEN);
            } else if (error.response.status === 403) {
              toast.error(Message.ERROR_FORBIDDEN);
            } else if (error.response.status === 500) {
              toast.error(Message.ERROR_500);
            }
            // auth.onLogout();
            // history.push("/login");
            console.log(3);
            // // Sentry.captureException(error);
          }
        });
    } else {
    }
  }, []);

  const handleDownload = async (taskId) => {
    const url = `${process.env.REACT_APP_IP}/teachers/lc-posts/submit-tasks/downloadAll/${taskId}`;
    return await Axios.get(url, {
      headers: { AccessToken: `Bearer ${token}` },
      responseType: "blob",
    })
      .then((res) => {
        if (res) {
          console.log(res);
          FileSaver.saveAs(
            res.data,
            decodeURI(res.headers["content-disposition"].split("filename=")[1])
          );
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <>
      <Header
        data={{
          title: "과제 관리",
          content: list && list.length !== 0 ? list[0].title : "",
        }}
      />
      <Navbar title={"과제 관리"} />
      <Wrapper>
        <Btn onClick={() => handleDownload(taskId)} color="#2E90CF">
          제출된 과제 모두 다운로드
        </Btn>
        <Table>
          <TableHeader>
            <div className="sm-div">No.</div>
            <div>학습자</div>
            <div className="md-div">제출일</div>
            <div className="sm-div">점수</div>
          </TableHeader>
          {list &&
            list.map((item, i) =>
              item.submitDate ? (
                <Link
                  key={item.userId}
                  to={`/teacher/task/${taskId}/${item.id}`}
                >
                  <TableRow>
                    <div className="sm-div">{i + 1}</div>
                    <div>{item.userName}</div>
                    <div className="md-div">
                      {item.submitDate.split("T")[0]}
                    </div>
                    <div className="sm-div">
                      {item.score ? `${item.score}/100` : "채점중"}
                    </div>
                  </TableRow>
                </Link>
              ) : (
                <DisabledTableRow key={item.userId}>
                  <div className="sm-div">{i + 1}</div>
                  <div>{item.userName}</div>
                  <div className="md-div">-</div>
                  <div className="sm-div">-</div>
                </DisabledTableRow>
              )
            )}
        </Table>
        <BtnWrapper>
          <Link to={`/teacher/task`}>
            <Btn color="rgba(0, 0, 0, 0.3)">돌아가기</Btn>
          </Link>
        </BtnWrapper>
      </Wrapper>
    </>
  );
};

export default StudentList;
