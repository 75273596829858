import React, { useRef, useContext } from "react";
import { withRouter } from "react-router";
import Slider from "react-slick";
import styles from "./Slider.module.scss";
import Layout from "../../layouts/Layout/Layout";
import { Button } from "../../elements";
import { toast } from "react-toastify";
import AuthContext from "../../../context/Auth.context";
import Message from "../../../utilities/Message";

export default withRouter(({ data = [], history }) => {
  const auth = useContext(AuthContext);

  const handleSecurePage = (id) => {
    if (id === "submittersignup") {
      history.push(`/submittersignup`);
    } else {
      if (auth.auth) {
        if (auth.type !== "1") {
          toast.error(Message.SERVICE_STUDENT_ONLY);
        } else if (
          auth.type === "1" &&
          auth.status !== "1" &&
          id === "capabilitytest"
        ) {
          history.push(`/student/${id}`);
        } else if (auth.type === "1" && auth.status !== "1") {
          toast.error(Message.STATUE_NOT_CONFIRMED);
        } else if (auth.type === "1" && auth.status === "1") {
          history.push(`/student/${id}`);
        }
      } else {
        toast.error(Message.SERVICE_AUTHENTICATED_ONLY);
      }
    }
  };

  let sliderRef = useRef(null);

  const settings = {
    dots: false,
    arrows: false,
    infinite: true,
    speed: 1000,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  const slideList = data.map((item, index) => (
    <article key={index}>
      <Layout
        style={{
          //background: "url(" + item.image + ") center/cover",
          backgroundColor: "#f0f5f9 !important",
        }}
        col="1"
        mode="light"
      >
        <div className={styles.slide}>
          <div className={styles.iLeft}>
            <i
              className={"las la-angle-left"}
              onClick={() => sliderRef.slickPrev()}
            />
          </div>

          <div className={styles.intro}>
            <h3>{item.subtitle}</h3>
            <h1>{item.title}</h1>
            <img src={item.subImg} width="80%" alt="subImg" />
            <Button
              click={() => handleSecurePage(item.id)}
              type="outline-color-tw"
              hoverType="solid-color-tw"
            >
              <span className="secosub2">바로가기</span>
            </Button>
            <p className="secotext">{item.short}</p>
          </div>
          <div className={styles.slideRight}>
            <img src={item.mainImg} alt="mainImg" />
          </div>

          <div className={styles.iRight}>
            <i
              className={"las la-angle-right"}
              onClick={() => sliderRef.slickNext()}
            />
          </div>
        </div>
      </Layout>
    </article>
  ));

  return (
    <Slider ref={(slider) => (sliderRef = slider)} {...settings}>
      {slideList}
    </Slider>
  );
});
