import React from "react";

import { List } from "@material-ui/core";
import Fade from "@material-ui/core/Fade";
import Menu from "@material-ui/core/Menu";
import { Fragment, useContext, useEffect, useState } from "react";
import { withRouter } from "react-router-dom";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import AuthContext from "../../../context/Auth.context";
import { useCustomState } from "../../../state/state";
import Message from "../../../utilities/Message";
import { Button, Logo } from "../../elements";
import Layout from "../Layout/Layout";
import styles from "./Header.module.scss";
import DefaultPwdChange from "../../../modules/login/DefaultPwdChange";

// var process = require("../../../myProcess.json");
const cms_url = process.env.REACT_APP_CMS_URL;

export default withRouter(({ data = [], history }) => {
  // 내정보 팝업
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  // 헤더 메뉴 sticky 설정
  const [sticky, setSticky] = useState(false);
  const actions = useCustomState()[1];

  const handleResize = () => {
    setSticky(window.pageYOffset > 200 ? true : false);
  };

  // 로그인 상태 정보
  const auth = useContext(AuthContext);
  const token = localStorage.getItem("token");
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setLoading(false);
    window.addEventListener("scroll", handleResize);
    return () => {
      window.removeEventListener("scroll", handleResize);
    };
  }, [auth]);

  const [menuData, setMenuData] = useState();

  useEffect(() => {
    const str = auth.type === "1" ? "교강사" : "스터디그룹";
    const str1 = auth.type === "1" ? "" : "";
    let newArr = data.filter((item) => item.name !== str && item.name !== str1);
    if (auth.type !== "1") {
      setMenuData(
        newArr.map((item) =>
          item.name === "내클래스"
            ? {
              ...item,
              children: item.children.slice(0, 2),
            }
            : item,
        ),
      );
    } else {
      setMenuData(newArr);
    }
  }, [data, auth]);

  const handleLogout = () => {
    auth.onLogout();
    setAnchorEl(null);
    history.push("/login");
  };

  const handleMypage = () => {
    if (auth.auth) {
      history.push("/mypagemyinformation");
    } else {
      toast.error(Message.SERVICE_AUTHENTICATED_ONLY);
      history.push("/login");
    }
    handleClose();
  };

  // 메뉴 클릭 시 접근 권한 체크 후 이동
  const handleClick = (e) => {
    e.preventDefault();
    // 이용안내             /aboutUser
    //  훈련장 이용 안내    /aboutUser
    //  출제자 이용 안내    /aboutSubmitter
    //  가격 정보           /aboutPrice
    // 학습자               /student
    //  학습실              /student/Course
    //  보안약점 진단훈련   /student/securelist
    //  프로젝트 개발훈련   /student/codediagnosis/listproblems
    //  대시보드            /common/dashboard
    // 출제자               /submittersignup
    //  출제자 신청         /submittersignup
    //  문제 출제           /submitterlist
    //  비용정산            /
    // 고객센터             /customer
    //  자료실              /customer
    //  자주묻는 질문       /customer/faq
    //  사용자 후기         /customer/review
    //  문의하기            /customer/contact
    const { path, title } = e.target.dataset;

    const authOnlyServices = [
      "/student", // 학습자
      "/student/Course", // 학습실
      "/student/securelist", // 보안약점 진단훈련
      "/student/codediagnosis", // 프로젝트 개발훈련
      "/common/dashboard", // 대시보드
      "/submitterlist", // 문제출제
      "/customer", // 고객센터 & 자료실
      "/customer/faq", // 자주묻는질문
      "/customer/review", // 사용자후기
      "/customer/contact", // 문의하기
    ];

    if (authOnlyServices.indexOf(path) !== -1 && !auth.authToken) {
      toast.error(`"${title}" 메뉴는 ${Message.SERVICE_AUTHENTICATED_ONLY}`);
      return false;
    }

    if (path.indexOf("/student") === 0) {
      if (auth.type !== "1") {
        toast.error(`"${title}" 메뉴는 ${Message.SERVICE_STUDENT_ONLY}`);
        return false;
      } else if (auth.status !== "1") {
        toast.error(Message.STATUS_NOT_CONFIRMED);
        return false;
      }
    } else if (path.indexOf("/submitterlist") === 0 && auth.type !== "2") {
      toast.error(`"${title}" 메뉴는 ${Message.SERVICE_TRAINER_ONLY}`);
      return false;
    }

    // if (path === "/capability/test") {
    //   if (auth.auth) {
    //     const url = `${process.env.REACT_APP_IP}/users/exam/taken/${auth.uid}`;
    //     Axios.get(url, {
    //       headers: {
    //         AccessToken: `Bearer ${token}`,
    //       },
    //     })
    //       .then((response) => {
    //         const { data } = response;
    //         if (data === 3) {
    //           toast.error("평가 대상이 아닙니다.");
    //         } else if (data === 2) {
    //           toast.error("평가 기간이 아닙니다.");
    //         } else if (data === 1) {
    //           toast.error("역량평가를 이미 응시하셨습니다.");
    //         } else {
    //           history.push(path);
    //         }
    //       })
    //       .catch((error) => {
    //         if (error.response !== undefined) {
    //           if (
    //             error.response.status === 401 ||
    //             error.response.status === 403
    //           ) {
    //             toast.error(Message.SERVICE_INVALID_TOKEN);
    //           }
    //           auth.onLogout();
    //         }
    //       });
    //   } else {
    //     toast.error(Message.SERVICE_AUTHENTICATED_ONLY);
    //   }
    // } else {
    // }
    history.push(path);
  };

  const menu = menuData?.map((item, index) => {
    if (!item.children) {
      return (
        <li key={index}>
          <a
            className="secosub2"
            data-path={item.url}
            data-title={item.name}
            href="#"
            onClick={handleClick}
          >
            {item.name}
          </a>
        </li>
      );
    } else {
      return (
        <li key={index}>
          <span>
            <a
              className="secosub2"
              data-path={item.url}
              data-title={item.name}
              href="#"
              onClick={handleClick}
            >
              {item.name}
            </a>
          </span>
          <ul>
            {item.children.map((subitem, subindex) => (
              <li key={subindex}>
                <a
                  className="secomin"
                  data-path={subitem.url}
                  data-title={subitem.name}
                  href="#"
                  onClick={handleClick}
                >
                  {subitem.name}
                </a>
              </li>
            ))}
          </ul>
        </li>
      );
    }
  });

  const header = (
    <Layout>
      <div className={styles.header} id="mainHeader">
        <Logo auth={auth.auth} dark />
        <ul className={styles.menu}>
          {menu}
          {/*{auth.type !== "1" && auth.type !== "2" && (*/}
          {/*  <li>*/}
          {/*    <a className="secosub2" href={cms_url}>*/}
          {/*      Go to CMS*/}
          {/*    </a>*/}
          {/*  </li>*/}
          {/*)}*/}
        </ul>
        {!loading ? (
          auth.auth ? (
            <Fragment>
              <button
                className="secotext"
                type="button"
                id="login"
                onClick={handleMenu}
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  marginTop: "0px",
                  padding: "12px 25px",
                  borderRadius: "5px",
                  background:
                    "linear-gradient(89.85deg, #2E90CF 10.06%, #302ae9 86.56%)",
                  color: "white",
                  outline: "none",
                  border: "none",
                  fontSize: "18px",
                  cursor: "pointer",
                  whiteSpace: "nowrap",
                }}
              >
                <span style={{ marginBottom: "0" }}>
                  {localStorage.getItem("userName")}
                </span>
                <i className="fas fa-user" style={{ marginLeft: "10px" }}></i>
              </button>
              <Menu
                id="login"
                anchorEl={anchorEl}
                keepMounted
                open={open}
                onClose={handleClose}
                TransitionComponent={Fade}
              >
                <div className={styles.dwUserBox}>
                  <div className={styles.uImg}>
                    <img src="/assets/icons/pf.png" alt="user" />
                  </div>
                  <div className={styles.uText}>
                    <p className="secotext secodgray">
                      {localStorage.getItem("userName")}
                    </p>
                    <p className="secomin secogray">
                      {localStorage.getItem("userEmail")}
                    </p>
                  </div>
                </div>
                <li
                  role="separator"
                  className={styles.divider}
                  style={{ marginBottom: "10px" }}
                />
                <List>
                  <button
                    className="btn btn-outline-primary secotext"
                    type="button"
                    id="button-addon2"
                    onClick={handleMypage}
                    style={{ padding: "5px 15px", marginLeft: "20px" }}
                  >
                    <i
                      className="far fa-address-book"
                      style={{ marginTop: "5px" }}
                    ></i>{" "}
                    Mypage
                  </button>
                  <button
                    className="btn btn-outline-danger secotext"
                    type="button"
                    id="button-addon2"
                    onClick={handleLogout}
                    style={{
                      padding: "5px 15px",
                      marginLeft: "5px",
                      marginRight: "20px",
                    }}
                  >
                    <i
                      className="fas fa-power-off"
                      style={{ marginTop: "5px" }}
                    ></i>{" "}
                    Signout
                  </button>
                </List>
              </Menu>
            </Fragment>
          ) : (
            <Fragment>
              <div className={styles.btn_desktop}>
                <Button
                  after="&#xf107;"
                  type="outline-color-tw"
                  hoverType="solid-color-tb"
                >
                  <span className="secosub2">Login</span>
                </Button>
              </div>
              <div
                className={styles.btn_mobile}
                onClick={() => actions.toggleSidebar()}
              >
                <Button
                  after="&#xf0c9;"
                  type="solid-white-tb"
                  hoverType="solid-gray-tb"
                />
              </div>
            </Fragment>
          )
        ) : (
          ""
        )}
      </div>
    </Layout>
  );

  return (
    <Fragment>
      <DefaultPwdChange />
      <div className={styles.wrapper}>{header}</div>
      <div
        className={[
          styles.wrapper,
          sticky ? styles.sticky : styles.hidden,
        ].join(" ")}
      >
        {header}
      </div>
      {/* <ToastContainer style={{ width: '40%', fontSize: '15px', fontWeight:'400', lineHeight:'22.4px', letterSpacing:'-0.15px' }} position="bottom-right"/> */}
    </Fragment>
  );
});
