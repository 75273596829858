import React, { Fragment, useState, useEffect, useRef } from "react";
import styles from "./MypageMyInformation.module.scss";
import { Link } from "react-router-dom";
import "../MyPage.css";
import AccountInfo from "./AccountInfo";

export default ({ sidebar = "left" }) => {
  const ref = useRef(null);
  const [height, setHeight] = useState(0);
  const [active, setActive] = React.useState(2);

  useEffect(() => {
    setHeight(ref.current.clientHeight);
    window.addEventListener("resize", resizeListener);
    return window.removeEventListener("resize", resizeListener);
  }, []);

  const resizeListener = () => {
    setHeight(ref.current.clientHeight);
  };

  const handelClick = (e) => {
    setActive(e);
  };

  return (
    <Fragment>
      <div className={styles.mypage} ref={ref}>
        <section
          className={[
            styles.wrapper,
            sidebar === "left"
              ? styles.with_sidebar + " " + styles.left
              : sidebar === "right"
                ? styles.with_sidebar + " " + styles.right
                : null,
          ].join(" ")}
        >
          {/*<aside className={styles.sidebar}>*/}
          {/*  <div className="myPageLeftSide">*/}
          {/*    <ul>*/}
          {/*      <li className={active === 2 ? "mypageLeftsideActive" : ""}>*/}
          {/*        <Link*/}
          {/*          to="/mypagemyinformation"*/}
          {/*          onClick={() => handelClick(2)}*/}
          {/*        >*/}
          {/*          <span className="secotext">*/}
          {/*            <i className="far fa-address-book fa-2x"></i>*/}
          {/*            <br />내 정보 관리*/}
          {/*          </span>*/}
          {/*        </Link>*/}
          {/*      </li>*/}
          {/*      <li className={active === 3 ? "mypageLeftsideActive" : ""}>*/}
          {/*        <Link to="/mypagemyqanda" onClick={() => handelClick(3)}>*/}
          {/*          <span className="secotext">*/}
          {/*            <i className="far fa-envelope-open sideMenuLast fa-2x"></i>*/}
          {/*            <br />*/}
          {/*            나의 문의*/}
          {/*          </span>*/}
          {/*        </Link>*/}
          {/*      </li>*/}
          {/*    </ul>*/}
          {/*  </div>*/}
          {/*</aside>*/}
          <article className="mypage">
            <div>
              <AccountInfo />
            </div>
          </article>
        </section>
      </div>
    </Fragment>
  );
};
