import React, { useEffect, useState } from "react";

export default function TestShortAnswer(props) {
  const [value, setValue] = useState([]);
  const [tmpValue, setTmpValue] = useState(true);
  const [answer0, setAnswer0] = useState("");
  const [answer1, setAnswer1] = useState("");
  const [answer2, setAnswer2] = useState("");
  const answerList = new Array(props.answerCount);

  let nArray = Array.from({ length: props.answerCount }, () => "");

  useEffect(() => {
    setValue([]);
    setAnswer0("");
    setAnswer1("");
    setAnswer2("");
    let savedAnswerList = sessionStorage.getItem("saveAnswer");
    if (savedAnswerList !== null) {
      let answer = savedAnswerList.split(",")[props.idx];
      if (answer !== "" || answer.length > 0 || typeof answer !== "undefined") {
        let answerArr = (answer || "").split(" ");
        if (answerArr.length === 1) {
          setAnswer0(answerArr[0]);
        } else if (answerArr.length === 2) {
          setAnswer0(answerArr[0]);
          setAnswer1(answerArr[1]);
        } else if (answerArr.length === 3) {
          setAnswer0(answerArr[0]);
          setAnswer1(answerArr[1]);
          setAnswer2(answerArr[2]);
        }
      }
    }
  }, [props.idx]);

  useEffect(() => {
    answerList.splice(0, answerList.length);
    answerList.push(
      answer0.replace(/ /gi, ""),
      answer1.replace(/ /gi, ""),
      answer2.replace(/ /gi, "")
    );
    if (answer0 === "" && answer1 === "" && answer2 === "") {
      setValue(null);
      setTmpValue(!tmpValue);
    } else {
      let tmp = answerList.join(" ");
      setValue(tmp.trim());
      setTmpValue(!tmpValue);
    }
  }, [answer0, answer1, answer2]);

  // 2022/2/10 정규식 수정 - 황길성
  // const regex = /[{}[]?.,;:|\)*~`!^-_+<>@#$%&=\('"]/g;

  // 2022/12/5 정규식 수정(특수문자 차단) - 이정현
  const regex = /[\{\}\[\]\/?.,;:|\)*~`!^\-_+<>@\#$%&\\\=\(\'\"]/g;

  // const regex = /[\[\[\]\{\}\/\(\)\?\<\>\!\@\#\$\%\^\&\*\;]/g;

  const handleInputText = (e, idx) => {
    console.log(e.target.value);
    if (regex.test(e.target.value)) {
      alert("사용 할 수 없는 문자가 포함되어 있습니다.");
      eval(`setAnswer${idx}`)(e.target.value.replace(regex, " "));
      console.log("사용할 수 없는 문자 포함");
    } else {
      eval(`setAnswer${idx}`)(e.target.value);
      console.log("사용할 수 없는 문자 불포함");
    }
  };

  const onKeyPress = (e) => {
    if (e.key === "Enter") {
      props.handleNextButton();
    }
  };

  useEffect(() => {
    if (value && !value.length) {
      props.setCheckAnswer("");
    } else {
      props.setCheckAnswer(value);
    }

    return function cleanup() {
      props.setCheckAnswer("");
    };
  }, [tmpValue]);

  return (
    <div className="choices">
      {/* 단답형 */}
      {nArray &&
        nArray.map((nums, idx) => (
          <li key={idx}>
            <p>답 {idx + 1}.</p>
            <input
              type="text"
              value={eval(`answer${idx}`)}
              maxLength="30"
              onChange={(e) => handleInputText(e, idx)}
              onKeyPress={onKeyPress}
              id={"v" + idx}
              className="form-control"
            />
          </li>
        ))}
      {/* <input type="text" value={value} maxLength="30" onChange={handleInputText} onKeyPress={onKeyPress} className="form-control"/> */}
    </div>
  );
}
