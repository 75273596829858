import React from "react";
import styled from "styled-components";

const OXAnswer = ({ level, data }) => {
  return (
    <div>
      <QuestionType>
        <h3>OX퀴즈</h3>
      </QuestionType>
      <QuestionType>
        {level === 0 && <h3>난이도: 하</h3>}
        {level === 1 && <h3>난이도: 중</h3>}
        {level === 2 && <h3>난이도: 상</h3>}
      </QuestionType>
      <H2>문제 분류</H2>

      <H3>{data?.objectiveName}</H3>

      <br />
      <H2>문제</H2>

      <H3 style={{ whiteSpace: "pre-line", lineHeight: "2.4rem" }}>
        {data?.examQuestion}
      </H3>

      <br />

      {data?.examQuestionContent === "" ||
      data?.examQuestionContent === null ? null : (
        <QuestionBox>
          <H3 style={{ whiteSpace: "pre-line", lineHeight: "2.4rem" }}>
            {data?.examQuestionContent}
          </H3>
        </QuestionBox>
      )}
      <br />
      <H2>정답</H2>

      <Answer>{data?.examCorrectAnswer}</Answer>

      <br />
    </div>
  );
};

const Answer = styled.div`
  font-weight: 400;
  min-width: 30px;
  padding-left: 20px;
  border-left: solid gray 4px;
  font-size: 22px;
`;

const QuestionBox = styled.div`
  background-color: rgb(233, 233, 233);
  padding: 20px;
`;
const H3 = styled.h3`
  font-weight: 300;
`;
const H2 = styled.h2`
  font-weight: 500;
`;

const QuestionType = styled.div`
  justify-content: center;
  float: right;
  background-color: rgb(231, 235, 238);
  width: 160px;
  height: 40px;
  padding-left: 20px;
  padding-right: 20px;
  border: solid 1px silver;
  display: flex;
  align-items: center;
  span {
    display: inline-block;
  }
  h3 {
    font-weight: 300;
  }
`;

const Choice = styled.div`
  padding: 5px;
  padding-left: 20px;
  padding-right: 20px;
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: space-between;
  border-top: solid 0.5px rgb(222, 222, 225);
  border-left: solid 0.5px rgb(222, 222, 225);
  border-right: solid 0.5px rgb(222, 222, 225);
  &:nth-child(1) {
    border-radius: 6px 6px 0 0;
  }
  &:nth-child(4) {
    border-radius: 0 0 6px 6px;
    border-bottom: solid 0.5px rgb(222, 222, 225);
  }
`;

export default OXAnswer;
