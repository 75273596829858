import axios from "axios";
import { useMutation } from "react-query";

// const process = require("../../myProcess.json");

const useMutatePrevExam = () => {
  const token = localStorage.getItem("token");
  const classId = localStorage.getItem("classId");
  const { mutate, isLoading } = useMutation(
    async ({ examId, problemId, unitId }) => {
      return await axios
        .put(
          `${process.env.REACT_APP_IP}/teachers/exam/creation/pre-question`,
          {
            examId,
            problemId,
            unitId,
          },
          {
            headers: { AccessToken: `Bearer ${token}` },
            classId,
          }
        )
        .then((res) => {
          return res.data;
        });
    }
  );
  return { mutate, isLoading };
};

export default useMutatePrevExam;
