import React, { useContext } from "react";
import { Button, Modal } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { useParams } from "react-router";
import AuthContext from "../../../../context/Auth.context";
import { useQueryClient } from "react-query";
import useMutateCounseling from "../../../../hooks/teacher/useMutateCounseling";
import { BtnLg } from "../../style";
import { ErrorMsg, InputWrapper, ModalForm } from "../style";
import swal from "sweetalert";

const Write = ({ data, modalType, handleClose }) => {
  const { userId } = useParams();
  const auth = useContext(AuthContext);
  const queryClient = useQueryClient();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const { mutate, isLoading } = useMutateCounseling();

  const onSubmit = ({ title, content }) => {
    if (isLoading) return;
    const jsonData = {
      authorId: auth.uid,
      studentId: userId,
      title,
      content,
    };
    mutate(jsonData, {
      onSuccess: (data) => {
        queryClient.invalidateQueries(["counselingComments", userId]);
        swal("상담일지가 등록되었습니다.", {
          icon: "success",
        });
        handleClose();
      },
      onError: (err) => {
        console.log(err);
      },
    });
  };
  return (
    <Modal className="fade modal" size="md" show={true} onHide={handleClose}>
      <Modal.Body style={{ overflow: "auto" }}>
        <Button variant="" className="close" onClick={handleClose}>
          <span>&times;</span>
        </Button>
        <ModalForm onSubmit={handleSubmit(onSubmit)}>
          <InputWrapper style={{ marginBottom: "10px" }}>
            <input
              placeholder="상담제목"
              {...register("title", { required: "필수 항목입니다." })}
            />
            <ErrorMsg>{errors.title?.message}</ErrorMsg>
          </InputWrapper>
          <InputWrapper>
            <textarea
              rows={13}
              placeholder="상담내용을 입력하세요."
              {...register("content", { required: "필수 항목입니다." })}
            ></textarea>
            <ErrorMsg>{errors.content?.message}</ErrorMsg>
          </InputWrapper>
          <div style={{ textAlign: "center", marginTop: "15px" }}>
            <BtnLg color="#d9534f" type="submit">
              상담저장
            </BtnLg>
          </div>
        </ModalForm>
      </Modal.Body>
    </Modal>
  );
};

export default Write;
