import React from "react";
import styles from "./Backdrop.module.scss";
import { useCustomState } from "../../../state/state";

export default () => {
  const [state, actions] = useCustomState();

  const hideContent = () => {
    state.sidebar && actions.toggleSidebar();
    state.request.modal && actions.toggleRequest();
    state.signconfirm.modal && actions.toggleSignConfirm();
    state.video && actions.toggleVideo();
  };

  return <div className={styles.backdrop} onClick={hideContent} />;
};
