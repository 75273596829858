import "react-app-polyfill/ie11";
import "react-app-polyfill/stable";

import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter as Router } from "react-router-dom";
import "./index.scss";

import "./assets/css/bootstrap.min.css";
import "./assets/css/animate.css";
import "./assets/fs/css/all.css";
import "line-awesome/dist/line-awesome/css/line-awesome.min.css";
import ScrollToTop from "./utilities/scrollToTop";
import App from "./App";
import { QueryClientProvider, QueryClient } from "react-query";
import Mock from "./state/mock";
import "./state/database";

import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";

Sentry.init({
  dsn: process.env.REACT_APP_DSN,
  integrations: [new Integrations.BrowserTracing({ tracingOrigins: ["*"] })],

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 1.0,
  enabled: process.env.REACT_APP_MODE === "production",
});

Mock.onAny().passThrough();

const queryClient = new QueryClient();

ReactDOM.render(
  <QueryClientProvider client={queryClient}>
    <Router>
      <ScrollToTop />
      <App />
    </Router>
  </QueryClientProvider>,
  document.getElementById("root")
);
