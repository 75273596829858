import React, { useState } from "react";
import styles from "./InfoFind.module.scss";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import TextField from "@material-ui/core/TextField";
import { Button } from "../../elements";
import Grid from "@material-ui/core/Grid";
import Container from "@material-ui/core/Container";

import axios from "axios";
import { toast } from "react-toastify";
//import AuthContext from '../../context/Auth.context';
//import { useContext } from 'react';

// var process = require("../../../myProcess.json");

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`wrapped-tabpanel-${index}`}
      aria-labelledby={`wrapped-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={2}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `wrapped-tab-${index}`,
    "aria-controls": `wrapped-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    padding: theme.spacing(3),
    margin: "50px 0 100px 0",
  },
  paper: {
    padding: theme.spacing(3),
  },
  tab: {
    borderRaidus: "10px",
    fontWeight: "900",
    color: "gray",
    backgroundColor: "white !important",
  },
  tabBtn: {
    width: "50% !important",
    fontSize: "1.4rem !important",
  },
  tF: {
    marginLeft: "0px !important",
  },
  indicator: {
    backgroundColor: "#007bff !important",
  },
  button: {
    color: "white",
    fontSize: "1.2rem",
    margin: theme.spacing(3, 0, 2),
    backgroundColor: "#343a40",
    cursor: "pointer",
    border: "thin solid #343a40 !important",
  },
}));

export default function InfoFind() {
  const classes = useStyles();
  const [value, setValue] = React.useState("one");

  const handleChange = (event, newValue) => {
    setValue(newValue);
    setCheckedUserId("no");
    setCheckedPass("no");
  };

  const [checkedUserId, setCheckedUserId] = React.useState("no");
  const [checkedPass, setCheckedPass] = React.useState("no");
  const [confirmStatusEmail, setConfirmStatusEmail] = useState("no");
  const [confirmStatusConfirmCode, setConfirmStatusConfirmCode] =
    useState("no");

  const [values, setValues] = React.useState({
    name: "",
    email: "",
  });

  const [valuesPass, setValuesPass] = React.useState({
    userId: "",
    name: "",
    email: "",
    confirmCode: "",
  });

  const [valuesPassConfirm, setValuesPassConfirm] = React.useState({
    password: "",
    confirmPassword: "",
  });

  const [error, setError] = React.useState({
    userIdError: "",
    emailError: "",
    emailCodeError: "",
    pwdError: "",
    confirmPwd: "",
    nameError: "",
  });

  const isEmail = (email) => {
    const emailRegex =
      /^(([^<>()\].,;:\s@"]+(\.[^<>()\].,;:\s@"]+)*)|(".+"))@(([^<>()¥[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/i;
    return emailRegex.test(email);
  };

  const isPwd = (pass) => {
    const pwdRegex =
      /^.*(?=.{6,20})(?=.*[0-9])(?=.*[a-zA-Z])(?=.*[@$!%*#?&]).*$/;

    return pwdRegex.test(pass);
  };

  const confirmPassword = (pass, confirmPass) => {
    return pass === confirmPass;
  };
  // TODO:인증번호 확인 검증 로직 추가
  const onTextValidationPass = () => {
    let userIdError = "";
    let nameError = "";
    let emailError = "";
    let emailCodeError = "";
    if (valuesPass.userId.length === 0) userIdError = "아이디를 입력해주세요.";
    if (valuesPass.name.length === 0) nameError = "이름을 입력해주세요.";
    if (!isEmail(valuesPass.email)) emailError = "email 형식이 아닙니다.";
    if (!confirmStatusEmail || confirmStatusEmail === "no")
      emailError = "이메일 인증을 진행해주세요";
    if (!confirmStatusConfirmCode || confirmStatusConfirmCode === "no")
      emailCodeError = "인증 번호를 확인 해주세요";

    setError({
      userIdError,
      nameError,
      emailError,
      emailCodeError,
    });

    if ((userIdError || nameError || emailError, emailCodeError)) return false;
    return true;
  };

  const onTextValidationPassConfirm = () => {
    let pwdError = "";
    let confirmPwd = "";

    if (!isPwd(valuesPassConfirm.password))
      pwdError = "비밀번호 조건을 만족 할 수 없습니다.";
    if (
      !confirmPassword(
        valuesPassConfirm.password,
        valuesPassConfirm.confirmPassword
      )
    )
      confirmPwd = "비밀번호가 일치하지 않습니다.";
    if (valuesPass.userId === valuesPassConfirm.password)
      pwdError = "아이디를 비밀번호로 사용 할 수 없습니다.";

    setError({
      pwdError,
      confirmPwd,
    });

    if (pwdError || confirmPwd) return false;
    return true;
  };

  const handleChangeForm = (e) => {
    setValues({ ...values, [e.target.name]: e.target.value });
  };

  const handleChangeFormPass = (e) => {
    setValuesPass({ ...valuesPass, [e.target.name]: e.target.value });
  };

  const handleChangeFormPassConfirm = (e) => {
    setValuesPassConfirm({
      ...valuesPassConfirm,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmitID = (e) => {
    e.preventDefault();

    const url = `${process.env.REACT_APP_IP}/users/sign/authId`;

    axios
      .post(url, {
        name: values.name,
        email: values.email,
      })
      .then((response) => {
        if (response.data === 0) toast.error("해당하는 아이디가 없습니다.");
        else setCheckedUserId(response.data);
      })
      .catch((error) => {
        toast.error("아이디를 찾을 수 없습니다.");
        setValues({ name: "", email: "" });
      });
  };
  const handleConfirmEmail = () => {
    if (!valuesPass.email) {
      toast.error("이메일을 입력하세요.");
    } else if (!isEmail(valuesPass.email)) {
      toast.error(valuesPass.email + "는 사용할 수 없는 형식입니다.");
    } else {
      const url = `${process.env.REACT_APP_IP}/users/email/findPwEmailConfirm/${valuesPass.email}`;

      setConfirmStatusEmail(false);
      axios
        .get(url)
        .then((response) => {
          const { data } = response;
          if (data === "success") {
            setConfirmStatusEmail(true);
            toast.success(
              "입력하신 이메일 주소로 인증번호를 발송했습니다. 받은 편지함을 확인하셔서 전달된 인증번호를 입력해 주세요."
            );
          } else if (data === "sendMailErrror") {
            setConfirmStatusEmail(false);
            toast.error(
              "인증번호를 발송할 수 없습니다. 이메일 주소를 확인하신 후 다시 시도해 주세요."
            );
          } else {
            toast.error("가입된 이메일이 아닙니다.");
          }
        })
        .catch((error) => {
          toast.error(
            "서버와의 연결이 원할하지 않습니다. 잠시 후 다시 시도해 주세요."
          );
        });
    }
  };

  //  이메일 인증코드 확인
  const handleConfirmCode = () => {
    if (confirmStatusEmail !== true) {
      toast.error("이메일 인증을 먼저 시도해 주세요.");
    } else if (!valuesPass.confirmCode) {
      toast.error("인증번호를 입력하세요.");
    } else {
      const url = `${process.env.REACT_APP_IP}/users/email/confirm/${valuesPass.email}/${valuesPass.confirmCode}`;
      setConfirmStatusConfirmCode(false);
      axios
        .get(url)
        .then((response) => {
          const { data } = response;
          if (data === "success") {
            setConfirmStatusConfirmCode(true);
            toast.error("인증되었습니다. 계속해서 진행해 주세요.");
          } else {
            setConfirmStatusConfirmCode(false);
            toast.error(
              "인증번호 확인에 실패했습니다. 인증번호를 확인하신 후 다시 시도해 주세요. 인증제한시간(5분)이 초과한 경우 이메일 인증부터 다시 시도해 주세요."
            );
          }
        })
        .catch((error) => {
          toast.error(
            "서버와의 연결이 원할하지 않습니다. 잠시 후 다시 시도해 주세요."
          );
        });
    }
  };

  const handleSubmitPassword = (e) => {
    e.preventDefault();
    const valid = onTextValidationPass();

    if (!valid) console.error("invalid");
    else {
      const url = `${process.env.REACT_APP_IP}/users/sign/authPw`;

      axios
        .post(url, {
          userId: valuesPass.userId,
          name: valuesPass.name,
          email: valuesPass.email,
        })
        .then((response) => {
          if (response.data === 0) toast.error("인증에 실패 했습니다.");
          else {
            toast.success("인증되었습니다. 패스워드를 재설정하세요.");
            setCheckedPass(response.data);
            setValuesPassConfirm({ password: "", confirmPassword: "" });
          }
        })
        .catch((error) => {
          //
          toast.error("인증에 실패 했습니다.");
          setValuesPass({ userId: "", name: "", email: "" });
        });
    }
  };

  const handleSubmitPasswordEdit = (e) => {
    e.preventDefault();
    const valid = onTextValidationPassConfirm();

    if (!valid) console.error("invalid");
    else {
      const url = `${process.env.REACT_APP_IP}/users/sign/pw`;

      axios
        .put(url, {
          userId: valuesPass.userId,
          password: valuesPassConfirm.password,
        })
        .then((response) => {
          //toast.error(response.data);
          if (response.data === 0) toast.error("인증에 실패 했습니다.");
          else if (response.data === 1) {
            toast.success("비밀번호가 변경되었습니다.");
            setTimeout(() => {
              window.location.href = "/";
            }, 1000);
          } else toast.error("비밀번호 변경에 실패 했습니다.");
          //window.location.href = "/"
        })
        .catch((error) => {
          //
          toast.error("인증에 실패 했습니다.");
          setValuesPassConfirm({ password: "", confirmPassword: "" });
        });
    }
  };

  return (
    <Container maxWidth="sm">
      <div className="mypage">
        <div className={classes.root}>
          <AppBar position="static">
            <Tabs
              className={classes.tab}
              classes={{ indicator: classes.indicator }}
              value={value}
              onChange={handleChange}
              aria-label="wrapped label tabs example"
            >
              <Tab
                className={classes.tabBtn}
                value="one"
                label="아이디 찾기"
                wrapped
                fullWidth
                {...a11yProps("one")}
              />
              <Tab
                className={classes.tabBtn}
                value="two"
                label="비밀번호 찾기"
                wrapped
                fullWidth
                {...a11yProps("two")}
              />
            </Tabs>
          </AppBar>
          <TabPanel value={value} index="one">
            {checkedUserId === "no" ? (
              <Grid container spacing={1}>
                <Grid item xs={12}>
                  <form className={classes.form} onSubmit={handleSubmitID}>
                    <TextField
                      className={classes.tF}
                      variant="outlined"
                      margin="normal"
                      required
                      autoFocus
                      autoComplete="name"
                      fullWidth
                      label="이름을 입력하세요."
                      name="name"
                      value={values.name}
                      onChange={handleChangeForm}
                    />
                    <div
                      style={{
                        color: "red",
                        fontSize: "12px",
                        margin: "-5px 0 10px 15px",
                      }}
                    >
                      {error.nameError}
                    </div>
                    <TextField
                      className={classes.tF}
                      variant="outlined"
                      margin="normal"
                      required
                      autoComplete="email"
                      fullWidth
                      label="이메일을 입력하세요."
                      name="email"
                      value={values.email}
                      onChange={handleChangeForm}
                    />
                    <div
                      style={{
                        color: "red",
                        fontSize: "12px",
                        margin: "-5px 0 10px 15px",
                      }}
                    >
                      {error.emailError}
                    </div>
                    <div style={{ width: "100%", textAlign: "center" }}>
                      <div
                        style={{
                          paddingTop: "10px",
                          borderBottom: "1px solid #e3e3e3",
                          margin: "10px 0",
                        }}
                      ></div>
                    </div>
                    <button
                      type="submit"
                      size="large"
                      variant="contained"
                      color="primary"
                      className={classes.button}
                      style={{
                        margin: "auto",
                        marginTop: "20px",
                        padding: "10px 30px",
                        borderRadius: "50px",
                      }}
                    >
                      아이디 찾기
                    </button>
                  </form>
                </Grid>
              </Grid>
            ) : (
              <div>
                <div
                  style={{ width: "90%", margin: "auto", marginTop: "50px" }}
                >
                  <p style={{ fontSize: "1.5rem" }}>
                    {values.name}님,{" "}
                    <span style={{ fontSize: "1.0rem" }}>반갑습니다.</span>
                  </p>
                  <p style={{ fontSize: "1.2rem" }}>
                    회원님의 아이디는 [{" "}
                    <span style={{ fontSize: "2.0rem" }}>{checkedUserId}</span>{" "}
                    ] 입니다.
                  </p>
                </div>
                <div style={{ textAlign: "center" }}>
                  <div
                    style={{
                      paddingTop: "10px",
                      borderBottom: "1px solid #e3e3e3",
                      margin: "25px 0",
                    }}
                  ></div>
                  <Button
                    size="large"
                    variant="contained"
                    color="primary"
                    data-toggle="modal"
                    data-target="#modal-signup"
                    className={classes.button}
                    style={{
                      margin: "auto",
                      marginTop: "20px",
                      padding: "10px 30px",
                      borderRadius: "50px",
                    }}
                  >
                    로그인
                  </Button>
                </div>
              </div>
            )}
          </TabPanel>
          {/* 비밀번호 재설정 부분 시작*/}
          <TabPanel value={value} index="two">
            {checkedPass === "no" ? (
              <Grid container spacing={2}>
                <form className={classes.form} onSubmit={handleSubmitPassword}>
                  <Grid item xs={12}>
                    <TextField
                      variant="outlined"
                      margin="normal"
                      required
                      autoComplete="userId"
                      autoFocus
                      label="아이디를 입력하세요."
                      name="userId"
                      fullWidth
                      value={valuesPass.userId}
                      onChange={handleChangeFormPass}
                    />
                    <div
                      style={{
                        color: "red",
                        fontSize: "12px",
                        margin: "-5px 0 10px 15px",
                      }}
                    >
                      {error.userIdError}
                    </div>
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      className={classes.tF}
                      variant="outlined"
                      margin="normal"
                      required
                      autoComplete="name"
                      fullWidth
                      label="이름을 입력하세요."
                      name="name"
                      value={valuesPass.name}
                      onChange={handleChangeFormPass}
                    />
                    <div
                      style={{
                        color: "red",
                        fontSize: "12px",
                        margin: "-5px 0 10px 15px",
                      }}
                    >
                      {error.nameError}
                    </div>
                  </Grid>
                  {/* 이메일 인증 로직 추가 시작*/}
                  <Grid item xs={7}>
                    <TextField
                      variant="outlined"
                      margin="normal"
                      required
                      autoComplete="email"
                      fullWidth
                      label="이메일을 입력하세요."
                      name="email"
                      value={valuesPass.email}
                      onChange={handleChangeFormPass}
                    />
                    <div
                      style={{
                        color: "red",
                        fontSize: "12px",
                        margin: "-5px 0 10px 15px",
                        fontFamily: "Noto Sans KR",
                      }}
                    >
                      {error.emailError}
                    </div>
                  </Grid>
                  {/* 스페이싱 대체 */}
                  <Grid item xs={1}>
                    <div></div>
                  </Grid>
                  <Grid item xs={4}>
                    <div className={styles.btn_findup}>
                      <Button roundSmall click={() => handleConfirmEmail()}>
                        이메일 인증
                      </Button>
                    </div>
                  </Grid>
                  <Grid item xs={7}>
                    <TextField
                      variant="outlined"
                      margin="normal"
                      required
                      autoComplete="code"
                      fullWidth
                      label="인증번호를 입력하세요."
                      name="confirmCode"
                      value={valuesPass.confirmCode}
                      onChange={handleChangeFormPass}
                    />
                    <div
                      style={{
                        color: "red",
                        fontSize: "12px",
                        margin: "-5px 0 10px 15px",
                        fontFamily: "Noto Sans KR",
                      }}
                    >
                      {error.emailCodeError}
                    </div>
                  </Grid>
                  {/* 스페이싱 대체 */}
                  <Grid item xs={1}>
                    <div></div>
                  </Grid>
                  <Grid item xs={4}>
                    <div className={styles.btn_findup}>
                      <Button roundSmall click={() => handleConfirmCode()}>
                        인증번호 확인
                      </Button>
                    </div>
                  </Grid>
                  {/* 이메일 인증 로직 추가 끝*/}
                  <div style={{ width: "100%", textAlign: "center" }}>
                    <div
                      style={{
                        paddingTop: "10px",
                        borderBottom: "1px solid #e3e3e3",
                        margin: "10px 0",
                      }}
                    ></div>
                  </div>

                  <button
                    type="submit"
                    size="large"
                    variant="contained"
                    color="primary"
                    className={classes.button}
                    style={{
                      margin: "auto",
                      marginTop: "20px",
                      padding: "10px 30px",
                      borderRadius: "50px",
                      fontFamily: "Noto Sans KR",
                    }}
                  >
                    비밀번호 재설정
                  </button>
                </form>
                {/* 비밀번호 재설정 부분 끝*/}
              </Grid>
            ) : (
              <Grid container spacing={1}>
                <Grid item xs={12}>
                  <form
                    className={classes.form}
                    onSubmit={handleSubmitPasswordEdit}
                  >
                    <TextField
                      variant="outlined"
                      margin="normal"
                      required
                      autoFocus
                      autoComplete=""
                      fullWidth
                      helperText="영문과 숫자, 특수문자(@$!%*#?&)를 조합하여 8-15자 사이로 입력해 주세요."
                      label="신규 비밀번호를 입력하세요."
                      name="password"
                      type="password"
                      value={valuesPassConfirm.password}
                      onChange={handleChangeFormPassConfirm}
                    />
                    <div
                      style={{
                        color: "red",
                        fontSize: "12px",
                        margin: "-5px 0 10px 15px",
                        fontFamily: "Noto Sans KR",
                      }}
                    >
                      {error.pwdError}
                    </div>
                    <TextField
                      variant="outlined"
                      margin="normal"
                      required
                      autoComplete=""
                      fullWidth
                      label="비밀번호 다시한번 입력하세요."
                      name="confirmPassword"
                      type="password"
                      value={valuesPassConfirm.confirmPassword}
                      onChange={handleChangeFormPassConfirm}
                    />
                    <div
                      style={{
                        color: "red",
                        fontSize: "12px",
                        margin: "-5px 0 10px 15px",
                        fontFamily: "Noto Sans KR",
                      }}
                    >
                      {error.confirmPwd}
                    </div>
                    <div style={{ width: "100%", textAlign: "center" }}>
                      <div
                        style={{
                          paddingTop: "10px",
                          borderBottom: "1px solid #e3e3e3",
                          margin: "10px 0",
                        }}
                      ></div>
                    </div>
                    <button
                      type="submit"
                      size="large"
                      variant="contained"
                      color="primary"
                      className={classes.button}
                      style={{
                        margin: "auto",
                        marginTop: "20px",
                        padding: "10px 30px",
                        borderRadius: "50px",
                      }}
                    >
                      비밀번호 재설정
                    </button>
                  </form>
                </Grid>
              </Grid>
            )}
          </TabPanel>
        </div>
      </div>
    </Container>
  );
}
