import React from "react";
import { Button, Modal } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { useQueryClient } from "react-query";
import { useParams } from "react-router";
import swal from "sweetalert";
import useUpdateCounseling from "../../../../hooks/teacher/useUpdateCounseling";
import { BtnLg } from "../../style";
import {
  Content,
  ErrorMsg,
  InputWrapper,
  ModalForm,
  Title,
  Wrapper,
} from "../style";

const Edit = ({ data, modalType, handleClose }) => {
  const { userId } = useParams();
  const queryClient = useQueryClient();
  const {
    register,
    handleSubmit,
    setError,
    formState: { errors },
  } = useForm({ defaultValues: { title: data.title, content: data.content } });

  const { mutate, isLoading } = useUpdateCounseling();

  const onSubmit = ({ title, content }) => {
    if (isLoading) return;
    if (title === data.title && content === data.content) {
      setError("content", { message: "수정된 내용이 없습니다." });
      return;
    }
    const jsonData = {
      counselingId: data.counselingId,
      title,
      content,
    };
    mutate(jsonData, {
      onSuccess: (data) => {
        queryClient.invalidateQueries(["counselingComments", userId]);
        swal("상담일지가 수정되었습니다.", {
          icon: "success",
        });
        handleClose();
      },
      onError: (err) => {
        console.log(err);
      },
    });
  };
  return (
    <Modal className="fade modal" size="md" show={true} onHide={handleClose}>
      <Modal.Body style={{ overflow: "auto" }}>
        <Button variant="" className="close" onClick={handleClose}>
          <span>&times;</span>
        </Button>
        <ModalForm onSubmit={handleSubmit(onSubmit)}>
          <InputWrapper style={{ marginBottom: "10px" }}>
            <input
              placeholder="상담제목"
              {...register("title", { required: "필수 항목입니다." })}
            />
            <ErrorMsg>{errors.title?.message}</ErrorMsg>
          </InputWrapper>
          <InputWrapper>
            <textarea
              rows={13}
              placeholder="상담내용을 입력하세요."
              {...register("content", { required: "필수 항목입니다." })}
            ></textarea>
            <ErrorMsg>{errors.content?.message}</ErrorMsg>
          </InputWrapper>
          <div style={{ textAlign: "center", marginTop: "15px" }}>
            <BtnLg color="#d9534f" type="submit">
              수정저장
            </BtnLg>
            <BtnLg color="rgba(0,0,0,0.3)" onClick={handleClose} type="button">
              취소하기
            </BtnLg>
          </div>
        </ModalForm>
      </Modal.Body>
    </Modal>
  );
};

export default Edit;
