import React, { useState } from "react";
import { Link, useLocation } from "react-router-dom";

//
import logo from "../../assets/images/openeg_logo_png(뒷배경투명).png";
import loginbg from "../../assets/images/bg-login.jpg";
import astroWorker from "../../assets/images/astro.gif";
import astroThinking from "../../assets/images/astroThinking.gif";
// import astroSk from "../../images/astro/astroSB.gif"

import axios from "axios";
import { useContext } from "react";
import AuthContext from "../../context/Auth.context";

// const process = require("../../myProcess.json");

function Login(props) {
  const auth = useContext(AuthContext);
  const [astro, setAstro] = useState(astroWorker);
  const [values, setValues] = useState({
    userId: "",
    password: "",
    type: 1,
  });

  const [errors, setErrors] = useState({
    userId: "",
    password: "",
    server: "",
  });

  const handleChange = (e) => {
    setValues({ ...values, [e.target.name]: e.target.value });
    setAstro(astroThinking);
    setErrors({
      userId: "",
      password: "",
      server: "",
    });
  };

  function onLogin(e) {
    e.preventDefault();

    let error = false;
    const errorObj = { ...errors };
    if (values.userId === "") {
      errorObj.userId = "아이디를 입력해 주세요.";
      error = true;
    }
    if (values.password === "") {
      errorObj.password = "패스워드를 입력해 주세요";
      error = true;
    }
    setErrors(errorObj);
    if (error) {
      return;
    }
    const url = `${process.env.REACT_APP_IP}/users/login`;
    axios
      .post(url, { userId: values.userId, password: values.password })
      .then((response) => {
        auth.onLogin(
          response.headers.accesstoken,
          response.headers.refreshtoken,
          response.data
        );
        console.log("login success");
        props.history.push("/");
      })
      .catch((error) => {
        setErrors({
          ...errors,
          server: "아이디와 패스워드를 다시 한번 확인해 주세요.",
        });
        setValues({ userId: "", password: "" });
        setAstro(astroWorker);
      });
  }

  return (
    <div
      className="login-main-page"
      style={{ backgroundImage: "url(" + loginbg + ")" }}
    >
      <div className="login-wrapper">
        <div className="login-aside-left">
          <span className="login-logo">
            <img src={logo} alt="오픈이지로고" className="mr-2" />
            <div
              className="mt-2"
              style={{ fontSize: "18px", color: "rgba(0,0,0,0.5)" }}
            ></div>
            <img
              src={astro}
              className="astro"
              alt="astro"
              style={{ width: "50%" }}
            />
          </span>
          <div className="login-description">
            <h2 className="mb-2" style={{ fontSize: "28px", color: "#2E90CF" }}>
              LMS
            </h2>
            <p style={{ fontSize: "14px" }} className="text-black">
              LMS 로그인 화면입니다.
            </p>
            <div className="mt-5">
              <div className="fs-12 text-gray">
                주식회사 오픈이지 | 사업자등록번호 737–81–00396
              </div>
              <div className="fs-12 text-gray">
                서울시 중구 퇴계로 36길 2 동국대 충무로영상센터 704호{" "}
              </div>
              <div className="fs-12 text-gray">
                {`COPYRIGHT © ${new Date(
                  Date.now()
                ).getFullYear()} OPENEG Corporation. All Rights Reserved.`}
              </div>{" "}
            </div>
          </div>
        </div>
        <div className="login-aside-right">
          <div className="row m-0 justify-content-center h-100 align-items-center">
            <div className="col-xl-9 col-xxl-9">
              <div className="authincation-content">
                <div className="row no-gutters">
                  <div className="col-xl-12">
                    <div className="auth-form">
                      <div className="mb-4">
                        <h3
                          style={{ fontSize: "28px" }}
                          className="text-white mb-1"
                        >
                          역량평가 시스템
                        </h3>
                        <p style={{ fontSize: "15px" }} className="text-white">
                          아래의 입력창에 아이디와 패스워드를 입력해 로그인
                          해주세요.
                        </p>
                      </div>
                      <form onSubmit={onLogin}>
                        <div className="form-group">
                          <label className="mb-2 ">
                            <strong className="text-white font10 font-weight-bold">
                              아이디
                            </strong>
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            name="userId"
                            value={values.userId}
                            onChange={handleChange}
                            style={{ height: "56px" }}
                          />
                          {errors.userId && (
                            <div className="text-white mt-2 fs-12">
                              {errors.userId}
                            </div>
                          )}
                        </div>
                        <div className="form-group">
                          <label className="mb-2 ">
                            <div className="text-white font10 font-weight-bold">
                              패스워드
                            </div>
                          </label>
                          <input
                            type="password"
                            className="form-control"
                            name="password"
                            value={values.password}
                            onChange={handleChange}
                            style={{ height: "56px" }}
                          />
                          {errors.password && (
                            <div className="text-white mt-2 fs-12">
                              {errors.password}
                            </div>
                          )}
                        </div>

                        <div className="text-center">
                          <button
                            style={{ fontSize: "15px" }}
                            type="submit"
                            className="btn bg-white text-primary btn-block"
                          >
                            로그인
                          </button>
                        </div>
                        {errors.server && (
                          <div className="text-white mt-2 fs-12">
                            {errors.server}
                          </div>
                        )}
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Login;
