import React, { useEffect, useState, useContext } from "react";
import axios from "axios";

import { toast } from "react-toastify";
import TestMultipleChoices from "./TestMultipleChoices";
import { useHistory } from "react-router-dom";
import TestOx from "./TestOx";
import TestChoice from "./TestChoice";
import TestShortAnswer from "./TestShortAnswer";
import AuthContext from "../../../context/Auth.context";
import Message from "../../../utilities/Message";
// import * as Sentry from "@sentry/react";

// var process = require("../../../myProcess.json");

export default function TestQuestion(props) {
  const userId = localStorage.getItem("uid");
  const history = useHistory();
  const token = localStorage.getItem("token");
  const auth = useContext(AuthContext);

  const [testQuestions, setTestQuestions] = useState([]);
  testQuestions.examImgPath1 = undefined;
  testQuestions.examImgPath2 = undefined;
  testQuestions.examProblemId = undefined;
  const [answer, setAnswer] = useState();
  const [idx, setIdx] = useState();

  useEffect(() => {
    setAnswer("");
  }, [props.idx]);

  const setCheckAnswer = (param) => {
    if (param !== "") {
      setAnswer(param);
    }
    if (answer === param && idx !== props.problemId) {
      props.saveAnswerHandler(answer);
    }
  };

  const onClickEnterKey = () => {
    props.onClickEnterKey();
  };

  const fetchQuestion = async () => {
    const url = `${process.env.REACT_APP_IP}/users/exam/${userId}/${props.problemId}`;
    const result = await axios
      .get(url, {
        headers: {
          AccessToken: `Bearer ${token}`,
        },
      })
      .then((response) => {
        if (response.data !== null) {
          setTestQuestions(response.data);
        } else {
          if (response.status === 400) {
            toast.error(Message.ERROR_500);
            // history.push("/capability/test");
            history.push("/capability/test");
          }
        }
      })
      .catch((error) => {
        if (error.response !== undefined) {
          if (error.response.status === 401) {
            toast.error(Message.SERVICE_INVALID_TOKEN);
          } else if (error.response.status === 403) {
            toast.error(Message.ERROR_FORBIDDEN);
          } else if (
            error.response.status === 500 ||
            error.response.status === 400
          ) {
            toast.error(Message.ERROR_500);
            history.push("/capability/test");
          }
          // auth.onLogout()
          // Sentry.captureException(error);
        }
      });
  };

  useEffect(() => {
    if (!auth.auth) return;
    props.problemId && fetchQuestion();
    setIdx(props.idx);
  }, [props.problemId]);

  useEffect(() => {
    if (!auth.auth) return;
    props.saveAnswerHandler(answer);
  }, [answer]);

  return (
    <div className="questions">
      <div className="capaQuestionClass">
        <p>
          {testQuestions.categoryName}&nbsp;/&nbsp;{testQuestions.unitTitle}
        </p>
      </div>
      <div className="capaQuestion">
        <p>
          {testQuestions.examQuestion &&
            testQuestions.examQuestion.split("\\n").map((line, index) => {
              return (
                <span key={index}>
                  {line}
                  <br />
                </span>
              );
            })}
        </p>
      </div>
      {testQuestions.examQuestionContent && (
        <pre className="capaQuestionContent">
          {testQuestions.examQuestionContent.split("\\n").map((line,index) => {
            return (
              <span key={index}>
                {line}
                <br />
              </span>
            );
          })}
        </pre>
      )}
      {!testQuestions.examImgPath1 ? null : (
        <div className="capaTestImage1">
          <img
            src={`${process.env.REACT_APP_IP}/examimage/${testQuestions.examProblemId}/${testQuestions.examImgPath1}`}
            alt="문제이미지1"
          />
        </div>
      )}
      {!testQuestions.examImgPath2 ? null : (
        <div className="capaTestImage2">
          <img
            src={`${process.env.REACT_APP_IP}/examimage/${testQuestions.examProblemId}/${testQuestions.examImgPath2}`}
            alt="문제이미지2"
          />
        </div>
      )}
      <div className="capaTestAnswer">
        {(() => {
          // eslint-disable-next-line default-case

          switch (testQuestions.examType) {
            case 0:
              return (
                <TestChoice
                  setCheckAnswer={setCheckAnswer}
                  idx={idx}
                  testOptions={testQuestions.examOptions}
                />
              );
            case 1:
              return (
                <TestMultipleChoices
                  setCheckAnswer={setCheckAnswer}
                  idx={idx}
                  testOptions={testQuestions.examOptions}
                />
              );
            case 2:
              return <TestOx setCheckAnswer={setCheckAnswer} idx={idx} />;
            case 3:
              return (
                <TestShortAnswer
                  setCheckAnswer={setCheckAnswer}
                  idx={idx}
                  handleNextButton={onClickEnterKey}
                  answerCount={testQuestions.answerCount}
                />
              );
          }
        })()}
      </div>
    </div>
  );
}
