import React, { Fragment, useContext, useEffect } from "react";
import { Header as Header1 } from "../../widgets";
import { useCustomState } from "../../../state/state";
import InfoFind from "./InfoFind";
import AuthContext from "../../../context/Auth.context";
import { Footer, Header } from "../../layouts";

export default ({ data }) => {
  const state = useCustomState()[0];
  const auth = useContext(AuthContext);

  const [stateData, actions] = useCustomState();

  useEffect(() => {
    if (localStorage.getItem("token") && !auth.auth) auth.onLogin();
  }, [auth]);

  return (
    <Fragment>
      <Header data={data} />
      <Header1 img={state.data.header_bgs.signup} subTitle="ID.Password Search">
        아이디, 비밀번호 찾기
      </Header1>
      <InfoFind />
      <Footer />
    </Fragment>
  );
};
