import React from "react";
import { useMemo } from "react";
import Detail from "./Detail";
import Edit from "./Edit";
import Write from "./Write";

const CounselingModal = ({ data, modalType, handleClose }) => {
  const props = useMemo(
    () => ({ data, modalType, handleClose }),
    [data, modalType, handleClose]
  );
  return (
    <>
      {modalType === "detail" && <Detail {...props} />}
      {modalType === "edit" && <Edit {...props} />}
      {modalType === "write" && <Write {...props} />}
    </>
  );
};

export default CounselingModal;
